import { useCallback } from "react";
import styles from "./ClassesCard.module.css";

const ClassesCard = (props) => {
	let data = props.data;
	let durationOfCourse = data?.classes[0]?.name?.replace("courseDuration", "")
	let classesPerWeek = data?.classes[0]?.description?.replace("classesPerWeek", "")

	const handleViewClick = useCallback(() => {
		const url = `/worlds/${props?.worldId}/activity/courses/${props?.courseId}`;
		props.history.push(url, { courseData: data });
	}, [props.history, props?.worldId, props?.courseId])

	return (
		<div className={styles.card} onClick={handleViewClick}>
			<div className={styles.cardImg}>
				<img src="/default_webinar_img.png" alt={data?.title}/>
			</div>
			<div className={styles.cardContent}>
				<h5>
					{data?.title} 
				</h5>
				<div className={styles.features}>
					<div className={styles.feature}>
						{durationOfCourse}
					</div>

					<div className={styles.feature}>
						Meets{classesPerWeek}x/week
					</div>

					<div className={styles.feature}>
						Grades {data?.grades[0]?.grade} - {data?.grades[data?.grades.length - 1]?.grade}
					</div>

				</div>
				<h6 className={styles.price}>
					₹{" "} {data?.fee}
					<span style={{ fontSize: "14px", fontWeight: "300" }}>/month</span>
				</h6>
			</div>
		</div >
	);
};

export default ClassesCard;
