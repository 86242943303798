import { call, put, takeEvery } from 'redux-saga/effects';
import {
    getUser as getUserSlice,
    getUserSuccess,
    getUserFailure,
    getWorldBookings,
    getWorldBookingsSuccess,
    getWorldBookingsFailure,
    getUpcomingLiveRooms,
    getUpcomingLiveRoomsSuccess,
    getUpcomingLiveRoomsFailure,
    getUpcomingLiveClasses,
    getUpcomingLiveClassesSuccess,
    getUpcomingLiveClassesFailure,
    getPastClasses,
    getPastClassesSuccess,
    getPastClassesFailure
} from '../slices/user';
import { userApi } from '../api';

function* getUser(action) {
    try {
        if (action.payload) {
            const { data } = yield call(userApi.getUser, action.payload);
            if (!data.success) throw new Error("Error fetching user");
            yield put(getUserSuccess(data.data));

        }
    } catch (error) {
        yield put(getUserFailure(error));
    }
}

function* worldBookings() {
    try {
        const { data } = yield call(userApi.getWorldBookings);
        if (!data.success) throw new Error("Error fetching world bookings");
        yield put(getWorldBookingsSuccess(data.data.results));
    } catch (error) {
        yield put(getWorldBookingsFailure(error));
    }
}

function* classBookings(action) {
    try {
        const { data } = yield call(userApi.getClassBookings, action.payload);
        if (!data.success) throw new Error("Error fetching class bookings");
        yield put(getPastClassesSuccess(data.data.results));
    } catch (error) {
        yield put(getPastClassesFailure(error));
    }
}

function* upcomingLiveRooms() {
    try {
        const { data } = yield call(userApi.getDashboardWebinars);
        if (!data.success) throw new Error("Error fetching world bookings");
        yield put(getUpcomingLiveRoomsSuccess(data.data.results));
    } catch (error) {
        yield put(getUpcomingLiveRoomsFailure(error));
    }
}

function* upcomingLiveClasses(action) {
    try {
        const { data } = yield call(userApi.getUpcomingClasses, action.payload);
        if (!data.success) throw new Error("Error fetching live classes");
        yield put(getUpcomingLiveClassesSuccess(data.data.results));
    } catch (error) {
        yield put(getUpcomingLiveClassesFailure(error));
    }
}

export default function* userSaga() {
    yield takeEvery(getUserSlice, getUser);
    yield takeEvery(getWorldBookings, worldBookings);
    yield takeEvery(getUpcomingLiveRooms, upcomingLiveRooms);
    yield takeEvery(getUpcomingLiveClasses, upcomingLiveClasses);
    yield takeEvery(getPastClasses, classBookings);
}