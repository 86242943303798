import React, { Component } from "react";
// import {
//   Link
// } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

class SingleCourseInfo extends Component {
	static contextType = ThemeContext;
	render() {
		// let mentor_url = "/mentor/" + this.props.mentor.id;
		// let featured_image = "/default_webinar_img.png";

		// if(this.props.featured_image !== null){
		//     featured_image = this.props.featured_image;
		// }
		const light = !this.context.isDark;
		let subject_string = "";
		if (
			this.props.secondary_subject === 0 ||
			this.props.secondary_subject === null ||
			this.props.secondary_subject === ""
		) {
			subject_string = this.props.primary_subject;
		} else {
			subject_string =
				this.props.primary_subject + ", " + this.props.secondary_subject;
		}

		let start_grade = 12;
		let end_grade = 1;
		for (let i = 0; i < this.props.grades.length; i++) {
			if (parseInt(this.props.grades[i]["grade"]) < start_grade) {
				start_grade = parseInt(this.props.grades[i]["grade"]);
			}
			if (parseInt(this.props.grades[i]["grade"]) > end_grade) {
				end_grade = parseInt(this.props.grades[i]["grade"]);
			}
		}

		let grade_text = "Grade " + start_grade + " to " + end_grade;

		if (start_grade === end_grade) {
			grade_text = "Grade " + start_grade;
		}

		return (
			<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 webinar-info-container mt-0 mt-md-3">
				<div
					className="row other-webinar-info"
					style={
						light ? { backgroundColor: "transparent", boxShadow: "#d9d9d9 0px 2px 10px" } : {
							boxShadow: "rgb(58 58 58) 0px 2px 10px"
						}
					}
				>
					<div className="custom-20-percent col-xs-6">
						<h5>Duration</h5>
						<p>
							{this.props.classes.length > 0
								? `${this.props.classes.length} classes,`
								: `0 classes`}{" "}
							{this.props.duration}
						</p>
					</div>
					<div className="custom-20-percent col-xs-6">
						<h5>Language</h5>
						<p>{this.props.language}</p>
					</div>
					<div className="custom-20-percent col-xs-6">
						<h5>Suitable for</h5>
						<p>{grade_text}</p>
					</div>
					<div className="custom-20-percent col-xs-6">
						<h5>Subjects</h5>
						<p>{subject_string}</p>
					</div>
					<div className="custom-20-percent col-xs-6">
						<h5>Upto</h5>
						<p>{this.props.group_size} participants</p>
					</div>
				</div>
			</div>
		);
	}
}

export default SingleCourseInfo;
