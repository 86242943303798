import { useEffect } from "react";
import css from "./ErrorPage.module.css";
import { Link } from "react-router-dom";

const ErrorPage = () => {
	useEffect(() => {
		document.title = "Page not found | HelloWorlds";
	}, []);

	return (
		<div className={css.errorpg}>
			<h1>404</h1>
			<p>OOPS! NOTHING WAS FOUND</p>
			<p>
				The page you are looking for might have been removed, had its name
				changed, or is temporarily unavailable.
			</p>
			<Link to="/">Return to homepage.</Link>
		</div>
	);
};

export default ErrorPage;
