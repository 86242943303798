import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import UpcomingLiveRooms from "./UpcomingLiveRooms";
import JoinedClubs from "./JoinedClubs";
import UpcomingLiveClasses from "./UpcomingLiveClasses";

class UpcomingExperienceCourse extends Component {
	render() {
		return (
			<div className="col-lg-8 col-12 no-mob-padding dashboard-cards-wrapper">
				<JoinedClubs
					student_id={this.props.student_id}
					access_token={this.props.access_token}
					student_uuid={this.props.student_uuid}
					dark={this.props.dark}
				/>
				<UpcomingLiveRooms
					student_id={this.props.student_id}
					access_token={this.props.access_token}
					student_uuid={this.props.student_uuid}
					dark={this.props.dark}
				/>
				<UpcomingLiveClasses
					student_id={this.props.student_id}
					access_token={this.props.access_token}
					student_uuid={this.props.student_uuid}
					dark={this.props.dark}
				/>
			</div>
		);
	}
}

export default UpcomingExperienceCourse;
