import React, { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { Overlay } from "react-bootstrap";
import swal from "sweetalert";
import MobSliderHeader from "./MobSliderHeader";
import LoginPopup from "./Auth/LoginPopup";
import DarkModeToggle from "./DarkModeToggle";
import NotificationPopup from "./NotificationPopup";
import LazyLoad from "./Commons/LazyLoad";
import MobileBottonNav from "./MobileBottonNav";
import DarkModeToggleSwitch from "./DarkModeToggleSwitch";
import { ThemeContext } from "../context/ThemeContext";
import { useSelector } from "react-redux";


const Header = (props) => {
	const { userInfo } = useSelector(state => state.user);
	const uuid = localStorage.getItem("student_uuid");
	const token = localStorage.getItem("student_token")

	function showLoginPopup() {
		if (
			uuid === null ||
			uuid === undefined
		) {
			var swal_popup = document.createElement("div");
			ReactDOM.render(
				<React.StrictMode>
					<LoginPopup />
				</React.StrictMode>,
				swal_popup
			);
			swal({
				content: swal_popup,
				buttons: false,
				className: "swal-login",
				closeOnClickOutside: false,
			});
		}
	}

	let avatar_url = userInfo?.avatar_url || "/student.png";
	let dashboard_class = "normal-header";
	let timeline_class = "normal-header";
	let exp_class = "normal-header";
	let webinar_class = "normal-header";
	let worlds_class = "normal-header";
	let inbox_class = "normal-header";
	const link_list_class = "me-2 mb-1 px-2";
	// let course_class = "normal-header";
	if (props.current_route === "dashboard") {
		dashboard_class = "underline-header";
	} else if (props.current_route === "timeline") {
		timeline_class = "underline-header";
	} else if (props.current_route === "learn") {
		exp_class = "underline-header";
	} else if (props.current_route === "webinars") {
		webinar_class = "underline-header";
	} else if (props.current_route === "worlds") {
		worlds_class = "underline-header";
	} else if (props.current_route === "chats") {
		inbox_class = "underline-header";
	}

	function RefundPopup(props) {
		const [show, setShow] = useState(false);
		const target = useRef(null);

		const PopupContent = () => {
			return (
				<>
					<div className="w-100 d-flex justify-content-between align-items-center">
						<h4 className="refund-popup-hero-head m-0 fw-bold">
							HelloWorlds Happiness Guarantee
						</h4>
						<div style={{ cursor: "pointer" }} onClick={() => setShow(false)}>
							<i
								className="fa fa-times-circle ms-3 fs-4 dark-font-pri"
								aria-hidden="true"
							></i>
						</div>
					</div>
					<div className="mt-3">
						<h5 className="refund-popup-head">Cancellations</h5>
						<p>
							Full refund if you cancel a class by 11:59 PM 2 days before the
							day of class.
						</p>
					</div>
					<div>
						<h5 className="refund-popup-head">Refunds</h5>
						<p>
							If not satisfied by the class, 100% refund for requests made
							within 48 hours post class.
						</p>
					</div>
					<p>
						Just send an email to hello@helloworlds.co or Whatsapp us at
						+91-9043408421. We promise to address it within 4 working hours.
					</p>
					<p className="fst-italic">
						Read about our cancellations and refunds policy
						<a
							href="https://www.helloworlds.co/refund-policy"
							target="_blank"
							rel="noreferrer"
						>
							{" "}
							here
						</a>
						.
					</p>
				</>
			);
		};

		return (
			<>
				{
					props.access_token === null ? (
						<img
							src="/images/refund_icon_img.png"
							alt="refund icon img"
							className="avatar-icon position-relative"
							ref={target}
							onClick={() => setShow(!show)}
						/>
					) : (
						<div
							className="desc"
							style={{ cursor: "pointer" }}
							ref={target}
							onClick={() => setShow(!show)}
						>
							Refund Policy
						</div>
					)
				}

				<Overlay target={target.current} show={show} placement="bottom-start">
					{({ placement, arrowProps, show: _show, popper, ...props }) => (
						<div
							{...props}
							style={
								window.innerWidth < 768 ? (
									{
										position: "absolute",
										...props.style,
										zIndex: 1000,
										left: "0%",
										top: "7%",
										transform: "translateX(0%)",
									}
								) : (
									{
										position: "absolute",
										...props.style,
										zIndex: 1000,
										left: "73%",
										top: "8%",
										transform: "translateX(0%)",
									}
								)

							}
							className="refund-popup-content"
						>
							<PopupContent />
						</div>
					)}
				</Overlay>
			</>
		);
	}

	const NavDropDown = () => {
		const [show, setShow] = useState(false);
		const { isDark } = useContext(ThemeContext)

		return (
			<div className="inner-avatar-header-div">
				<div>
					<LazyLoad
						src={avatar_url}
						alt="User Avatar"
						classes="avatar-icon"
						onClick={() => setShow(!show)}
					/>
					<div
						className={show ? "dropdown-content-active" : "dropdown-content rounded-3"}
						style={
							isDark
								? {
									boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
									background: "var(--background_primary)",
									zIndex: 1000,
								}
								: {
									boxShadow: "#d9d9d9 0px 2px 10px",
									background: "var(--background_primary)",
									zIndex: 1000,
								}
						}
					>

						{
							window.innerWidth > 768 ? (
								<Link to="/chats">
									<div className="desc scale-on-hover">Inbox</div>
								</Link>
							) : null
						}
						<Link to="/edit-profile">
							<div className="desc scale-on-hover">Edit Profile</div>
						</Link>
						<Link to="/past-recordings/worlds">
							<div className="desc scale-on-hover">My Journey</div>
						</Link>
						{/* <RefundPopup /> */}
						<Link to="/faqs">
							<div className="desc scale-on-hover">FAQs</div>
						</Link>
						{/* <a
								href="https://formaloo.net/21js7"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="desc">Free growth counselling</div>
							</a> */}
						<a
							href="https://forms.gle/toVqhQFknqDptFYu7"
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className="desc scale-on-hover">Recommend a mentor</div>
						</a>
						<a
							// href="https://forms.gle/cvuqQzhAutEuAKzk8"
							href="https://api.whatsapp.com/send?phone=919043408421&text=Hello,%20I%20have%20a%20question%20about%20the%20classes."
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className="desc scale-on-hover">Support</div>
							<DarkModeToggleSwitch />
						</a>
						<Link to="/logout">
							<div className="desc scale-on-hover">Logout</div>
						</Link>
					</div>
				</div>
			</div>
		)
	}

	const NavDropDownMobile = () => {
		const { isDark } = useContext(ThemeContext)
		const [show, setShow] = useState(false);
		return (
			<div className="inner-avatar-header-div">
				<div>
					{
						show ? (
							<LazyLoad
								src={isDark ? "/icons/cross.png" : "/icons/cross_black.png"}
								alt="User Avatar"
								classes="avatar-icon"
								onClick={() => setShow(!show)}
							/>
						) : (
							<LazyLoad
								src={!avatar_url ? "/student.png" : avatar_url}
								alt="User Avatar"
								classes="avatar-icon"
								onClick={() => setShow(!show)}
							/>
						)
					}
					<div
						className={show ? "dropdown-content-active slide-in-from-top" : "dropdown-content"}
						style={{
							zIndex: "9999",
							width: "100vw",
							right: "-24px",
							top: "39px",
							paddingLeft: "9px",
							background: "var(--background_primary)",
							boxShadow: "0px 10px 10px -15px var(--fontColor-primary)"
						}}
					>
						{
							window.innerWidth > 768 ? (
								<Link to="/chats">
									<div className="desc">Inbox</div>
								</Link>
							) : null
						}
						<Link to="/edit-profile">
							<div className="desc">Edit Profile</div>
						</Link>
						<Link to="/past-recordings/worlds">
							<div className="desc">My Journey</div>
						</Link>
						{/* <RefundPopup /> */}
						<Link to="/faqs">
							<div className="desc scale-on-hover">FAQs</div>
						</Link>
						{/* <a
								href="https://formaloo.net/21js7"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="desc">Free growth counselling</div>
							</a> */}
						<a
							href="https://forms.gle/toVqhQFknqDptFYu7"
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className="desc">Recommend a mentor</div>
						</a>
						<a
							href="https://api.whatsapp.com/send?phone=919043408421&text=Hello,%20I%20have%20a%20question%20about%20the%20classes."
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className="desc">Support</div>
							<DarkModeToggleSwitch />
						</a>
						<Link to="/logout">
							<div className="desc">Logout</div>
						</Link>
					</div>
				</div>
			</div>
		)
	}

	return (
		<header>
			<div className="header-div px-4">
				<div className="nav-container d-md-none">
					<div className="button" tabIndex="0">
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
					</div>
					<div className="nav-content" tabIndex="0">
						<ul style={{ color: "inherit" }}>
							<li style={{ height: "70px" }}>
								<Link to="/timeline">HOME</Link>
							</li>

							{token && (
								<li style={{ height: "70px" }}>
									<Link to="/dashboard">DASHBOARD</Link>
								</li>
							)}
							{/* <li>
									<Link to="/learn">MIND POD</Link>
								</li> */}
							{/* <li>
									<Link to="/rooms">SOUND POD</Link>
								</li> */}
							<li style={{ height: "70px" }}>
								<Link to="/worlds">WORLDS</Link>
							</li>
							{token && (
								<li style={{ height: "70px" }}>
									<Link to="/chats">INBOX</Link>
								</li>
							)}
							{/* <li><Link to="/courses">MIND POD</Link></li> */}
						</ul>
					</div>
				</div>
				<div className="logo-div">
					<Link to="/">
						<LazyLoad
							src="/edvolve_full.png"
							alt="GlobalShaala Logo"
							classes="header-logo"
						/>
					</Link>
				</div>
				{props.no_avatar ? null : (
					<div className="header-content-container d-flex align-items-center w-100 h-100">
						<div className="header-links d-none d-md-block">
							<ul className="nav big-nav">
								<li
									className={`${link_list_class} ${timeline_class} ev-navbar-item`}
									style={{ color: "inherit", height: "70px" }}
								>
									<LazyLoad
										src="/icons/home2.png"
										alt="Dashboard Icon"
										classes="header-icon"
									/>
									<Link to="/timeline">HOME</Link>
								</li>

								{token && (
									<li
										className={`${link_list_class} ${dashboard_class} ev-navbar-item`}
										style={{ color: "inherit", height: "70px" }}
									>
										<LazyLoad
											src="/icons/dashboard2.png"
											alt="Dashboard Icon"
											classes="header-icon"
										/>
										<Link to="/dashboard">DASHBOARD</Link>
									</li>
								)}
								<li
									className={`${link_list_class} ${worlds_class} ev-navbar-item`}
									style={{ color: "inherit", height: "70px" }}
								>
									<LazyLoad
										src="/icons/global.png"
										alt="Worlds Icon"
										classes="header-icon"
									/>
									<Link to="/worlds">WORLDS</Link>
								</li>
							</ul>
						</div>
						<div className="avatar-header-div">
							{
								token && (
									<NotificationPopup access_token={token} />
								)
							}
							{
								token === null && (
									<DarkModeToggle />
								)
							}


							{
								token === null ? (
									<div className="inner-avatar-header-div">
										<div>
											<button
												onClick={() => showLoginPopup()}
												className="login-btn-header"
											>
												Login
											</button>
										</div>
									</div>
								) : (
									window.innerWidth > 768 ? (
										<NavDropDown />
									) : (
										<NavDropDownMobile />
									)
								)
							}
						</div>
					</div>
				)}
			</div>
			<MobSliderHeader
				current_route={props.current_route}
				logged_in={token}
			/>
			<MobileBottonNav
				current_route={props.current_route}
				logged_in={token}
			/>
		</header>
	);
}

export default Header;