import { LoadingIndicator } from 'stream-chat-react';

const Loading = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                flexDirection: 'column',
                gap: '1rem',
            }}
        >
            <LoadingIndicator size={100} />
            <h3>Loading...</h3>
        </div>
    )
}

export default Loading