const baseUrl = `${process.env.REACT_APP_BASE_URL}/rest/students/world/chat`

const endpoints = {
    getToken: 'token',
    getChannels: 'get-channels',
    createChannel: 'create-channel',
    deleteChannel: 'delete-channel',
    addChannelMembers: 'add-members',
    removeChannelMembers: 'remove-members',
    sendMessage: 'send-message',
    updateMessage: 'update-message',
    deleteMessage: 'delete-message',
    addMessageReaction: 'add-message-reaction',
    removeMessageReaction: 'delete-message-reaction',
}

const token = localStorage.getItem('student_token')

/**
 * Making a get request
 * @param Endpoint
 */
const get = async (endpoint) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };
    let url = `${baseUrl}/${endpoint}`
    const response = await fetch(url, requestOptions);
    const json = await response.json();
    return json
}

/**
 * Making post request
 * @param Endpoint
 * @param Data
 */
const post = async (endpoint, data) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: "follow",
    };
    let url = `${baseUrl}/${endpoint}`
    const response = await fetch(url, requestOptions);
    const json = await response.json();
    return json
}

/**
 * Making put request
 * @param Endpoint
 * @param Data
 */
const put = async (endpoint, data) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: "follow",
    };
    let url = `${baseUrl}/${endpoint}`
    const response = await fetch(url, requestOptions);
    const json = await response.json();
    return json
}

/**
 * Making delete request
 * @param Endpoint
 * @param Data
 */
const del = async (endpoint, data) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: "follow",
    };
    let url = `${baseUrl}/${endpoint}`
    const response = await fetch(url, requestOptions);
    const json = await response.json();
    return json
}

/**
 * Get a token for the chat
 * @returns Token
 */
export const getToken = async () => {
    return await get(endpoints.getToken)
}

/**
 * Get all channels
 * @returns List of channels
 */
export const getChannels = async () => {
    return await get(endpoints.getChannels)
}

/**
 * Create a new channel
 * @param group ( 0 for 1-1 chat and 1 for creating a group )
 * @param world_id
 */
export const createChannel = async (data) => {
    return await post(endpoints.createChannel, data)
}

/**
 * Delete a new channel
 * @param group
 * @param world_id
 */
export const deleteChannel = async (data) => {
    return await post(endpoints.deleteChannel, data)
}

/**
 * Add members to a channel
 * @param channle_name
 * @param members
 */
export const addChannelMembers = async (data) => {
    return await post(endpoints.addChannelMembers, data)
}

/**
 * Send a message to a channel
 * @param channel_name
 * @param message
 * @param parent_id
 * @param mentioned_users UUIDs of mentioned users
 */
export const sendMessage = async (data) => {
    return await post(endpoints.sendMessage, data)
}

/**
 * Update a message
 * @param id
 * @param text
 * @param mentioned_users UUIDs of mentioned users
 */
export const updateMessage = async (data) => {
    return await put(endpoints.updateMessage, data)
}

/**
 * Delete a message
 * @param id
 */
export const deleteMessage = async (data) => {
    return await del(endpoints.deleteMessage)
}

/**
 * Add a reaction to a message
 * @param message_id
 * @param channel_name
 * @param type
 */
export const addMessageReaction = async (data) => {
    return await post(endpoints.addMessageReaction, data)
}

/**
 * Remove a reaction from a message
 * @param message_id
 * @param channel_name
 * @param type
 */
export const removeMessageReaction = async (data) => {
    return await post(endpoints.removeMessageReaction, data)
}