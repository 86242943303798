import React, { Component } from "react";
import AppFloatingInput from "../AppComponents/AppFloatingInput";

class EnterMobile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mob: "",
			btnDisabled: true,
		};
		this.handleChange = this.handleChange.bind(this);
		this.mobNumber = this.mobNumber.bind(this);
	}

	handleChange(e) {
		let tel = e.target.value;
		if (tel.match(/[^0-9]+/g)) {
			return;
		}

		if (tel.trim().length === 10) {
			this.setState({ btnDisabled: false });
		} else {
			this.setState({ btnDisabled: true });
		}
		this.setState({
			mob: tel,
		});
	}

	mobNumber(e) {
		e.preventDefault()
		this.props.mob_handler(this.state.mob);
	}

	render() {
		return (
			<div
				style={this.props.style}
				className="login-detail-box col-sm-12 col-md-12 col-lg-12 col-xs-12"
			>
				{/* <h2 style={{ color: "inherit" }}>Enter your mobile number</h2>
                <input
                    onChange={this.handleChange}
                    type="tel"
                    name="mobile"
                    className="mobile-login"
                    placeholder="Your 10 digit number"
                    minLength={10}
                    maxLength={10}
                /> */}
				<form onSubmit={this.mobNumber}>
					<AppFloatingInput
						// className="text-center"
						placeholder="Your 10 digit number"
						label="Phone No"
						onChange={this.handleChange}
						value={this.state.mob}
						type="tel"
						name="mobile"
						minLength={10}
						maxLength={10}
						autoFocus={true}
					/>
					<button
						disabled={this.state.btnDisabled}
						id="mob_number_btn"
						className="login-btn"
						// onClick={this.mobNumber}
						style={this.state.btnDisabled ? {} : { color: "white" }}
					>
						Get OTP
					</button>
				</form>
			</div>
		);
	}
}

export default EnterMobile;
