import React, { Component } from "react";
import TimeBox from "./TimeBox";
import { ThemeContext } from "../../context/ThemeContext";
import { nearest50 } from "../utils";

class ExperienceDateSelection extends Component {
	static contextType = ThemeContext;

	constructor(props) {
		super(props);
		this.state = {
			show_time_box: Boolean(this.props.show_time_box),
		};
		this.showTimeBox = this.showTimeBox.bind(this);
		this.closeAvailability = this.closeAvailability.bind(this);
	}

	showTimeBox() {
		this.setState({
			show_time_box: true,
		});
	}

	closeAvailability() {
		this.setState({
			show_time_box: false,
		});
	}

	render() {
		const light = !this.context.isDark;
		let window_width = window.innerWidth;
		return (
			<>
				<div
					className="pricing-booking-container"
					style={light ? { backgroundColor: "transparent" } : {}}
				>
					<div className="date-exp-container">
						<div className="price-experience">
							{/* <span>{this.props.price}</span> / person */}
							<span className="original">
								₹{nearest50(Math.ceil(this.props.original_price))}
							</span>
							<span className="discounted">₹{this.props.price}</span>
						</div>
					</div>
					<div className="d-md-none text-center col-xs-7">
						<button
							className="attend-now-btn-webinar"
							onClick={() => this.showTimeBox()}
						>
							Enrol
						</button>
					</div>
				</div>
				{this.state.show_time_box || window_width > 768 ? (
					<TimeBox
						update_token={this.props.update_token}
						access_token={this.props.access_token}
						exp_id={this.props.exp_id}
						student_id={this.props.student_id}
						close_availability={this.closeAvailability}
						student_name={this.props.student_name}
						mentor_name={this.props.mentor_name}
						featured_image={this.props.featured_image}
						price={this.props.price}
						title={this.props.title}
						language={this.props.language}
					/>
				) : null}
			</>
		);
	}
}

export default ExperienceDateSelection;
