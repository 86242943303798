import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import LazyLoad from "../Commons/LazyLoad";

var scheduleStartTime = require("../utils.js").scheduleStartTime;

class Snippet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			zoom_url: "",
			showModal: false,
		};
	}

	componentDidMount() {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/courses/batch/classes/${this.props.object_id}/recording`;

		if (this.props.type === "experience") {
			url = `${process.env.REACT_APP_BASE_URL}/rest/students/experiences/schedule/${this.props.object_id}/recording`;
		}

		fetch(url, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					this.setState({
						zoom_url: result.data?.recording,
					});
				},
				(error) => {
					this.setState({ zoom_url: "This recording does not exist." });
				}
			);
	}

	render() {
		let snippet_url =
			"/course/batch/class/" + this.props.object_id + "/recording";
		if (this.props.type === "experience") {
			snippet_url =
				"/experience/schedule/" + this.props.object_id + "/recording";
		}

		const toggleModal = () => {
			this.setState({ showModal: !this.state.showModal });
		};

		const getZoomLink = () => {
			if (this.state.zoom_url === "This recording does not exist.") {
				this.setState({ showModal: true });
			} else {
				window.open(this.state.zoom_url, "_blank");
			}
		};

		return (
			<div className="col-sm-12 col-md-12 col-xs-12 col-lg-12 single-snippet-recording">
				<div className="snippet-image-past-recording col-sm-12 col-md-12 col-xs-12 col-lg-12 align-center">
					{/* <Link to={snippet_url}> */}
					<LazyLoad
						src={this.props.img}
						alt={this.props.title}
						classes="snippet-img-past-recording"
					/>
					{/* </Link> */}
				</div>
				<div className="snippet-content-past-recording col-sm-12 col-md-12 col-xs-12 col-lg-12">
					<h2>{this.props.title}</h2>
					{this.props.type === "course" ? (
						<p className="snippet-mentor-past-recording">
							{this.props.class_number}
						</p>
					) : (
						<span></span>
					)}
					<p className="snippet-mentor-past-recording">
						By {this.props.mentor_name}
					</p>
					<p className="snippet-completed-past-recording">
						Completed On {scheduleStartTime(this.props.past_date)}
					</p>
					{!!this.state.zoom_url && (
						<button
							onClick={getZoomLink}
							className="replay-btn-past-recording"
						>
							Replay
						</button>
					)}
					<Modal
						show={this.state.showModal}
						centered
						onHide={toggleModal}
						dialogClassName="zoom-nolink-popup"
					>
						<div
							style={{
								backgroundColor: "var(--background_primary)",
								color: "var(--fontColor-primary)",
							}}
							className="row login-page-popup"
						>
							<div className="login-box-popup col-sm-12 col-md-12 col-lg-12 col-xs-12 border-bottom">
								<h5>Oops...</h5>
								<i
									className="fa fa-times login-popup-cross-icon"
									onClick={toggleModal}
									aria-hidden="true"
								></i>
							</div>
							<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 social-login-pop mt-3">
								{this.state.zoom_url}
							</div>
						</div>
					</Modal>
				</div>
			</div>
		);
	}
}

export default Snippet;
