import React, { Component } from "react";
import Snippet from "./Snippet";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Col } from 'react-bootstrap';

class PastExpBookings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			snippets: [],
			loading: true,
		};
	}

	componentDidMount() {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		var url = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.props.student_id}/experiences/bookings?type=past`;

		fetch(url, requestOptions)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then(
				(result) => {
					this.setState({
						snippets: result["data"]["results"],
						loading: false,
					});
				},
				(error) => {
					localStorage.removeItem("student_token");
					localStorage.removeItem("student_uuid");
					this.setState({
						redirect: true,
					});
				}
			);
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/login" />;
		}

		const LoadingSkeleton = () => {
			return (
				<Col xs={12} md={6} className="my-3">
					<div className="experience-snippet-dashboard">
						<div className="snippet-dashboard-img">
							<Skeleton height={170} width="100%" />
						</div>
						<div className="exp-snippet-dashboard-texts">
							<h3 className="snippet-dashboard-text">
								<Skeleton height={25} width={150} />
							</h3>
							<p className="snippet-with-dashboard-text">
								<Skeleton height={20} width={150} />
							</p>
							<div className="snippet-dashboard-last d-flex justify-content-between">
								<Skeleton height={30} width={80} />
								<Skeleton height={30} width={80} />
							</div>
						</div>
					</div>

				</Col>
			)
		}

		return (
			<div className="row upcoming-webinars">
				{
					this.state.loading ? (
						<SkeletonTheme baseColor="gray" highlightColor="white">
							<LoadingSkeleton />
							<LoadingSkeleton />
							<LoadingSkeleton />
							<LoadingSkeleton />
							<LoadingSkeleton />
							<LoadingSkeleton />
						</SkeletonTheme>
					) :
						this.state.snippets.length ? (
							this.state.snippets.map((snippet) => (
								<Snippet
									type="experience"
									img={snippet.experience.featured_image}
									title={snippet.experience.title}
									past_date={snippet.time_slot.start_time}
									object_id={snippet.time_slot_id}
									mentor_name={
										snippet.experience.mentor.first_name +
										" " +
										snippet.experience.mentor.last_name
									}
									token={this.props.access_token}
									dark={this.props.dark}
								/>
							))
						) : (
							<div className="no-upcoming-past-recording">
								<p className="bold-no-past-recording">No History</p>
								<Link to={"/learn"}>
									<button
										className="no-btn-past-recording"
										type="submit"
									>
										Explore Workshops
									</button>
								</Link>
							</div>
						)}
			</div>
		);
	}
}

export default PastExpBookings;
