import { Component } from "react";

class ShowExperience extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
        };
    }

    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.props.access_token);

        let requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        let url = `${process.env.REACT_APP_BASE_URL}/rest/students/experiences/schedule/${this.props.match.params.time_slot_id}/url`;

        console.log(url);

        fetch(url, requestOptions)
            .then((res) => res.json())
            .then(
                (result) => {
                    console.log(result["data"]["results"]);
                    let final_url = result["data"].launch_url;
                    console.log(final_url);
                    window.location.href = final_url;

                    this.setState({
                        url: final_url,
                    });
                },
                (error) => {}
            );
    }

    render() {
        return null;
        /*
		return(
		<div className="webinar-iframe-container">
			<iframe className="webinar-iframe" src={this.state.url} title="View Webinar" frameBorder="0" width="100%" height="100vh" scrolling="auto" allow="camera;microphone" allowFullScreen></iframe>
		</div>
			);
		*/
    }
}

export default ShowExperience;
