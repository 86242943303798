import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import UpcomingLearn from "./UpcomingLearn";
import Banner from "./Banner";
// import StartingSoon from "./StartingSoon";
import CourseCarousel from "./CourseCarousel";

class Course extends Component {
	componentDidMount() {
		document.title = "Mind Pod | HelloWorlds";
		if(this.props.access_token === null){
			localStorage.setItem("redirect", window.location.pathname);
		}
	}

	render() {
		//if (this.props.access_token===null) {
		// return <Redirect to='/login'/>;
		//}
		return (
			<div>
				<Header
					update_token={this.props.update_token}
					current_route={this.props.current_route}
					avatar_url={this.props.avatar_url}
					access_token={this.props.access_token}
				/>
				<Banner />
				<CourseCarousel />
				<div className="container mt-3">
					<UpcomingLearn access_token={this.props.access_token} />
				</div>
				<Footer />
			</div>
		);
	}
}

export default Course;
