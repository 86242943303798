import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import swal from 'sweetalert';
import {
  Link
} from "react-router-dom";

class Qna extends Component {

    constructor(props) {
        super(props);
        this.state = {
          questions: [],
          pages:[],
          page_no: 1,
          show: this.props.show, //will show all with pagination if props show is 10,
          total_qna: 0
        };
        this.askQuestion = this.askQuestion.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    componentDidMount() {
        /*
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.props.access_token);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        var url = "https://api.edvolve.in/rest/webinars/"+this.props.webinar_id+"/q?page="+this.state.page_no;

        fetch(url, requestOptions)
            .then(function(response) {
                let status = response.status;
                let json_response = response.json();
                if (status !== 200) {
                    if(status === 401){
                        throw new Error('Your session has expired. Please login again.');
                    }else{
                        throw new Error('Something went wrong. Please contact support.');
                    }
                }else{
                    return json_response;
                }
                
            })
            .then(
              (result) => {
                //console.log(result["data"]["results"]);
                this.setState({
                    questions: result["data"]["results"],
                    pages: Array.from({length: result["data"]["metadata"]["total_pages"]}, (_, i) => i + 1)
                });
              },
              (error) => {
              }
            );
            */
        this.changePage(1);
    }

    askQuestion(){
        var swal_question_popup = document.createElement('div');
        ReactDOM.render(
          <React.StrictMode>
            <QnaPopup mentor_name={this.props.mentor_name} access_token={this.props.access_token} webinar_id={this.props.webinar_id} />
          </React.StrictMode>,
          swal_question_popup
        );
        //swal_welcome_content.innerHTML = <QnaPopup />;
        swal({
            "content": swal_question_popup,
            "buttons": false,
            "className": "swal-qna"
        });
    }

    changePage(page){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.props.access_token);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        var url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/${this.props.webinar_id}/q?page=${page}`;

        fetch(url, requestOptions)
            .then(function(response) {
                let status = response.status;
                let json_response = response.json();
                if (status !== 200) {
                    if(status === 401){
                        throw new Error('Your session has expired. Please login again.');
                    }else{
                        throw new Error('Something went wrong. Please contact support.');
                    }
                }else{
                    return json_response;
                }
                
            })
            .then(
              (result) => {
                //console.log(result["data"]["results"]);
                this.setState({
                    questions: result["data"]["results"],
                    pages: Array.from({length: result["data"]["metadata"]["total_pages"]}, (_, i) => i + 1),
                    page_no: page,
                    total_qna: result["data"]["metadata"]["total_qna"]
                });
              },
              (error) => {
              }
            );
    }

	render(){
        var all_q_url = "/room/"+this.props.webinar_id+"/qna";
		return(
			<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 qna-container">
                <div className="col-sm-9 col-md-9 col-lg-9 col-xs-12 no-padding">
                    <h3 className="webinar-title">
                        Get your questions answered by {this.props.mentor_name}!
                    </h3>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3 col-xs-12 no-padding">
                    <button className="ask-q-btn" onClick={this.askQuestion}>Ask a question</button>
                </div>
                <div className="clearfix"></div>
				{this.state.questions.slice(0, this.state.show).map((question, index) => (
                    <div className="question-answer-div" key={index}>
                        <p className="single-question">Q: {question.question}</p>
                        <p className="single-answer">A: {question.answer}</p>
                    </div>
                ))}
                { this.state.show===10 && this.state.total_qna > 10 ? (
                <div className="qna-pagination-parent">
                    <div className="qna-pagination">
                        {this.state.pages.map((page) => (
                        <div className={ this.state.page_no===page ? "pagination-single-qna selected-qna-pagination" : "pagination-single-qna greyed-qna-pagination" } onClick={() => this.changePage(page)}>
                            {page}
                        </div>
                        ))}
                    </div>
                </div>
                ) : (<div></div>) }
                { this.state.show<10 && this.state.total_qna > this.state.show ? (
                <div className="see-more-qna">
                        <Link to={all_q_url}>
                            <button className="see-more-qna-btn">See more answered questions ({this.state.total_qna})</button>
                        </Link>
                    </div>) : (<div></div>) 
                }
			</div>
			);
	}
}

class QnaPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
          question: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.postQuestion = this.postQuestion.bind(this);
    }

    handleChange(q) {
        this.setState({
          question: q.target.value
        })
    }

    postQuestion(){
        if(this.state.question.length>9){
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + this.props.access_token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({"question": this.state.question});

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            var url = `${process.env.REACT_APP_BASE_URL}/rest/webinars/${this.props.webinar_id}/q`;

            fetch(url, requestOptions)
              .then(function(response) {
                let status = response.status;
                let json_response = response.json();
                if (status !== 200) {
                    if(status === 401){
                        throw new Error('Your session has expired. Please login again.');
                    }else{
                        throw new Error('Something went wrong. Please contact support.');
                    }
                }else{
                    return json_response;
                }
            })
            .then(
              (result) => {
                console.log(result);
                if(result["success"]===true){
                    swal.close();
                    var swal_success_q = document.createElement('div');
                    swal_success_q.innerHTML = '<p>Question submitted! Once ' + this.props.mentor_name + ' answers the question, it will be posted here.</p>';
                    swal({
                        "content": swal_success_q
                    });
                }
              },
              (error) => {
              }
            );
        }else{
            alert("Please enter atleast 10 characters.");
        }
    }

    closeQnaPopup(){
        swal.close();
    }

    render(){
        return(
            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 qna-popup no-padding">
                <div className="popup-q-title">
                    Post your question
                </div>
                <div className="qna-popup-content">
                    <textarea onChange={this.handleChange}></textarea>
                    <p>We will request {this.props.mentor_name} to answer this question for you. Make sure:</p>
                    <p>- You have gone through all questions already asked here and this isn’t a repeat question.</p>
                    <p>- You are specific, ask questions only about the topic addressed in this webinar</p>
                    <div className="qna-popup-btn-div">
                        <button className="cancel-q-btn" onClick={this.closeQnaPopup}>Cancel</button>
                        <button className="post-q-btn" onClick={this.postQuestion}>Post</button>
                    </div>
                </div>
            </div>
            );
    }
}

export default Qna;