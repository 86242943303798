import { Offcanvas } from 'react-bootstrap';
import LazyLoad from '../../Commons/LazyLoad';

const handleProfilePic = (img) => {
    if (img === null || img === undefined) {
        return "/student.png"
    } else if (img === "" || img === "NA") {
        return "/student.png"
    } else {
        return img
    }
}

const MembersMobileSidebar = ({ show, handleClose, dark, worldMembers,handleOpenProfileSidebar }) => {
    const activeMembers = worldMembers?.filter(member => member?.sub_status === "ACTIVE")

    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement='end'
            style={{
                background: "var(--background_primary)",
                color: "var(--textColor_primary)",
            }}
        >
            <Offcanvas.Header>
                <div className='d-flex justify-content-between w-100 px-3'>
                    <h4>
                        Members
                    </h4>
                    <div>
                        <img
                            src={dark ? "/icons/cross.png" : "/icons/cross_black.png"}
                            alt="HelloWorlds"
                            style={{
                                width: "30px",
                                cursor: "pointer",
                            }}
                            onClick={handleClose}
                        />
                    </div>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body
                className="hide-scroll-bar"
                style={window.innerWidth < 768 ? { paddingBottom: "100px" } : {}}
            >
                <div className="px-3">
                    <h5 className='fw-bolder mb-4'>
                        {activeMembers?.length} Members
                    </h5>
                    <div className='grid-3-3'>
                        {
                            activeMembers?.map((member => (
                                <div 
                                onClick={()=>handleOpenProfileSidebar(member)}
                                key={member?.student_id}
                                >
                                    <LazyLoad
                                        src={handleProfilePic(member?.uuid?.student?.avatar_url)}
                                        width="80px"
                                        height="80px"
                                        style={{ borderRadius: "50%" }}
                                    />
                                </div>
                            )))
                        }
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas >
    );
}

export default MembersMobileSidebar;