import { Link } from "react-router-dom";
// import { RWebShare } from "react-web-share";
import Title from "./Title";
import swal from "sweetalert";
import WebinarContent from "./WebinarContent";
import ThingsToDo from "./ThingsToDo";
import SingleWebinarInfo from "./SingleWebinarInfo";
import WebinarDateSelection from "./WebinarDateSelection";
import {
	onlyTime,
	onlyDate,
	scheduleStartTime,
	capitalizeName,
} from "../utils";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { RWebShare } from "react-web-share";
import LazyLoad from "../Commons/LazyLoad";
import moment from "moment";

const SingleWebinarPopup = (props) => {
	const [roomData, setRoomData] = useState({
		title: "",
		start_time: "",
		end_time: "",
		description: "",
		subjects: [],
		grades: [],
		notes: [],
		registered: 0,
		featured_image: "",
		mentor: {
			id: "",
			first_name: "",
			last_name: "",
			avatar_url: "",
			about_me: "",
			slug: "",
		},
	});
	const [mentorName, setMentorName] = useState("");

	useEffect(() => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/${props.match.params.webinar_id}`;

		const makeRequest = async () => {
			const response = await fetch(url, requestOptions);
			const { data } = await response.json();
			setRoomData({
				title: data.title,
				start_time: data.start_time_ts,
				end_time: data.end_time_ts,
				description: data.description,
				subjects: data.subjects,
				grades: data.grades,
				notes: data.notes,
				registered: data.registered,
				featured_image: data.featured_image,
				mentor: {
					id: data.mentor.uuid.uuid,
					first_name: data.mentor.first_name,
					last_name: data.mentor.last_name,
					avatar_url: data.mentor.avatar_url,
					about_me: data.mentor.about_me,
					slug: data.mentor.slug,
				},
			});

			setMentorName(
				capitalizeName(
					`${data?.mentor?.first_name} ${data?.mentor?.last_name || ""}`
				)
			);
			document.title = `${capitalizeName(
				`${data?.mentor?.first_name} ${data?.mentor?.last_name || ""}`
			)} - ${data.title} | HelloWorlds`;
		};
		makeRequest();
	}, [props.access_token, props.match.params.webinar_id]);

	useEffect(() => {
		document.body.style.overflowY = "hidden";
	}, []);

	const enableScroll = () => {
		document.body.style.overflowY = "auto";
		document.title = "Sound Pod | HelloWorlds";
		// props.history.push("/rooms/");
		props.history.goBack()
	};

	const registerStudent = () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + props.access_token);
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({ random: "random" });

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		var url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/${props.match.params.webinar_id}/register`;

		fetch(url, requestOptions)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then((result) => {
				if (result["success"] === true) {
					swal(
						"Participation confirmed! Have the Zoom app installed."
					);
					setRoomData({
						...roomData,
						registered: 1,
					})
					window.Moengage.track_event("WebinarPurchase", {
						first_name: props.student.first_name,
						last_name: props.student.last_name,
						grade: props.student.grade,
						age: props.student.dob,
						email: props.student.email,
						mobile_number: props.student.mobile_number,
						signup_date: onlyDate(props.student.created_at),
						signup_time: onlyTime(props.student.created_at),
						webinar_title: roomData.title,
						time_slot: scheduleStartTime(roomData.start_time),
						zoom_link: result.data.zoom_link,
					});
				}
			});
	};

	let current_epoch = Math.floor(Date.now() / 1000);
	let past = 0;
	if (current_epoch > roomData.end_time) {
		past = 1;
	}

	const Backdrop = () => {
		return ReactDOM.createPortal(
			<>
				<div onClick={enableScroll} className="single-webinar-backdrop"></div>
			</>,
			document.getElementById("backdrop-webinar")
		);
	};

	const Modal = () => {
		return ReactDOM.createPortal(
			<>
				{/* <Link to="/rooms/"> */}
				<button onClick={enableScroll} className="single-webinar-close-btn">
					<i
						style={{ color: "white" }}
						className="fa fa-chevron-left"
						aria-hidden="true"
					></i>
				</button>
				{/* </Link> */}
				<div className="single-webinar-container">
					<div>
						<LazyLoad
							classes="single-webinar-img"
							src={roomData.featured_image || "/default_webinar_img.png"}
							alt="banner"
							wrapper="image-lazyload"
						/>
						<div className="single-webinar-content">
							<h1>{roomData.title}</h1>
							<div className="webinar-hosted-by">
								<div>
									<Link to={`/mentor/${roomData.mentor.slug}`}>
										<LazyLoad src={roomData.mentor.avatar_url} alt="host" />
									</Link>
									<p
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
										}}
									>
										<span>
											By&nbsp;
											<Link to={`/mentor/${roomData.mentor.slug}`}>
												{roomData.mentor.first_name}
											</Link>
										</span>
										<RWebShare
											data={{
												text:
													"Hey, Checkout this session on HelloWorlds: " +
													roomData.title,
												url:
													"https://app.helloworlds.co/room/" +
													props.match.params.webinar_id,
												title: roomData.title,
											}}
											onClick={() => console.log("shared successfully!")}
										>
											<div className="share-div m-0">
												<LazyLoad
													src="/icons/share.png"
													alt="Share Icon"
													classes="share-icon"
													style={{ height: "15px", width: "15px" }}
												/>
												<span>Share</span>
											</div>
										</RWebShare>
									</p>
								</div>
								<p>
									<i className="fa fa-calendar" aria-hidden="true"></i>
									{moment(roomData.start_time).format("LLL")}
								</p>
							</div>
							<SingleWebinarInfo
								access_token={props.access_token}
								past={past}
								webinar_id={props.match.params.webinar_id}
								subjects={roomData.subjects}
								grades={roomData.grades ? roomData.grades : []}
								mentor={roomData.mentor}
								duration={moment(roomData.end_time).diff(roomData.start_time, "minutes")}
							/>
							<WebinarContent content={roomData.description} />
							<div className="py-3">
								<Title title="Things to note" img="no-img" url="no-url" />
								{roomData?.notes?.length > 0 ? (
									<ThingsToDo notes={roomData.notes} />
								) : (
									<ThingsToDo
										notes={[
											{
												notes:
													"Make sure you have an active internet connection :)",
											},
										]}
									/>
								)}
							</div>


							{/* <Title
								title={`Meet your Host ${mentorName}!`}
								img={roomData.mentor.avatar_url}
								url={`/mentor/${roomData.mentor.slug}`}
							/> */}
							{/* <WebinarContent content={roomData.mentor.about_me} /> */}
						</div>
					</div>
				</div>
				<div className="single-webinar-actions">
					<WebinarDateSelection
						update_token={props.update_token}
						access_token={props.access_token}
						show_time_box={0}
						register_student={registerStudent}
						registered={roomData.registered}
						past={past}
						start_time={roomData.start_time}
						end_time={roomData.end_time}
						price={0}
						webinar_id={props.match.params.webinar_id}
						title={roomData.title}
						featured_image={roomData.featured_image}
						mentor_name={mentorName}
						location={props.location}
					/>
				</div>
			</>,
			document.getElementById("modal-webinar")
		);
	};

	return (
		<>
			<Backdrop />
			<Modal />
		</>
	);
};

export default SingleWebinarPopup;
