import LazyLoad from "../../Commons/LazyLoad";
import styles from "./ActivityCard.module.css";
import moment from "moment/moment";
import { useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ImageSlider from "../ImageSlider";

/**
 * For handling profile picture
 * @param {string} image url
 * @returns {string} url if image is not null or undefined or empty string else default image
 */
const handleProfilePic = (img) => {
	if (img === null || img === undefined) {
		return "/student.png";
	} else if (img === "" || img === "NA") {
		return "/student.png";
	} else {
		return img;
	}
};

const ActivityCard = (props) => {
	const activity = props.activity;
	const access_token = props.access_token;
	const student_uuid = localStorage.getItem("student_uuid");
	const fetchActivity = props.fetchActivity;

	//Checking if the post is already liked by the user
	const [isLiked, setIsLiked] = useState(
		activity?.latest_reactions?.like?.filter(
			(like) => like?.user_id === student_uuid
		)[0]?.user_id
	);
	const [likeCount, setLikeCount] = useState(
		activity?.reaction_counts?.like || 0
	);
	const [likeLoading, setLikeLoading] = useState(false);

	const [comment, setComment] = useState(
		activity?.latest_reactions?.comment || []
	);
	const [commenting, setCommenting] = useState(false);
	const [commentErr, setCommentErr] = useState(false);
	const [showComments, setShowComments] = useState(false);

	let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/`;
	let myHeaders = new Headers();
	myHeaders.append("Authorization", "Bearer " + access_token);

	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		redirect: "follow",
	};
	const commentRef = useRef(null);
	const [comments, setComments] = useState(5);
	const [hasMoreComments, setHasMoreComments] = useState({
		hasMore: activity?.reaction_counts?.comment > 5 ? true : false,
		loading: false,
		alreadyLoaded: false,
		loaded: 0,
	});

	/**
	 * Opening profile sidebar
	 * @param {object} User data
	 */
	const handleOpenProfileSidebar = (data) => {
		if (activity?.actor === data?.id) return;
		const newData = {
			active_since: data?.data?.joining_date,
			last_seen: data?.data?.last_seen,
			uuid: {
				student: {
					avatar_url: data?.data?.avatar_url,
					city: {
						name: data?.data?.city,
					},
					interest: data?.data?.interest?.map((item) => {
						return {
							name: item,
						};
					}),
					first_name: data?.data?.name,
					last_name: "",
					dob: data?.data?.date_of_birth,
				},
			},
		};
		props.handleOpenProfileSidebar(newData);
	};

	// Ading like to the post
	const handleLikeOnPost = async (worldId, activityId) => {
		try {
			setLikeLoading(true);
			setIsLiked(true);
			setLikeCount(likeCount + 1);
			const response = await fetch(
				`${url}${worldId}/add-like/${activityId}`,
				requestOptions
			);
			const data = await response.json();
			if (data.success) {
				await fetchActivity();
				setLikeLoading(false);
			} else {
				setIsLiked(false);
				setLikeLoading(false);
				setLikeCount(likeCount - 1);
			}
		} catch (error) {
			console.error("Like Error : ", error);
		}
	};

	// Removing like or comment from the post
	const handleDislikeOnPost = async (worldId, reactionId) => {
		try {
			setLikeLoading(true);
			setIsLiked(false);
			setLikeCount(likeCount - 1);
			const response = await fetch(
				`${url}${worldId}/remove-like-comment/${reactionId}`,
				requestOptions
			);
			const data = await response.json();
			if (data.success) {
				await fetchActivity();
				setLikeLoading(false);
			} else {
				setIsLiked(true);
				setLikeLoading(false);
				setLikeCount(likeCount + 1);
			}
		} catch (error) {
			console.error("Dislike Error : ", error);
		}
	};

	// Adding comment to the post
	const handleCommentOnPost = async (worldId, activityId) => {
		try {
			const commentData = commentRef.current.value;
			if (!commentData && commentData === "") {
				return;
			}
			commentRef.current.value = "";
			setCommenting(true);
			setCommentErr(false);
			const newCommentData = [
				{
					data: {
						text: commentData,
					},
					user: {
						data: {
							avatar_url: props.student?.avatar_url,
							name: props.student?.first_name + " " + props.student?.last_name,
						},
					},
				},
			];
			setComment(newCommentData.concat(comment));
			const response = await fetch(
				`${url}${worldId}/add-comment/${activityId}`,
				{
					method: "POST",
					redirect: "follow",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: "Bearer " + access_token,
					},
					body: JSON.stringify({
						text: commentData,
					}),
				}
			);
			const data = await response.json();
			if (data.success === true) {
				setCommentErr(false);
				setCommenting(false);
				commentRef.current.value = "";
				await fetchActivity();
			} else {
				setCommentErr(true);
			}
		} catch (error) {
			console.error("Comment Error : ", error);
		}
	};

	//First letter capitalize
	const changeNameToLowerCase = (name) => {
		if (!name || name === "" || name === undefined) return;
		const fullNameCaps = name?.split(/\s+/);
		const fullName = fullNameCaps.map(
			(name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
		);
		return fullName.join(" ");
	};

	// changing date and time format
	const changeDateTime = (dateTime) => {
		const newDateTime = moment.utc(dateTime).local().fromNow();
		const newDate = newDateTime.split(" ");
		if (newDate[0] === "a" || newDate[0] === "an") {
			const time = "1" + newDate[1].slice(0, 1) + " ago";
			if (time === "1f ago") {
				return "Just now";
			} else {
				return time;
			}
		}

		const newTime = newDate[0] + newDate[1].slice(0, 1);
		return newTime + " ago";
	};

	// load more comments
	const loadMoreComments = async () => {
		try {
			setHasMoreComments((prev) => {
				return { ...prev, hasMore: false, loading: true };
			});
			let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/`;
			let myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + props.access_token);

			let requestOptions = {
				method: "GET",
				headers: myHeaders,
				redirect: "follow",
			};
			const response = await fetch(
				`${url}${activity.world.id}/get-comment/${activity.id}`,
				requestOptions
			);
			const data = await response.json();
			if (data.success) {
				setComment(data.data.results);
				setComments(data.data.results.length);
				setHasMoreComments({
					hasMore: false,
					loading: false,
					alreadyLoaded: true,
					loaded: data.data.results.length,
				});
			}
		} catch (error) {
			console.error("Load More Comments Error : ", error);
		}
	};

	// hide more comments
	const hideMoreComments = () => {
		setComment(activity?.latest_reactions?.comment || []);
		setHasMoreComments({
			hasMore: true,
			loading: false,
			alreadyLoaded: false,
			loaded: 0,
		});
	};

	const CommentsLoadingSkeleton = () => {
		return (
			<div className="comment-loading d-flex my-2 align-items-center">
				<div className="comment-loading__image">
					<Skeleton circle={true} height={40} width={40} />
				</div>
				<div className="comment-loading__text ms-3">
					<Skeleton height={25} width={210} />
				</div>
			</div>
		);
	};

	/**
	 * Show liked modal
	 * @param {object} likesData
	 */
	const showLikedModal = (likesData) => {
		props.setLikedModal(true);
		props.setLikedModalData(likesData);
	};

	const handleCommentsShow = () => {
		setShowComments(!showComments);
	};

	return (
		<div className="gopher-font">
			<div className={styles.author}>
				<div className={styles["author-details"]}>
					<LazyLoad src={handleProfilePic(activity?.actor_data?.avatar_url)} />
					<div className="d-flex flex-row align-items-start">
						<h6 className="me-0 fw-bold">
							{activity?.actor_data?.name &&
								changeNameToLowerCase(activity?.actor_data?.name)}
						</h6>
						<h6 className={styles.time}>{changeDateTime(activity.time)}</h6>
					</div>
				</div>
			</div>
			<p
				className="mx-2 mb-2"
				style={{ fontSize: "18px", whiteSpace: "pre-line" }}
			>
				{activity.text}
			</p>
			{activity.tag && activity.tag.length > 0 && (
				<p className="my-0 mb-2 mx-2">
					{activity.tag.map((tag, index) => {
						return <span key={index}>{tag?.name + " "}</span>;
					})}
				</p>
			)}
			{
				activity?.image ? (
					<div className={styles["activity-image"]} style={{ width: "100%" }}>
						{
							typeof activity?.image === 'string' ? (
								activity?.image?.includes("youtube") ? (
									<iframe
										src={activity?.image}
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									/>
								) : (
									<ImageSlider images={[activity?.image]} actorName={activity.actor_data?.name} />
								)
							) : (
								Array.isArray(activity?.image) && activity?.image.length > 0 ? (
									<ImageSlider images={activity?.image} actorName={activity.actor_data?.name} />
								) : null
							)
						}
					</div>
				) : null
			}
			<div className={styles.liked}>
				{likeCount > 0 ? (
					<p onClick={() => showLikedModal(activity?.latest_reactions?.like)}>
						<i className={`fa fa-thumbs-up me-1`} aria-hidden="true"></i>
						{isLiked && likeCount === 1
							? "You liked this"
							: isLiked && likeCount === 2
								? "You and 1 other"
								: isLiked && likeCount > 2
									? `You and ${likeCount - 1} others`
									: null}
						{!isLiked && likeCount === 1
							? `${activity?.latest_reactions?.like?.filter(
								(like) => like?.user_id !== student_uuid
							)[0]?.user?.data?.name
							} liked this`
							: !isLiked && likeCount === 2
								? `${activity?.latest_reactions?.like[0]?.user?.data?.name} and ${likeCount - 1
								} other`
								: !isLiked && likeCount > 2
									? `${activity?.latest_reactions?.like[0]?.user?.data?.name} and ${likeCount - 1
									} others`
									: null}
					</p>
				) : null}
			</div>

			<div className={styles.stats}>
				<p>
					<button
						className="text-secondary"
						style={
							props.sneakPeek
								? { cursor: "not-allowed", background: "transparent" }
								: { background: "transparent", cursor: "pointer" }
						}
						onClick={() => {
							if (isLiked) {
								if (likeLoading) {
									return;
								}
								handleDislikeOnPost(
									activity?.world?.id,
									activity?.latest_reactions?.like?.filter(
										(like) => like.user_id === student_uuid
									)[0].id
								);
							} else {
								if (likeLoading) {
									return;
								}
								handleLikeOnPost(activity?.world?.id, activity.id);
							}
						}}
					>
						<i
							className={`fa fa-thumbs-up me-1 ${isLiked ? "text-danger" : ""
								} `}
							aria-hidden="true"
						></i>
						{likeCount}
					</button>
				</p>
				<p
					style={{ cursor: "pointer" }}
					className="text-secondary"
					onClick={handleCommentsShow}
				>
					<i className="fa fa-comment me-1" aria-hidden="true"></i>
					{activity?.reaction_counts?.comment || 0}
				</p>
			</div>

			{showComments ? (
				<div className={styles.comments}>
					{commenting ? (
						<div className={styles["self-comment"]} style={{ opacity: "0.5" }}>
							<LazyLoad src={handleProfilePic(props.student?.avatar_url)} />
							<form
								method="POST"
								className="w-100"
								onSubmit={(e) => {
									e.preventDefault();
									handleCommentOnPost(activity?.world?.id, activity.id);
								}}
							>
								<div>
									<input
										type="text"
										placeholder="Leave a comment"
										ref={commentRef}
										disabled={commenting || props.sneakPeek}
										style={props.sneakPeek ? { cursor: "not-allowed" } : {}}
									/>
									{window.innerWidth < 500 && (
										<button
											disabled={commenting || props.sneakPeek}
											type="submit"
											style={{
												background: "transparent",
												position: "relative",
												bottom: "34px",
												left: "195px",
											}}
										>
											<i className="fa fa-paper-plane" aria-hidden="true"></i>
										</button>
									)}
								</div>
							</form>
						</div>
					) : (
						<div className={styles["self-comment"]}>
							<LazyLoad src={handleProfilePic(props.student?.avatar_url)} />
							<form
								method="POST"
								className="w-100"
								onSubmit={(e) => {
									e.preventDefault();
									handleCommentOnPost(activity?.world?.id, activity.id);
								}}
							>
								<div>
									<input
										type="text"
										placeholder="Leave a comment"
										ref={commentRef}
										disabled={commenting || props.sneakPeek}
										style={props.sneakPeek ? { cursor: "not-allowed" } : {}}
									/>
									{window.innerWidth < 500 && (
										<button
											disabled={commenting || props.sneakPeek}
											type="submit"
											style={{
												background: "transparent",
												position: "relative",
												bottom: "34px",
												left: "195px",
											}}
										>
											<i className="fa fa-paper-plane" aria-hidden="true"></i>
										</button>
									)}
								</div>
							</form>
						</div>
					)}

					{commentErr && (
						<p style={{ color: "red", margin: "10px 0 12px 10px" }}>
							Something went wrong, please try again
						</p>
					)}

					{comment?.length > 0 && comment?.length <= comments
						? comment?.map((item, index) =>
							commenting ? (
								<div
									key={index}
									className={styles["other-comments"]}
									style={{ opacity: "0.5" }}
								>
									<LazyLoad
										src={handleProfilePic(item?.user?.data?.avatar_url)}
									/>
									<p>
										<strong>
											{changeNameToLowerCase(item?.user?.data?.name)}
										</strong>{" "}
										{item?.data?.text}
									</p>
								</div>
							) : (
								<div
									key={index}
									className={styles["other-comments"]}
									onClick={() => handleOpenProfileSidebar(item?.user)}
									style={{
										cursor:
											activity?.actor === item?.user?.id ? "" : "pointer",
										width: "fit-content",
									}}
								>
									<LazyLoad
										src={handleProfilePic(item?.user?.data?.avatar_url)}
									/>
									<p>
										<strong>
											{changeNameToLowerCase(item?.user?.data?.name)}
										</strong>{" "}
										{item?.data?.text}
									</p>
								</div>
							)
						)
						: comment?.length > comments
							? comment?.slice(0, comments).map((item, index) =>
								commenting ? (
									<div
										key={index}
										className={styles["other-comments"]}
										style={{ opacity: "0.5" }}
									>
										<LazyLoad
											src={handleProfilePic(item?.user?.data?.avatar_url)}
										/>
										<p>
											<strong>
												{changeNameToLowerCase(item?.user?.data?.name)}
											</strong>{" "}
											{item?.data?.text}
										</p>
									</div>
								) : (
									<div key={index} className={styles["other-comments"]}>
										<LazyLoad
											src={handleProfilePic(item?.user?.data?.avatar_url)}
										/>
										<p>
											<strong>
												{changeNameToLowerCase(item?.user?.data?.name)}
											</strong>{" "}
											{item?.data?.text}
										</p>
									</div>
								)
							)
							: null}

					{hasMoreComments.loading && (
						<SkeletonTheme baseColor="gray" highlightColor="white">
							<Skeleton wrapper={CommentsLoadingSkeleton} count={4} />
						</SkeletonTheme>
					)}

					{hasMoreComments.hasMore && (
						<p
							style={{
								fontSize: "16px",
								textAlign: "center",
								fontWeight: "bold",
								cursor: "pointer",
								color: "grey",
							}}
							onClick={() => {
								loadMoreComments();
							}}
						>
							{`View more comments`}
						</p>
					)}
					{hasMoreComments.alreadyLoaded && (
						<p
							style={{
								fontSize: "16px",
								textAlign: "center",
								fontWeight: "bold",
								cursor: "pointer",
								color: "grey",
							}}
							onClick={() => {
								hideMoreComments();
							}}
						>
							{`Hide comments`}
						</p>
					)}
				</div>
			) : null}
		</div>
	);
};

export default ActivityCard;
