import { createSlice } from '@reduxjs/toolkit';

const mentorsSlice = createSlice({
    name: 'mentors',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        getAllMentors: (state) => {
            state.loading = true;
        },
        getAllMentorsSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        getAllMentorsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    getAllMentors,
    getAllMentorsSuccess,
    getAllMentorsFailure,
} = mentorsSlice.actions;
export default mentorsSlice.reducer;
