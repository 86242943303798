
import React, { useState, useEffect } from 'react'
import styles from './SingleClass.module.css'
import ReactDOM from "react-dom";
import moment from "moment";
import { extractUrlParamSubscription } from "../../utils";
import { Spinner, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import LoginPopup from "../../Auth/LoginPopup";

// getting the next subscription date
const nextSubDate = () => {
    const currentDate = moment().startOf('day');
    const newDate = currentDate.add(30, 'days');
    const formattedDate = newDate.format('MMM DD, YYYY');
    return formattedDate;
};

const SingleClass = (props) => {
    const [classData, setClassData] = useState(null);
    const [batches, setBatches] = useState(null);
    let subscriptionId = extractUrlParamSubscription();

    const goBack = () => {
        document.body.style.overflowY = "auto";
        props.history.push(`/worlds/${props.match.params.id}/activity/courses`)
    };


    const confirmSubscription = async () => {
        let url = `${process.env.REACT_APP_BASE_URL}/rest/students/courses/${props.match.params.course_id}/subscribe/confirm/${subscriptionId}`;
        let requestOptions = {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " + props.access_token,
            },
        };
        try {
            const response = await fetch(`${url}`, requestOptions);
            const data = await response.json();
            if (!data.success) throw new Error(data?.message);
            swal({
                title: "Subscription Confirmed.",
                text: `You have successfully enrolled to ${classData?.title}`,
            }).then(() => {
                props.history.push(`/worlds/${props.match.params.id}/activity/courses`);
            });
        } catch (error) {
            console.error("Subscription confirm error : ", error);
        }
    };

    useEffect(() => {
        (async function () {
            document.body.style.overflowY = "hidden";
            if (!props.match.params.course_id || !props.access_token) return;
            if (props?.location?.state?.courseData) {
                setClassData(props.location.state.courseData);
            }

            let url = `${process.env.REACT_APP_BASE_URL}/rest/students/courses/${props.match.params.course_id}`;
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + props.access_token);

            let requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            }
            try {
                if (!props?.location?.state?.courseData) {
                    const [classesResponse, batchResponse] = await Promise.all([
                        fetch(url, requestOptions),
                        fetch(`${url}/batch`, requestOptions),
                    ]);
                    const classesData = await classesResponse.json();
                    const batchData = await batchResponse.json();
                    setClassData(classesData?.data);
                    setBatches(batchData?.data?.results);
                } else {
                    const batchResponse = await fetch(`${url}/batch`, requestOptions);
                    const batchData = await batchResponse.json();
                    setBatches(batchData?.data?.results);
                }
            } catch (error) {
                console.error("Single Class error : ", error);
            }

        })();
    }, [props.match.params.course_id, props.access_token]);

    // Check if subscription is confirmed
    useEffect(() => {
        if (subscriptionId) {
            confirmSubscription();
        }
    }, [subscriptionId]);

    const loginPopup = () => {
        if (props.access_token === null || props.access_token === undefined) {
            var swal_popup = document.createElement("div");
            ReactDOM.render(
                <React.StrictMode>
                    <LoginPopup update_token={props.update_token} />
                </React.StrictMode>,
                swal_popup
            );
            swal({
                content: swal_popup,
                buttons: false,
                className: "swal-login",
                closeOnClickOutside: false,
            });
        }
    };

    const SubscriptionPopupModal = (props) => {
        const [subscribeLoading, setSubscribeLoading] = useState(false)

        const handleSubscription = async (batchId) => {
            if (!props.access_token || !batchId || subscribeLoading) return console.log("No access token or batch id");
            setSubscribeLoading(true);
            let url = `${process.env.REACT_APP_BASE_URL}/rest/students/courses/${props.match.params.course_id}/subscribe`;
            let requestOptions = {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: "Bearer " + props.access_token,
                },
                body: JSON.stringify({
                    batch_id: batchId,
                    world_id: Number(props.match.params.id),
                }),
                redirect: "follow",
            };
            try {
                const response = await fetch(`${url}`, requestOptions);
                const data = await response.json();
                if (data?.status_code === 403) {
                    swal({
                        title: "Subscription Failed.",
                        text: data?.message,
                        icon: "error",
                    });
                    return;
                }
                if (data?.data?.redirect && data?.data?.url) {
                    window.location.href = data?.data?.url;
                } else {
                    throw new Error(data?.message);
                }
            } catch (error) {
                console.error("Subscription create error : ", error);
                swal({
                    title: "Subscription Failed.",
                    text: error?.message,
                    icon: "error",
                });
            } finally {
                setSubscribeLoading(false);
            }
        }

        return (
            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 payment-popup no-padding">
                <div className="payment-popup-content">
                    <div className="row no-margin first-section-payment">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
                            <img
                                className="availability-cross-icon"
                                src="/icons/cross.jpg"
                                alt="availability-cross-icon"
                                onClick={() => swal.close()}
                                style={{
                                    cursor: "pointer",
                                    borderRadius: "50%",
                                }}
                            />
                            <img
                                src="/default_webinar_img.png"
                                alt={props.title}
                                className="payment-img"
                            />
                        </div>
                    </div>
                    <div className="payment-popup-text-content gopher-font">
                        <div className=" no-margin second-section-payment">
                            <p className="mb-1">Enrolling to </p>
                            <h3 style={{ fontSize: window.innerWidth > 500 ? "27px" : "22px" }} className='mb-1'>{props.title}</h3>
                        </div>

                        <div className="row no-margin third-section-payment my-3">
                            <div className="col-6 no-padding">
                                <h4>Subtotal</h4>
                            </div>
                            <div className="col-6 no-padding">
                                <p>₹ {Number(props.price)}</p>
                                <h6 style={{ fontWeight: "400", fontSize: "12px", textAlign: "right" }}>Tax Included</h6>
                            </div>
                        </div>

                        <div className="row no-margin fourth-section-payment mb-3">
                            <div className="col-6 no-padding">
                                <h4>Total</h4>
                            </div>
                            <div className="col-6 no-padding">
                                <p>
                                    ₹{" "} {Number(props.price)}
                                    <span style={{ fontSize: "12px", fontWeight: "300" }}>/month</span>
                                </p>
                            </div>
                        </div>

                        <div className="row no-margin mb-3">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
                                <p className="">
                                    The subscription will automatically renew every 30 days. (next subscription on {" "}
                                    <span className="text-info">
                                        {nextSubDate()}
                                    </span>) {" "}
                                </p>
                            </div>
                        </div>

                        <div className="row no-margin">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
                                <p className="cancellation-payment-popup">
                                    Cancellation/Refund Policy
                                </p>
                                <p className="cancellation-text-popup">
                                    This subscription can be cancelled anytime from <a href={`${window.location.origin
                                        }/past-recordings/worlds`} target="_blank">My Journey</a> page.
                                </p>
                            </div>
                            <div className="clear"></div>

                        </div>
                        <div className="payment-popup-btn-div mt-3">
                            <button
                                className="pay-btn"
                                onClick={() => handleSubscription(props?.batch?.id)}
                                disabled={subscribeLoading}
                            >
                                {
                                    subscribeLoading ? (
                                        <Spinner animation="border" variant="light" size="sm" />
                                    ) : "Proceed to Subscribe"
                                }
                            </button>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 t-c-accept no-padding">
                            <p
                                className="cancellation-text-popup muted-text"
                                style={{ fontSize: "11px" }}
                            >
                                By clicking on the Proceed to Subscribe
                                button, you agree to the{" "}
                                <a
                                    href="https://www.helloworlds.co/refund-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Cancellation and Refunds Policy
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const subscribePopup = (batch) => {
        if (props.access_token === null || props.access_token === undefined) {
            loginPopup();
        } else {
            var subscribe_popup = document.createElement("div");
            ReactDOM.render(
                <React.StrictMode>
                    <SubscriptionPopupModal
                        title={classData?.title}
                        price={classData?.fee}
                        access_token={props.access_token}
                        batch={batch}
                        match={props.match}
                    />
                </React.StrictMode>,
                subscribe_popup
            );
            swal({
                content: subscribe_popup,
                buttons: false,
                className: "swal-qna",
            });
        }
    };

    const Backdrop = () => {
        return ReactDOM.createPortal(
            <>
                <div className="single-webinar-backdrop" style={{ backgroundColor: "var(--background_primary)" }}></div>
            </>,
            document.getElementById("backdrop-webinar")
        );
    };

    const Modal = () => {
        return ReactDOM.createPortal(
            <>
                <button onClick={goBack} className="single-webinar-close-btn">
                    <i
                        style={{ color: "white" }}
                        className="fa fa-chevron-left"
                        aria-hidden="true"
                    ></i>
                </button>
                <div className="single-webinar-container" style={{ width: "100%", maxWidth: "1100px" }}>
                    <div>
                        <img src="/default_webinar_img.png" alt={classData?.title} className='single-webinar-img' />
                        <div className="single-webinar-content">
                            <h1>{classData?.title}</h1>
                            <div className={styles.content}>
                                <div className={styles.details}>
                                    <ul className={styles.listItems}>
                                        <li>
                                            <p className='text-muted'>Class Duration</p>
                                            <h6>{classData?.classes[0]?.name?.replace("courseDuration", "")}</h6>
                                        </li>

                                        <li>
                                            <p>Class Length</p>
                                            <h6>{classData?.duration}/class</h6>
                                        </li>

                                        <li>
                                            <p className='text-muted'>Meets</p>
                                            <h6>
                                                {
                                                    classData?.classes[0]?.description?.replace("classesPerWeek", "")
                                                }x/week
                                            </h6>
                                        </li>

                                        <li>
                                            <p className='text-muted'>Upto</p>
                                            <h6>{classData?.group_size} participants</h6>
                                        </li>

                                        <li>
                                            <p className='text-muted'>Price</p>
                                            <h6>{classData?.fee}/month</h6>
                                        </li>

                                    </ul>
                                </div>

                                <Row>
                                    <Col md={8}>
                                        <div className={styles.description}>
                                            <div>
                                                <h4 className='mb-3'>Description</h4>
                                                <div className={styles.descriptionContent} dangerouslySetInnerHTML={{ __html: classData?.description }} />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <div className={styles.pricing}>
                                            <h4 className='mb-3'>Upcoming Batches:</h4>
                                            <div className={styles.batches}>
                                                {
                                                    batches && batches?.length ? (
                                                        batches.map((batch) => (
                                                            <div className={styles.batch}>
                                                                <div className={styles.header}>
                                                                    <p>
                                                                       Every {batch?.additional_info} at {moment.utc(batch?.start_time * 1000).format("h:mm A")}
                                                                    </p>
                                                                </div>
                                                                <div className={styles.body}>
                                                                    <p>
                                                                        Starting on {moment.utc(batch?.start_time * 1000).format("DD MMMM")}
                                                                    </p>
                                                                    <p>
                                                                        Ending on {moment.utc(batch?.end_time * 1000).format("DD MMMM")}
                                                                    </p>
                                                                </div>
                                                                <div className={styles.footer}>
                                                                    <button
                                                                        className="attend-now-btn-webina text-white p-2"
                                                                        onClick={() => subscribePopup(batch)}
                                                                    >
                                                                        Enroll
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        ))
                                                    ) : (
                                                        <div >
                                                            <h6>No batches available</h6>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Col>


                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>,
            document.getElementById("modal-webinar")
        );
    };

    return (
        <>
            <Backdrop />
            <Modal />
        </>
    );
};

export default SingleClass;
