import styles from "./ClubCard.module.css";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ClubCard = (props) => {
	return (
		<Col md={6} lg={4} className={styles.cardOuter}>
			<Link className={styles.card} to={`/worlds/${props.club_id}`}>
				<div className={styles.cardImg}>
					<img src={props.img_url} alt={props?.title} />
				</div>
				<div className={styles.cardContent}>
					<h5>
						{props.title}
					</h5>
					<div className={styles.features}>
						<div className={styles.feature}>
							{props.subject}
						</div>

						<div className={styles.feature}>
							{`Grades ${props.grades}`}
						</div>
					</div>
					<p>
						{props.description}
					</p>
					<div className={styles.mentor}>
						<img src={props.instructorImg} alt={props.instructor} />
						<h6 className="text-info">
							{props.instructor}
						</h6>
					</div>
				</div>
			</Link >
		</Col>
	);
};

export default ClubCard;
