import css from "./Recommendations.module.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { capitalizeName } from "../utils";
import LazyLoad from "../Commons/LazyLoad";

const Recommendations = ({ type, id }) => {
	const [recommendation, setRecommendation] = useState([]);
	useEffect(() => {
		const url = `${process.env.REACT_APP_BASE_URL}/rest/students/recommendation?type=${type}&id=${id}`;
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setRecommendation(data.data.results);
				console.log(data);
			})
			.catch((error) => console.log(error));
	}, [type, id]);

	return (
		<div className={css["carousel-container"]}>
			<Carousel
				additionalTransfrom={0}
				arrows
				autoPlaySpeed={3000}
				centerMode={false}
				className=""
				containerClass="container-exp-page"
				dotListClass=""
				draggable
				focusOnSelect={false}
				infinite={true}
				itemClass=""
				keyBoardControl
				minimumTouchDrag={80}
				renderButtonGroupOutside={false}
				renderDotsOutside={false}
				responsive={{
					desktop: {
						breakpoint: {
							max: 3000,
							min: 1140,
						},
						items: 3,
						partialVisibilityGutter: 40,
					},
					tablet: {
						breakpoint: {
							max: 1140,
							min: 700,
						},
						items: 2,
						partialVisibilityGutter: 30,
					},
					mobile: {
						breakpoint: {
							max: 700,
							min: 0,
						},
						items: 1,
						partialVisibilityGutter: 30,
					},
				}}
				showDots={false}
				sliderClass=""
				slidesToSlide={1}
				swipeable
			>
				{recommendation.map((item) => (
					<Link
						to={
							item.type === "course"
								? `/course/${item.id}`
								: `/experience/${item.id}`
						}
						key={item.id}
						style={{ textDecoration: "none" }}
					>
						<div className={css["card-container"]}>
							<LazyLoad
								src={item.featured_image || "/public/default_webinar_img.png"}
								alt={item.title}
								classes={css["card-img"]}
							/>
							<section className={css.details}>
								<h5>{item.title}</h5>
								{item.mentor.city && (
									<p>
										<i className="fa fa-map-marker me-1" aria-hidden="true"></i>{" "}
										Live from {item.mentor.city.name},{" "}
										{item.mentor.city.country}
									</p>
								)}
								<p>
									{item.mentor.avatar_url && (
										<LazyLoad src={item.mentor.avatar_url} alt="author" />
									)}
									{capitalizeName(
										`${item.mentor.first_name} ${item.mentor.last_name || ""}`
									)}
								</p>
							</section>
						</div>
					</Link>
				))}
			</Carousel>
		</div>
	);
};

export default Recommendations;
