import React, { Component } from "react";
import LazyLoad from "../Commons/LazyLoad";
import { Link } from "react-router-dom";

class Title extends Component {
	render() {
		let img_class = "hide-it";
		if (this.props.img !== "no-img") {
			img_class = "mentor-big-detail-page";
		}
		return (
			<div className="d-flex align-items-center gap-3">
				{this.props.img !== "no-img" ? (
					<Link to={this.props.url}>
						<LazyLoad
							src={this.props.img}
							alt="User Avatar"
							classes={img_class}
						/>
					</Link>
				) : (
					""
				)}{" "}
				<h4 className="webinar-title mb-0">
					{this.props.title}
				</h4>
			</div>
		);
	}
}

export default Title;
