import React, { Component } from 'react';
import {
  Redirect
} from "react-router-dom";

class ShowWebinar extends Component {

	constructor(props) {
	  	super(props);
	    this.state = {
	      url: ""
	    };
	}

	componentDidMount() {

		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/${this.props.match.params.webinar_id}/url`;

		console.log(url);

      	fetch(url, requestOptions)
	        .then(res => res.json())
	        .then(
	          (result) => {
	          	//console.log(result);
	          	//console.log(result["data"]["results"]);
	          	//let final_url = "https://live.edvolve.in" + result["data"].launch_url.substring(25);
	          	let final_url = result["data"].launch_url;
	          	console.log(final_url);
	          	window.location.href = final_url; 
	          	this.setState({
	                url: final_url
	             });
	          },
	          (error) => {
	          }
      		);
  	}

	render(){
		if(this.props.access_token===null) {
	       return <Redirect to='/login'/>;
	    }

     	return null;

	    /*
		return(
		<div className="webinar-iframe-container">
			<iframe className="webinar-iframe" src={this.state.url} title="View Webinar" frameBorder="0" width="100%" height="100vh" scrolling="auto" allow="camera;microphone" allowFullScreen></iframe>
		</div>
			);
		*/
	}
}

export default ShowWebinar;
