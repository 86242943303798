import React, { Component } from "react";
import OtpInput from "react-otp-input";
import Timer from "react-compound-timer";

class EnterOTP extends Component {
	constructor(props) {
		super(props);
		this.state = {
			otp: "",
			show_timer: true,
			no_resend_option: false,
			first_time_user: this.props.first_time_user,
			btnDisabled: true,
		};
		this.handleChange = this.handleChange.bind(this);
		this.otpSubmit = this.otpSubmit.bind(this);
		this.resendOTP = this.resendOTP.bind(this);
		this.showResend = this.showResend.bind(this);
	}

	handleChange(otp) {
		if (otp.length === 6) {
			this.setState({ btnDisabled: false });
		} else {
			this.setState({ btnDisabled: true });
		}
		this.setState({
			otp: otp,
		});
	}

	otpSubmit(e) {
		e.preventDefault()
		this.props.otp_handler(this.state.otp);
	}

	showResend() {
		this.setState({
			show_timer: false,
		});
	}

	resendOTP() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({ phone_number: this.props.mob_number });

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			`${process.env.REACT_APP_BASE_URL}/auth/students/sendotp`,
			requestOptions
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error("Something went wrong. Please contact support.");
					// return Promise.reject();
				}
			})
			.then((result) => {
				this.setState({
					no_resend_option: true,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		//alert(this.state.first_time_user);
		return (
			<div
				id="enter_otp"
				className="login-detail-box col-sm-12 col-md-12 col-lg-12 col-xs-12"
			>
				<h3 style={{ color: "inherit", textAlign:"left", margin:0 }}>
					<span style={{color:"var(--fontColor-secondary"}}>OTP sent to</span> {this.props.mob_number}
				</h3>
				<form onSubmit={this.otpSubmit}>
					<OtpInput
						value={this.state.otp}
						onChange={this.handleChange}
						numInputs={6}
						separator={<span>-</span>}
						isInputNum={true}
						containerStyle="otp-box"
						inputStyle="partitioned-input"
						shouldAutoFocus={true}
					/>
					{this.state.no_resend_option ? (
						<div></div>
					) : this.state.show_timer ? (
						<div className="otp-timer-div">
							<Timer
								initialTime={60000}
								lastUnit="s"
								direction="backward"
								checkpoints={[{ time: 0, callback: () => this.showResend() }]}
							>
								Resend OTP in{" "}
								<span className="fw-bold">
									<Timer.Seconds /> seconds
								</span>
							</Timer>
						</div>
					) : (
						<div className="resend-otp-tex" onClick={this.resendOTP}>
							Resend OTP
						</div>
					)}
					{this.state.first_time_user === 0 ? (
						<div></div>
					) : (
						<p style={{ color: "inherit", textAlign: "left" }}>
							By signing up, you agree to the{" "}
							<a
								href="https://www.helloworlds.co/terms-and-conditions"
								target="_blank"
								rel="noopener noreferrer"
							>
								terms and conditions
							</a>{" "}
							and{" "}
							<a
								href="https://www.helloworlds.co/privacy-policy"
								target="_blank"
								rel="noopener noreferrer"
							>
								privacy policy
							</a>{" "}
							set by HelloWorlds.
						</p>
					)}
					<button
						disabled={this.state.btnDisabled}
						id="otp_number_btn"
						type="submit"
						className="login-btn"
						// onClick={this.otpSubmit}
						style={this.state.btnDisabled ? {} : { color: "white" }}
					>
						Confirm OTP
					</button>
				</form>
			</div>
		);
	}
}

export default EnterOTP;
