import React, { Component } from "react";
import {Container, Row, Col} from "react-bootstrap";

class Banner extends Component {
    render() {
        return (
            <div>
                <Container className="px-4 pt-4 pb-0">
                    <h1 className="fw-bolder">Outcome Driven, Interactive Video-Chat Classes</h1>
                    <Row>
                        <Col as="p" xs={12} md={7} style={{ fontSize: "18px", marginBottom:0 }}>
                            Connect with the best in the field via introductory
                            workshops & deeper courses in subjects you love!
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Banner;
