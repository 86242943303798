import { Link } from "react-router-dom";
import styles from "./Activity.module.css";

const JoindedClubs = (props) => {
    return (
        props.clubs.length > 0 ? (
            <div className="d-flex flex-column">
                {
                    props.clubs.map((club) => {
                        return (
                            <div className={styles.joinedClubItem} key={club.world.id}>
                                <Link to={`/worlds/${club.world.id}/activity`}>
                                    {
                                        props.currentClub === club.world.id ? (
                                            <>
                                                <span className={styles.activeClub}></span>
                                                <img
                                                    src={club?.world?.image}
                                                    alt={club?.world?.title}
                                                    title={club?.world?.title}
                                                />
                                                <div className={styles.clubName}>
                                                    {club?.world?.title}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={club?.world?.image}
                                                    alt={club?.world?.title}
                                                    title={club?.world?.title}
                                                />
                                                <div className={styles.clubName}>
                                                    {club?.world?.title}
                                                </div>
                                            </>

                                        )
                                    }
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        ) : (
            null
        )
    )
}

export default JoindedClubs