import React, { useState, useEffect, useContext } from "react";
import {
	Switch,
	Route,
	Redirect,
	withRouter,
} from "react-router-dom";
import ScrollIntoView from "./ScrollIntoView";

import Login from "./Auth/Login";
import Logout from "./Auth/Logout";
import Home from "./Home/Home";
import ShowWebinar from "./Webinar/ShowWebinar";
import WebinarRecording from "./Webinar/WebinarRecording";
import EditProfile from "./Auth/EditProfile";
import WebinarQna from "./Webinar/WebinarQna";
import SingleExperience from "./Experience/SingleExperience";
import SingleCourse from "./Course/SingleCourse";
import Experience from "./ExperienceHome/Home";
import Course from "./CourseHome/Home";
import Learn from "./LearnHome/Home";
import ShowCourse from "./Dashboard/ShowCourse";
import ShowExperience from "./Dashboard/ShowExperience";
import Dashboard from "./Dashboard/Dashboard";
import ExpCourseHome from "./CourseExpRec/ExpCourseHome";
import ShowExpRecording from "./CourseExpRec/ShowExpRecording";
import ShowCourseRecording from "./CourseExpRec/ShowCourseRecording";
import Chat from "./StreamChat/Chat";
import LoginGoogle from "./Auth/LoginGoogle";
import LoginFacebook from "./Auth/LoginFacebook";
import ErrorPage from "./ErrorPage";
import SingleWebinarPopup from "./Webinar/SingleWebinarPopup";
import Worlds from "./Worlds";
import SingleClub from "./Worlds/SingleClub";
import Activity from "./Worlds/Activity";
import UserFeed from "./Worlds/UserFeed/UserFeed";
import AllMentors from "./Mentor/AllMentors";
import NewMentor from "./Mentor/NewMentor";
import Faq from "./faq/Faq";
import ShowProfile from "./Auth/ShowProfile";
import { getUser } from "../services/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../context/ThemeContext";

const Routes = (props) => {
	const dark = useContext(ThemeContext).isDark;
	const dispatch = useDispatch()
	const { userInfo } = useSelector(state => state.user);
	const uuid = localStorage.getItem("student_uuid");
	const token = localStorage.getItem("student_token");

	const [profileRedirect, setProfileRedirect] = useState(false)

	useEffect(() => {
		dispatch(getUser(uuid));
	}, [token, uuid])

	useEffect(() => {
		if (userInfo) {
			window.Moengage.add_first_name(userInfo?.first_name);
			window.Moengage.add_last_name(userInfo?.last_name);
			window.Moengage.add_email(userInfo?.email);
			window.Moengage.add_mobile("+91" + userInfo?.mobile_number);
			window.Moengage.add_user_name(
				userInfo?.first_name + " " + userInfo?.last_name
			);
		}
		if (userInfo?.first_name === null || userInfo?.dob === null || userInfo?.grade === null) {
			return setProfileRedirect(true)
		}
	}, [userInfo])

	return (
		<>
			<ShowProfile show={profileRedirect} />
			{profileRedirect ? (
				<Switch>
					<Route
						exact
						path="/login"
						render={(props) => (
							<Login access_token={token} student_uuid={uuid} />
						)}
					/>
					<Route
						exact
						path="/mentor/:slug"
						render={(props) => (
							<NewMentor {...props} access_token={token} student_uuid={uuid} />
						)}
					/>
					<Route render={() => <Redirect to="/login" />} />
				</Switch>
			) : profileRedirect ? (
				<Switch>

				</Switch>
			) : (
				<ScrollIntoView>
					<Switch
						location={
							(props.location.state &&
								props.location.state.background) ||
							props.location
						}
					>
						<Route exact path="/auth/google" component={LoginGoogle} />
						<Route exact path="/auth/facebook" component={LoginFacebook} />
						<Route
							exact
							path="/login"
							render={(props) => (
								<Login {...props} access_token={token} student_uuid={uuid} />
							)}
						/>
						<Route
							exact
							path="/logout"
							render={(props) => (
								<Logout access_token={token} student_uuid={uuid} />
							)}
						/>
						<Route
							exact
							path="/mentors"
							render={(props) => (
								<AllMentors
									{...props}
									current_route="mentors"
									access_token={token}
									student_uuid={uuid}
								/>
							)}
						/>
						<Route
							exact
							path="/dashboard"
							render={(props) => (
								<Dashboard
									{...props}
									current_route="dashboard"
									dark={dark}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route exact path="/chats/">
							{token && uuid ? (
								(props) => (
									<Chat
										{...props}
										access_token={token}
										student_uuid={uuid}
										student={userInfo}
									/>
								)
							) : (
								<>
									<Login
										location="/chats"
										access_token={token}
										student_uuid={uuid}
									/>
								</>
							)}
						</Route>

						<Route
							exact
							path="/past-recordings/worlds"
							render={(props) => (
								<ExpCourseHome
									{...props}
									current_route="recording-world"
									dark={dark}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/past-recordings/worlds-courses"
							render={(props) => (
								<ExpCourseHome
									{...props}
									current_route="recording-world-courses"
									dark={dark}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/past-recordings/experience"
							render={(props) => (
								<ExpCourseHome
									{...props}
									current_route="recording-exp"
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/past-recordings/course"
							render={(props) => (
								<ExpCourseHome
									{...props}
									current_route="recording-course"
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>

						<Route
							exact
							path="/experience/schedule/:schedule_id/recording"
							render={(props) => (
								<ShowExpRecording
									{...props}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/course/batch/class/:class_id/recording"
							render={(props) => (
								<ShowCourseRecording
									{...props}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/edit-profile"
							render={(props) => (
								<EditProfile
									{...props}
									dark={dark}
									uuid={uuid}
									token={token}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/room/:webinar_id/attend"
							render={(props) => (
								<ShowWebinar
									{...props}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/room/:webinar_id/recording"
							render={(props) => (
								<WebinarRecording
									{...props}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/room/:webinar_id/qna"
							render={(props) => (
								<WebinarQna
									{...props}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/room/:webinar_id"
							render={(props) => (
								<SingleWebinarPopup
									{...props}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/experience/:time_slot_id/attend"
							render={(props) => (
								<ShowExperience
									{...props}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/course/:class_id/attend"
							render={(props) => (
								<ShowCourse
									{...props}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/experience/:exp_id"
							render={(props) => (
								<SingleExperience
									{...props}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/course/:course_id"
							render={(props) => (
								<SingleCourse
									{...props}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/mentor/:slug"
							render={(props) => (
								<NewMentor
									{...props}
									dark={dark}
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/rooms/"
							render={(props) => (
								<Home
									{...props}
									current_route="Home"
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/experiences/"
							render={(props) => (
								<Experience
									{...props}
									current_route="exp"
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/courses/"
							render={(props) => (
								<Course
									{...props}
									current_route="courses"
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/learn/"
							render={(props) => (
								<Learn
									{...props}
									current_route="learn"
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/faqs/"
							render={(props) => (
								<Faq
									{...props}
									current_route="faqs"
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path="/worlds/"
							render={(props) => (
								<Worlds
									{...props}
									current_route="worlds"
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>
						<Route
							exact
							path={[
								"/worlds/:id/activity",
								"/worlds/:id/activity/",
								"/worlds/:id/activity/calendar",
								"/worlds/:id/activity/library",
								"/worlds/:id/activity/rules",
								"/worlds/:id/activity/courses",
								"/worlds/:id/activity/courses/:course_id",
							]}
						>
							{token && uuid ? (
								(props) => (
									<Activity
										{...props}
										dark={dark}
										access_token={token}
										student_uuid={uuid}
										student={userInfo}
									/>
								)
							) : (
								<Login
									location="/world"
									access_token={token}
									student_uuid={uuid}
								/>
							)}
						</Route>
						<Route
							exact
							path="/worlds/:id"
							render={(props) => (
								<SingleClub
									{...props}
									current_route="worlds"
									access_token={token}
									student_uuid={uuid}
									student={userInfo}
								/>
							)}
						/>

						<Route exact path="/timeline/">
							{token && uuid ? (
								(props) => (
									<UserFeed
										{...props}
										access_token={token}
										student_uuid={uuid}
										student={userInfo}
									/>
								)
							) : (
								<>
									<Login location="/timeline" />
								</>
							)}

						</Route>



						<Route path="/">
							{token && uuid ? (
								(props) => (
									<UserFeed
										{...props}
										access_token={token}
										student_uuid={uuid}
										student={userInfo}
									/>
								)
							) : (
								<>
									<Worlds
										current_route="worlds"
										access_token={token}
										student_uuid={uuid}
										student={userInfo}
									/>
								</>
							)}
						</Route>

						<Route component={ErrorPage} />
					</Switch>
					{props.location.state &&
						props.location.state.background && (
							<Route
								exact
								path="/room/:webinar_id"
								render={(props) => (
									<SingleWebinarPopup
										{...props}
										access_token={token}
										student_uuid={uuid}
										student={userInfo}
									/>
								)}
							/>
						)}
				</ScrollIntoView>
			)}
		</>
	)
}

export default withRouter(Routes);