import React from 'react'
import styles from "./WorldsListItem.module.css";
import { useHistory } from 'react-router-dom';

const MoreWorlds = (props) => {
    const history = useHistory();
    return (
        <div
            className='d-flex align-items-center py-2 px-3 mb-3'
            style={{
                boxShadow: "var(--shadow_primary)",
                borderRadius: "30px",
                cursor: "pointer",
                minHeight: "80px",
            }}
            onClick={() => history.push(`/worlds/${props?.club?.id}`)}
        >
            <div className={styles.worldItemLeft}>
                <div className={styles.worldImageBox}>
                    <img
                        className={styles.worldImage}
                        src={props.club?.image}
                        alt={props.club?.title}
                    />
                </div>
                <div className={styles.worldDetails}>
                    <h6>{props.club?.title}</h6>
                    <p className='text-secondary'>
                        {`${props.club?.uuid?.user?.first_name} ${props.club?.uuid?.user?.last_name}`}
                    </p>
                </div>
            </div>

            <div className={styles.worldItemRight}>
                <img
                    src="/icons/arr.png"
                    alt="HelloWorlds"
                    style={{
                        width: "15px",
                        height: "15px",
                        filter: props.dark ? "invert(0.7)" : "invert(0.3)",
                    }}
                />
            </div>
        </div>
    )
}

export default MoreWorlds