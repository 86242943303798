import Styles from "./Chat.module.css";

const ChatChannelsList = (props) => {
    return (
        <div className={Styles.channels}>
            {
                props?.channels?.map((channel) => (
                    <div
                        className={
                            props?.currentChannel?.channel?.id === channel?.channel?.id ? Styles.activeChannel : Styles.channel
                        }
                        key={channel?.channel?.id}
                        onClick={() => props?.setCurrentChannel(channel)}
                    >
                        <div className={Styles.image}>
                            {
                                channel?.channel?.member_count === 1 ? (
                                    <img
                                        src={
                                            channel?.members[0]?.user?.id === props?.userId ? (
                                                channel?.channel?.created_by?.avatar_url ? channel?.channel?.created_by?.avatar_url : "/student.png"
                                            ) : (
                                                channel?.members[0]?.user?.avatar_url ? channel?.members[0]?.user?.avatar_url : "/student.png"
                                            )
                                        }
                                        alt={channel?.channel?.created_by?.name}
                                    />
                                ) : (
                                    <img
                                        src="/student.png"
                                        alt="group"
                                    />
                                )
                            }
                        </div>
                        <div className={Styles.name}>
                            {
                                channel?.channel?.member_count === 1 ? (
                                    channel?.members[0]?.user?.id === props?.userId ? (
                                        channel?.channel?.created_by?.name
                                    ) : (
                                        channel?.members[0]?.user?.name
                                    )
                                ) : channel?.channel?.channel_name
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ChatChannelsList