import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import LazyLoad from "../Commons/LazyLoad";
import { dateConverter, capitalizeName, getGradeText } from "../utils.js";

class Snippet extends Component {
	static contextType = ThemeContext;
	render() {
		const light = !this.context.isDark;
		let snippet = this.props.snippet;
		// let duration = Math.round((snippet.end_time - snippet.start_time) / 60);
		let featured_image = "/default_webinar_img.png";
		// if (snippet.featured_image !== null) {
		//     featured_image = snippet.mentor.avatar_url;
		// }
		// let city = "";
		// if (this.props.snippet.mentor.city !== null) {
		//     city = this.props.snippet.mentor.city.name;
		// }
		let snippet_url = "/room/" + snippet.id;
		//let attend_url = "/webinar/" + snippet.id + "/attend";
		//let watch_url = "/webinar/" + snippet.id + "/recording";

		let full_date = dateConverter(snippet.start_time);

		let grade_text = getGradeText(snippet.grades);

		return (
			<div className="experience-snippet">
				<div
					className="experience-snippet-inner"
					style={light ? { backgroundColor: "white" } : {}}
				>
					<div className="snippet-image">
						<Link
							to={{
								pathname: snippet_url,
								state: { background: this.props.location },
							}}
						>
							<LazyLoad
								src={
									snippet.mentor.avatar_url
										? snippet.mentor.avatar_url
										: featured_image
								}
								alt={snippet.title}
								classes="snippet-img-snippets"
							/>
						</Link>
					</div>
					<div className="snippet-content snippet-content-webinar">
						<div className="snippet-content-hero">
							<div className="snippet-content-head">
								<div className="snippet-event-type">
									{snippet.room_type
										? String(snippet.room_type).toUpperCase()
										: "WORKSHOP"}
								</div>
								<div>
									<em>{grade_text}</em>
								</div>
								{/* <div className="snippet-interested">
                                    {snippet.registration
                                        .slice(0, 3)
                                        .map((item) => (
                                            <>
                                                <img
                                                    className="snippet-interested-img"
                                                    src={
                                                        item.avatar_url
                                                            ? item.avatar_url
                                                            : featured_image
                                                    }
                                                    alt="interested ppl"
                                                />
                                            </>
                                        ))}
                                    {snippet.registration.length > 3 ? (
                                        <span style={{ marginLeft: "5px" }}>
                                            {`+${
                                                snippet.registration.length - 3
                                            }
                                            others interested`}
                                        </span>
                                    ) : null}
                                </div> */}
							</div>
							<div className="snippet-content-main">
								<div>
									<Link
										to={{
											pathname: snippet_url,
											state: { background: this.props.location },
										}}
									>
										<h5 className="">{snippet.title}</h5>
									</Link>
									<div className="snippet-content-main_sub-details">
										<p>
											By{" "}
											{capitalizeName(
												`${snippet.mentor.first_name} ${
													snippet.mentor.last_name || ""
												}`
											)}
										</p>
										<div>
											<p className="duration-in-snippet">{full_date}</p>
										</div>
									</div>
								</div>
								<Link
									to={{
										pathname: snippet_url,
										state: { background: this.props.location },
									}}
								>
									<button className="snippet-attend-btn dark-border">
										View
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Snippet;
