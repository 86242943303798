// this component uses the RequestMentor css, because of the same nature of both the components

import css from "./RequestMentor.module.css";
import { Modal } from "react-bootstrap";
import { useContext, useState, StrictMode } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import LoginPopup from "../Auth/LoginPopup";
import ReactDOM from "react-dom";
import swal from "sweetalert";

const NotifyMe = ({ type, id, token, update_token }) => {
	const light = useContext(ThemeContext).isDark;
	const [open, setOpen] = useState(false);
	const [success, setSucces] = useState(null);

	const openLoginPopup = () => {
		let swal_popup = null;

		swal_popup = document.createElement("div");
		ReactDOM.render(
			<StrictMode>
				<LoginPopup update_token={update_token} from="mindpod" />
			</StrictMode>,
			swal_popup
		);
		swal({
			content: swal_popup,
			buttons: false,
			className: "swal-login",
			closeOnClickOutside: false,
		});
	};

	const closeModal = () => {
		setOpen(false);
	};

	const handleSubmit = () => {
		if (!!token) {
			const url = `${process.env.REACT_APP_BASE_URL}/rest/students/${type}/${id}/notify`;

			let myHeaders = {
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			};

			let requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: {},
				redirect: "follow",
			};

			fetch(url, requestOptions)
				.then((response) => response.json())
				.then((result) => {
					setOpen(true);
					setSucces(result.success);
				})
				.catch((error) => {});
			return;
		}

		openLoginPopup();
	};

	return (
		<>
			<div
				className="request-class-box"
				style={light ? { backgroundColor: "transparent" } : {}}
			>
				<button
					onClick={handleSubmit}
					style={{
						color: "inherit",
						background: "none",
					}}
					className="request-class-btn border-2 dark-border"
				>
					Notify Me
				</button>
			</div>
			<Modal
				show={open}
				centered
				onHide={closeModal}
				contentClassName={css.content}
			>
				<div className={css.title}>
					<span> {(success && "Thank you!") || "Oops.."} </span>
					<button
						style={{
							background: "none",
						}}
						onClick={closeModal}
					>
						<i className="fa fa-times" aria-hidden="true"></i>
					</button>
				</div>
				{success ? (
					<p>
						Thank you for your interest. We will notify you when new schedules
						for this class are added by the Mentor.
					</p>
				) : (
					<p>You've already raised a notification request for this class.</p>
				)}
				<button
					className={`border-2 dark-border font-lg ${css.action}`}
					style={{
						background: "none",
						borderRadius: "10px",
					}}
					onClick={closeModal}
				>
					Okay
				</button>
			</Modal>
		</>
	);
};

export default NotifyMe;
