import { Col, Container, Row } from "react-bootstrap";

const Banner = () => {
	return (
		window.innerWidth > 768 ? (
			<Container className="py-3 video-bg-content">
				<img
					src="/images/2.webp"
					alt="banner"
					style={{ width: "100%", borderRadius: "20px" }}
				/>
			</Container>
		) : (
			<div className="video-bg-content">
				<img
					src="/images/2-mobile.webp"
					alt="banner"
					style={{ width: "100%" }}
				/>
			</div>
		)
	);
};

export default Banner;
