import React from 'react'
import { Link } from 'react-router-dom'

const MobileBottonNav = (props) => {
    return (
        <div className="mobile-bottom-nav">
            <div className="mobile-bottom-nav-container">
                <div className="mobile-bottom-nav-item">
                    <Link to="/timeline" >
                        <img src="/icons/home2.png" alt="timeline" />
                        <p
                            style={
                                props.current_route === "timeline" ? (
                                    {
                                        color: "var(--border)"
                                    }
                                ) : {}
                            }
                        >
                            Home</p>
                    </Link>
                </div>
                
                    <div className="mobile-bottom-nav-item">
                        <Link to="/dashboard">
                            <img src="/icons/dashboard2.png" alt="dashboard" />
                            <p
                                style={
                                    props.current_route === "dashboard" ? (
                                        {
                                            color: "var(--border)"
                                        }
                                    ) : {}
                                }
                            >
                                Dashboard</p>
                        </Link>
                    </div>
               

                <div className="mobile-bottom-nav-item">
                    <Link to="/worlds">
                        <img src="/icons/global.png" alt="worlds" />
                        <p
                            style={
                                props.current_route === "worlds" ? (
                                    {
                                        color: "var(--border)"
                                    }
                                ) : {}
                            }
                        >
                            Worlds</p>
                    </Link>
                </div>

              
                    <div className="mobile-bottom-nav-item">
                        <Link to="/chats">
                            <img src="/icons/mail.png" alt="inbox" />
                            <p
                                style={
                                    props.current_route === "inbox" ? (
                                        {
                                            color: "var(--border)"
                                        }
                                    ) : {}
                                }
                            >
                                Inbox</p>
                        </Link>
                    </div>
                

                {/* <div className="mobile-bottom-nav-item">
                    <Link to="/learn">
                        <img src="/icons/plant.png" alt="mind pod" />
                        <p
                            style={
                                props.current_route === "learn" ? (
                                    {
                                        color: "var(--border)"
                                    }
                                ) : {}
                            }
                        >
                            Mind Pod</p>
                    </Link>
                </div> */}

                {/* <div className="mobile-bottom-nav-item">
                    <img src="/icons/flask.png" alt="sound pod" />
                    <Link to="/rooms">Sound Pod</Link>
                </div> */}
            </div>

        </div>
    )
}

export default MobileBottonNav