import React, { useRef } from "react";
import CourseClasses from "./CourseClasses";
import CourseContent from "./CourseContent";
import ThingsToDo from "./ThingsToDo";
import Title from "./Title";
import styles from "./SingleCourseDetails.module.css";

const SingleCourseDetails = (props) => {
    const detailsRef = useRef(null);
    const requirementsRef = useRef(null);
    const mentorRef = useRef(null);

    const executeScroll = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <>
            <div className={styles.courseContent}>
                <ul>
                    <li
                        onClick={() => {
                            executeScroll(detailsRef);
                        }}
                    >
                        Details
                    </li>
                    <li
                        onClick={() => {
                            executeScroll(requirementsRef);
                        }}
                    >
                        Requirements
                    </li>
                    <li
                        onClick={() => {
                            executeScroll(mentorRef);
                        }}
                    >
                        Mentor
                    </li>
                </ul>
            </div>
            <div ref={detailsRef}>
                <h3>Details</h3>
                <CourseContent content={props.description} />
                <h3>What you'll learn</h3>
                <CourseContent content={props.outcome} />
                <h3>Course Content</h3>
                <CourseClasses classes={props.classes} />
            </div>

            <div ref={requirementsRef}>
                {props.skill && (
                    <div>
                        <h3>Requirements</h3>
                        <CourseContent content={props.skill} />
                    </div>
                )}
                <h3>Things to note</h3>
                <ThingsToDo notes={props.notes} />
            </div>

            <div ref={mentorRef}>
                <h3>Mentor</h3>
                <Title
                    title={props.mentor_title}
                    img={props.mentor_avatar}
                    url={props.url}
                />
                <CourseContent content={props.mentor} />
            </div>
        </>
    );
};

export default SingleCourseDetails;
