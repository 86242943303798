import React from 'react'
import { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import LazyLoad from '../../Commons/LazyLoad';
import ReadMoreReact from "read-more-react";

const MentorModal = (props) => {
    const dark = useContext(ThemeContext).isDark;
    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header className="text-warning border-0" closeButton
                style={
                    dark ? {
                        filter: "invert(1)",
                        borderRadius: "30px",
                    } : {}
                }
            >
                <Modal.Title id="contained-modal-title-vcenter"
                    style={{ color: "red" }}
                >
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                className="d-flex flex-column "
                style={{
                    borderRadius: "30px",
                }}
            >
            <div className="px-4 gopher-font">
                <div>
                    <div className="p-0">
                        <h4 className="gopher-font mb-4">
                            World Run By :
                        </h4>
                        <div className="mentor-modal-content">
                            <div className="mentor-modal-content-top">
                                <div className="mentor-modal-content-image">
                                    <LazyLoad
                                        src={props?.mentorData?.avatar_url}
                                        alt={props?.mentorData?.first_name}
                                        classes="mentor-image"
                                    />
                                </div>
                                <div className="mentor-modal-content-other">
                                    <div className="gopher-font">
                                        <Button
                                            className="text-white"
                                            style={{ cursor: "pointer", fontSize: "0.8rem", border: '0' }}
                                            onClick={() => {
                                                props.history.push(`/mentor/${props?.mentorData?.slug}`)
                                            }}
                                        >
                                            View Full Profile
                                        </Button>
                                        {
                                            window.innerWidth > 600 && (
                                                <p
                                                    className="btn btn-outline-primary m-0 ms-3 text-uppercase"
                                                    style={{ cursor: "pointer", fontSize: "0.8rem" }}
                                                    onClick={props.onHide}
                                                >
                                                    Close
                                                </p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mentor-modal-content-bottom">
                                <h4>
                                    {props?.mentorData?.first_name} {props?.mentorData?.last_name}
                                </h4>
                                <div className="row mentor-about p-0 pb-5">
                                    {typeof props?.mentorData?.about_me !== "undefined" &&
                                        props?.mentorData?.about_me.length > 0 ? (
                                        <ReadMoreReact
                                            text={props?.mentorData?.about_me}
                                            max={100000}
                                            min={150}
                                            ideal={280}
                                            readMoreText={
                                                "Read More"
                                            }
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </Modal.Body>
        </Modal >
    )
}

export default MentorModal