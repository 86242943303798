import React, { useEffect } from "react";
import { Redirect } from "react-router";
import Header from "../Header";
import UpcomingExperienceCourse from "./UpcomingExperienceCourse";
import Mentors from "./Mentors";
import Footer from "../Footer";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function capitalizeFirstLetter(string) {
	if (!string) return "";
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const Dashboard = (props) => {
	const dark = useContext(ThemeContext).isDark;
	const token = localStorage.getItem("student_token");
	const { userInfo } = useSelector(state => state.user);

	useEffect(() => {
		document.title = "Dashboard | HelloWorlds";
	}, [])

	if (token === null) {
		return <Redirect to="/login" />
	}

	return (
		<div>
			<Header
				current_route={props.current_route}
				avatar_url={userInfo?.avatar_url || "./student.png"}
				access_token={token}
			/>

			<div className="dashboard-body row justify-content-center ">
				<div className="student-welcome-dashboard" style={{ marginBottom: "30px" }}>
					<h2>
						👋 Hello,{" "}
						{userInfo?.first_name
							? capitalizeFirstLetter(userInfo?.first_name)
							: userInfo?.first_name}
						!
					</h2>
					<p className="ps-3">Be awesome everyday🔥</p>
				</div>
				<div className="row no-mob-padding">
					<UpcomingExperienceCourse
						key={userInfo?.student_id}
						student_id={userInfo?.id}
						access_token={token}
						student_uuid={userInfo?.uuid?.uuid}
						dark={dark}
					/>
					<Mentors
						access_token={token}
						student_uuid={props.student_uuid}
						student_id={props.student_id}
						student={props.userInfo}
						history={props.history}
						dark={dark}
					/>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Dashboard;
