import React from "react";
import { Form, FloatingLabel } from "react-bootstrap";

const AppFloatingInput = (props) => {
  const { label, selectOptions } = props;
  const formSelectProps = { ...props, selectOptions: null };
  return (
    <FloatingLabel label={label} color="black">
      {selectOptions ? (
        <Form.Select {...formSelectProps} style={{boxShadow:"var(--shadow_primary)"}}>
          {selectOptions.map((item, index) => (
            <option value={item.value} key={index}>
              {item.label}
            </option>
          ))}
        </Form.Select>
      ) : (
        <Form.Control {...props} style={{boxShadow:"var(--shadow_primary)"}}/>
      )}
    </FloatingLabel>
  );
};

export default AppFloatingInput;
