import React, { useEffect, useState } from 'react';
import moment from 'moment';

const FreeTrialTimer = ({ subscriptionDate, freeTrialDays }) => {
    const [remainingTime, setRemainingTime] = useState(null);

    useEffect(() => {
        const freeTrialEndDate = moment(subscriptionDate).add(freeTrialDays, 'days');
        const today = moment();
        const diff = freeTrialEndDate.diff(today, 'days');

        if (diff < 1) {
            // Calculate countdown timer if less than 1 day remaining
            const timer = setInterval(() => {
                const remaining = freeTrialEndDate.diff(moment(), 'seconds');
                setRemainingTime(remaining);

                if (remaining <= 0) {
                    // Timer expired, handle it as needed
                    clearInterval(timer);
                }
            }, 1000);

            return () => {
                // Clean up timer on component unmount
                clearInterval(timer);
            };
        }

        // No countdown timer needed, set remaining days
        setRemainingTime(diff);

        // Clean up remaining time on component unmount
        return () => {
            setRemainingTime(null);
        };
    }, [subscriptionDate, freeTrialDays]);

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div>
            {remainingTime === null ? (
                <p className="text-white">Loading...</p>
            ) : remainingTime < 1 ? (
                <p className="text-white">Free trial ends in {formatTime(remainingTime)}</p>
            ) : (
                <p className="text-white">Free trial ends in {remainingTime} days</p>
            )}
        </div>
    );
};

export default FreeTrialTimer;
