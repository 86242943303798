import React, { Component } from "react";
import Title from "./Title";
import Snippet from "./Snippet";
import { Redirect } from "react-router";
import LoadingSkeleton from "./LoadingSkeleton";
import LazyLoad from "../Commons/LazyLoad";
// import swal from "sweetalert";

class PastWebinars extends Component {
	constructor(props) {
		super(props);
		this.state = {
			snippets: [],
			redirect: false, //redirect to login in case of unauthenticated
			loading: true,
		};
	}

	componentDidMount() {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(
			`${process.env.REACT_APP_BASE_URL}/rest/students/webinars?type=past&recording_status=1`,
			requestOptions
		)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then(
				(result) => {
					this.setState({
						snippets: result["data"]["results"],
						loading: false,
					});
				},
				(error) => {
					localStorage.removeItem("student_token");
					localStorage.removeItem("student_uuid");
					this.setState({
						redirect: true,
						loading: false,
					});
				}
			);
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/login" />;
		}

		return (
			<div className="upcoming-webinars home-container">
				<Title title="Past Rooms" />
				{this.state.loading && <LoadingSkeleton />}
				{!this.state.loading &&
					(this.state.snippets.length ? (
						this.state.snippets.map((snippet, index) => (
							<Snippet
								location={this.props.location}
								type="past"
								snippet={snippet}
								key={index}
							/>
						))
					) : (
						<div
							className="no-upcoming-webinar"
							style={{
								justifyContent: "flex-start",
								marginLeft: "3vw",
								marginBottom: "1rem",
							}}
						>
							<LazyLoad
								src="/icons/calendar.png"
								alt="calendar-dashboard"
								classes="calendar-dashboard"
							/>
							<p className="no-bookings-yet">Uh oh. No past rooms found :(</p>
						</div>
					))}
			</div>
		);
	}
}

export default PastWebinars;
