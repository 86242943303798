import React from 'react'
import styles from "./MentorListItem.module.css";
import { useHistory } from 'react-router-dom';

const MentorsListItem = (props) => {
    const history = useHistory()
    return (
        <div
            className='d-flex py-2 px-3 mb-3'
            style={{
                boxShadow: "var(--shadow_primary)",
                borderRadius: "30px",
                cursor: "pointer",
                minHeight: "90px",
            }}
            onClick={() => history.push(`/mentor/${props.mentor.slug}`)}
        >
            <div className={styles.mentorItemLeft}>
                <div className={styles.mentorImageBox}>
                    <img
                        className={styles.mentorImage}
                        src={!props.mentor.avatar_url ? "./images/teacher.png" : props.mentor.avatar_url}
                        alt=""
                    />
                </div>
                <div className={styles.mentorDetails}>
                    <h6>
                        {
                            props.mentor?.first_name + " " + props.mentor?.last_name
                        }
                    </h6>
                    <p className='text-secondary'>
                        {
                            props.mentor?.one_liner?.length > 35 ? props.mentor?.one_liner?.slice(0, 35) + "..." : props.mentor?.one_liner
                        }
                    </p>
                </div>
            </div>

            <div className={styles.mentorItemRight}>
                <img
                    src="/icons/arr.png"
                    alt="HelloWorlds"
                    style={{
                        width: "15px",
                        height: "15px",
                        filter: props.dark ? "invert(0.7)" : "invert(0.3)",
                    }}
                />
            </div>
        </div>
    )
}

export default MentorsListItem