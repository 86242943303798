import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { Form } from 'react-bootstrap';


const Search = ({ options, selected, setSelected, search }) => {
    return (
        <Typeahead
            id="basic-example"
            onChange={(item) => {
                setSelected(item)
                search(item)
            }}
            options={options}
            placeholder="Type to search..."
            selected={selected}
            multiple
            labelKey="name"
            highlightOnlyResult
            className='search-bar'
        />
    )
}

export default Search