import { createContext, useState } from "react";

export const LoginSuggestionContext = createContext({});

export const LoginSuggestionProvider = (props) => {
    const [showGoogleLoginSuggestion, setShowGoogleLoginSuggestion] = useState(true);

    return (
        <LoginSuggestionContext.Provider
            value={{
                showGoogleLoginSuggestion,
                setShowGoogleLoginSuggestion,
            }}
        >
            {props.children}
        </LoginSuggestionContext.Provider>
    );
}
