import React, { Component } from "react";

import Title from "./Title";
import ExperienceContent from "./ExperienceContent";

class SectionContent extends Component {
    render() {
        return (
            <div>
                {this.props.content === null ? null : (
                    <div>
                        {this.props.title && (
                            <Title
                                title={this.props.title}
                                img={this.props.img}
                                url={this.props.url}
                            />
                        )}
                        <ExperienceContent content={this.props.content} />
                    </div>
                )}
            </div>
        );
    }
}

export default SectionContent;
