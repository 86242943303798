import { createContext, useState, useEffect } from "react";
import { applyTheme } from "../theme/theme";

export const ThemeContext = createContext({
	isDark: true,
	toggleTheme: () => {},
});

export const ThemeProvider = (props) => {
	const storedColor =
		(localStorage.getItem("edvolveAppThemeIsDark") || "true") === "true"
			? true
			: false;
	const [isDark, setIsDark] = useState(storedColor);

	const changeTheme = (theme) => {
		setIsDark(theme);
		applyTheme(theme);
	};

	useEffect(() => {
		localStorage.setItem("edvolveAppThemeIsDark", isDark);
		changeTheme(isDark);
	}, [isDark]);

	return (
		<ThemeContext.Provider
			value={{
				isDark,
				toggleTheme: () => setIsDark(!isDark),
			}}
		>
			{props.children}
		</ThemeContext.Provider>
	);
};
