

const Rules = (props) => {
	return (
		<div>
			<h2 className="d-none d-md-block gopher-font mb-4">Rules/Guidelines</h2>
			<div
				className="gopher-font"
				style={{
					color: "f8f8f8",
					maxWidth: "600px",
					boxShadow: "var(--shadow_primary)",
					padding: "30px",
					borderRadius: "30px",
					fontSize:"17px",
					background:"var(--background_primary)"
				}}
				dangerouslySetInnerHTML={{ __html: props.worlds?.guidelines }}
			/>
		</div>
	);
};

export default Rules;
