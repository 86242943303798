import React, { Component } from "react";
import { Link } from "react-router-dom";
import { RWebShare } from "react-web-share";

import Header from "../Header";
import SubHeader from "../SubHeader";
import Title from "./Title";
// import CourseContent from "./CourseContent";
// import CourseClasses from "./CourseClasses";
// import ThingsToDo from "./ThingsToDo";
import SingleCourseInfo from "./SingleCourseInfo";
import CourseDateSelection from "./CourseDateSelection";
import Recommendations from "../Commons/Recommendations";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";

import ReactDOM from "react-dom";
import swal from "sweetalert";

import Footer from "../Footer";
// eslint-disable-next-line no-unused-vars
import { AdvantagesBox, stripHtml } from "../Experience/SingleExperience";
import { ThemeContext } from "../../context/ThemeContext";

import {
	extractUrlParam,
	onlyTime,
	onlyDate,
	scheduleStartTime,
	scheduleEndTime,
	capitalizeName,
} from "../utils";
import RequestMentor from "../Commons/RequestMentor";
import { joinGroup } from "../Chat/utils";
import NotifyMe from "../Commons/NotifyMe";
import LazyLoad from "../Commons/LazyLoad";
//var Moengage = require('../utils.js').Moengage;
import SingleCourseDetails from "./SingleCourseDetails";
import styles from './SingleCourseDetails.module.css';

class SingleCourse extends Component {
	static contextType = ThemeContext;

	constructor(props) {
		super(props);
		var payment_request_id = extractUrlParam();
		var payment_id = null;
		let show_confirmation_popup = 0;
		if (payment_request_id) {
			show_confirmation_popup = 1;
			payment_id = payment_request_id;
		}

		this.state = {
			title: "",
			description: "",
			duration: "",
			primary_subject: "",
			secondary_subject: "",
			language: "English",
			grades: [],
			notes: [],
			fee: -1,
			original_fee: 0,
			group_size: 0,
			featured_image: null,
			mentor: {
				id: 0,
				first_name: "",
				last_name: "",
				avatar_url: null,
				about_me: "",
				slug: "",
			},
			payment_id: payment_id,
			show_confirmation_popup: show_confirmation_popup,
			imgs: [],
			classes: [],
			skill: "",
			show_time_box: 0, //used in time box component
			opacity: 1,
			transition: "opacity 1s",
			coupons_list: [],
			mentor_name: "",
			showScrollBtn: false
		};

		window.onscroll = () => {
			window.scrollY > 300 ? this.setState({
				showScrollBtn: true
			}) : this.setState({
				showScrollBtn: false
			})
		};
	}

	componentDidUpdate() {
		document.title = `${this.state.mentor_name} - ${this.state.title} | HelloWorlds`;
		if (this.props.access_token === null) {
			localStorage.setItem("redirect", window.location.pathname);
		}
	}

	componentDidMount() {
		this.getOffers();

		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/courses/${this.props.match.params.course_id}`;

		fetch(url, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					this.setState(
						{
							title: result.data.title,
							description: result.data.description,
							outcome: result.data.outcome,
							duration: result.data.duration,
							primary_subject: result.data.primary_subject.name,
							language: result.data.language.language,
							fee: result.data.fee,
							original_fee: result.data.original_fee,
							group_size: result.data.group_size,
							notes: result.data.notes,
							featured_image: result.data.featured_image,
							imgs: result.data.imgs,
							grades: result.data.grades,
							classes: result.data.classes,
							skill: result.data.skill_level,
							mentor: {
								id: result.data.mentor.uuid.uuid,
								first_name: result.data.mentor.first_name,
								last_name: result.data.mentor.last_name,
								avatar_url: result.data.mentor.avatar_url,
								about_me: result.data.mentor.about_me,
								slug: result.data.mentor.slug,
							},
						},
						() => {
							this.setState({
								mentor_name: capitalizeName(
									`${this.state.mentor.first_name} ${this.state.mentor.last_name || ""
									}`
								),
							});
						}
					);

					if (result.data.secondary_subject !== null) {
						this.setState({
							secondary_subject: result.data.secondary_subject.name,
						});
					}

					if (this.state.show_confirmation_popup === 1) {
						this.confPopup();
					}
				},
				(error) => { }
			);

		window.addEventListener("scroll", this.listenScrollEvent);
	}

	getOffers() {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/coupons`;

		fetch(url, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					// console.log("result", result["data"]["results"]);
					this.setState({
						coupons_list: result.data.results,
					});
				},
				(error) => { }
			);
	}

	listenScrollEvent = (e) => {
		let currentScrollPos = window.pageYOffset;
		let maxScroll = document.body.scrollHeight - window.innerHeight;

		if (currentScrollPos < maxScroll) {
			this.setState({ opacity: 1 });
		} else {
			this.setState({ opacity: 0 });
		}
	};

	confPopup() {
		var swal_popup = document.createElement("div");
		ReactDOM.render(
			<React.StrictMode>
				<ConfPopup
					access_token={this.props.access_token}
					student={this.props.student}
					payment_id={this.state.payment_id}
					mentor_name={this.state.mentor_name}
					course_id={this.props.match.params.course_id}
				/>
			</React.StrictMode>,
			swal_popup
		);
		//swal_welcome_content.innerHTML = <QnaPopup />;
		swal({
			content: swal_popup,
			buttons: false,
			className: "swal-payment-conf",
		});
	}

	// Show scroll to top button


	render() {
		const light = !this.context.isDark;

		/*if (this.props.access_token===null) {
		   return <Redirect to='/login'/>;
		}*/
		let mentor_title = `Meet your mentor, ${this.state.mentor_name}`;
		let featured_image = "/default_webinar_img.png";
		if (this.state.featured_image !== null) {
			featured_image = this.state.featured_image;
		}

		let current_epoch = Math.floor(Date.now() / 1000);

		let past = 0;
		if (current_epoch > this.state.end_time) {
			past = 1; //the webinar is past
		}

		let images = [{ url: this.state.featured_image }];
		if (this.state.imgs.length > 0) {
			this.state.imgs.forEach(function (img) {
				images.push({ url: img.image_path });
			});
		}

		let mentor_url = "/mentor/" + this.state.mentor.slug;
		//<img src={featured_image} alt={this.state.title} className="snippet-img" />

		return (
			<div>
				<div className="d-md-block d-none">
					<Header
						update_token={this.props.update_token}
						avatar_url={this.props.avatar_url}
						access_token={this.props.access_token}
					/>
				</div>
				<div className="row full-detail-content mx-0">
					{/* for large screens */}
					<div className="row ps-4 d-md-flex d-none">
						<SubHeader back="Mind Pod" backUrl="/learn" />
						<p className="class-type-text">
							<i className="fa fa-graduation-cap" aria-hidden="true"></i> Course
						</p>
						<h1 className="col-12">{this.state.title}</h1>
						<div className="col-9 no-padding">
							<h2 className="by-new-design">
								<Link to={mentor_url}>
									<LazyLoad
										src={this.state.mentor.avatar_url}
										alt="User Avatar"
										classes="mentor-icon-detail-page"
									/>
								</Link>
								<p>
									By <Link to={mentor_url}>{this.state.mentor_name}</Link>
								</p>
							</h2>
						</div>
						<div className="col-3 no-padding">
							<RWebShare
								data={{
									text:
										"Hey, Checkout this course on HelloWorlds: " + this.state.title,
									url:
										"https://app.helloworlds.co/course/" +
										this.props.match.params.course_id,
									title: this.state.title,
								}}
								onClick={() => console.log("shared successfully!")}
							>
								<div className="share-div rounded-pill">
									<LazyLoad
										src="/icons/share.png"
										alt="Share Icon"
										classes="share-icon"
									/>
									<span>Share</span>
								</div>
							</RWebShare>
						</div>
					</div>
					<div className="col-12 col-md-9 webinar-container">
						<div className="row webinar-basic-info-container">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 p-0 px-md-2">
								<div className="snippet-image-single-page snippet-image-mobile-webinar">
									<Carousel
										additionalTransfrom={0}
										arrows
										autoPlaySpeed={3000}
										centerMode={false}
										className=""
										containerClass="container-exp-page"
										dotListClass="carousel-dots"
										draggable
										focusOnSelect={false}
										infinite={false}
										itemClass=""
										keyBoardControl
										minimumTouchDrag={80}
										renderButtonGroupOutside={false}
										renderDotsOutside={false}
										responsive={{
											desktop: {
												breakpoint: {
													max: 3000,
													min: 1024,
												},
												items: 2,
												partialVisibilityGutter: 40,
											},
											mobile: {
												breakpoint: {
													max: 464,
													min: 0,
												},
												items: 1,
												partialVisibilityGutter: 30,
											},
											tablet: {
												breakpoint: {
													max: 1024,
													min: 464,
												},
												items: 1,
												partialVisibilityGutter: 30,
											},
										}}
										showDots={true}
										sliderClass=""
										slidesToSlide={1}
										swipeable
									>
										{images.map((image) => (
											<LazyLoad
												src={image.url}
												alt=""
												classes="snippet-img-exp-page"
												key={image.url}
											/>
										))}
									</Carousel>
								</div>
							</div>
						</div>
						{/* for small screens */}
						<div className="row d-md-none d-flex mt-4 px-2">
							<SubHeader back="Mind Pod" backUrl="/learn" />
							<p className="class-type-text mt-2">
								<i className="fa fa-graduation-cap" aria-hidden="true"></i>{" "}
								Course
							</p>
							<h1 className="col-12 my-3 mt-0">{this.state.title}</h1>
							<div className="col-9 px-1 mb-3">
								<h2 className="by-new-design m-0">
									<Link to={mentor_url}>
										<LazyLoad
											src={this.state.mentor.avatar_url}
											alt="User Avatar"
											classes="mentor-icon-detail-page"
										/>
									</Link>{" "}
									By <Link to={mentor_url}>{this.state.mentor_name}</Link>
								</h2>
							</div>
							<div className="col-3 no-padding mb-3">
								<RWebShare
									data={{
										text:
											"Hey, Checkout this course on HelloWorlds: " +
											this.state.title,
										url:
											"https://app.helloworlds.co/course/" +
											this.props.match.params.course_id,
										title: this.state.title,
									}}
									onClick={() => console.log("shared successfully!")}
								>
									<div className="share-div rounded-pill">
										<LazyLoad
											src="/icons/share.png"
											alt="Share Icon"
											classes="share-icon"
										/>
										<span>Share</span>
									</div>
								</RWebShare>
							</div>
						</div>
						<div className="row webinar-full-content">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
								<SingleCourseInfo
									past={past}
									webinar_id={this.props.match.params.webinar_id}
									group_size={this.state.group_size}
									title={this.state.title}
									duration={this.state.duration}
									primary_subject={this.state.primary_subject}
									secondary_subject={this.state.secondary_subject}
									mentor={this.state.mentor}
									language={this.state.language}
									grades={this.state.grades}
									classes={this.state.classes}
								/>

								{/* <Tabs defaultActiveKey="Details" className="col-12">
									<Tab
										eventKey="Details"
										title="Details"
										tabClassName="experience-tab-title"
									>
										<CourseContent content={this.state.description} />
										<Title
											title="What you'll learn"
											img="no-img"
											url="no-url"
										/>
										<CourseContent content={this.state.outcome} />
										<Title title="Course Content" img="no-img" url="no-url" />
										<CourseClasses classes={this.state.classes} />
									</Tab>
									<Tab
										eventKey="Requirements"
										title="Requirements"
										tabClassName="experience-tab-title"
									>
										{this.state.skill && (
											<div>
												<Title title="Requirements" img="no-img" url="no-url" />
												<CourseContent content={this.state.skill} />
											</div>
										)}
										<Title title="Things to note" img="no-img" url="no-url" />
										<ThingsToDo notes={this.state.notes} />
									</Tab>
									<Tab
										eventKey="Mentor"
										title="Mentor"
										tabClassName="experience-tab-title"
									>
										<Title
											title={mentor_title}
											img={this.state.mentor.avatar_url}
											url={mentor_url}
										/>
										<CourseContent content={this.state.mentor.about_me} />
									</Tab>
								</Tabs> */}

								<SingleCourseDetails
									description={this.state.description}
									outcome={this.state.outcome}
									classes={this.state.classes}
									notes={this.state.notes}
									skill={this.state.skill}
									mentor={this.state.mentor.about_me}
									url={mentor_url}
									mentor_title={mentor_title}
									mentor_avatar={this.state.mentor.avatar_url}
								/>

								<div
									className="no-padding coupon-code-box d-md-none d-block py-0 border-0"
									style={{
										borderBottom: "1px solid var(--fontColor-secondary)",
										backgroundColor: light
											? "var(--background_primary)"
											: "var(--background_secondary)",
									}}
								>
									<div style={{ paddingLeft: "0.5rem" }}>
										<Title title="HelloWorlds Offers" img="no-img" />
									</div>
									<div className="coupon-code-box-height my-3">
										{this.state.coupons_list.length ? (
											this.state.coupons_list.map((coupons) => (
												<div className="single-coupon-wrapper" key={coupons.id}>
													<div className="time-schedule-exp col-sm-12 col-lg-12 col-md-12 col-xs-12">
														<p>
															{coupons.name}{" "}
															<OverlayTrigger
																placement="right"
																overlay={<Tooltip>Click to copy code</Tooltip>}
															>
																<i
																	className="fa fa-clone ms-2"
																	aria-hidden="true"
																	onClick={() => {
																		navigator.clipboard.writeText(coupons.name);
																	}}
																	style={{
																		cursor: "pointer",
																	}}
																></i>
															</OverlayTrigger>
														</p>
													</div>
													<div className="coupon-description">
														<p>{coupons.description}</p>
													</div>
												</div>
											))
										) : (
											<div className="no-upcoming-webinar">
												Uh oh. No coupons available :(
											</div>
										)}
									</div>
								</div>
								<div
									style={{
										borderBottom: " 1px solid #c9c9c9",
									}}
									className="pb-2"
								>
									<Title title="Advantages of HelloWorlds " img="no-img" />
									<AdvantagesBox />
								</div>
								<Title title="Keep In Mind" img="no-img" />
								<div className="thing-to-do">
									<LazyLoad
										src="/icons/tick.png"
										alt="tick icon"
										style={{
											width: "25px",
											height: "25px",
										}}
									/>
									<span>
										<span className="bold-item">Have Zoom Installed:</span> Use
										Zoom App to participate on your desktop or mobile device.
										After you book, the link to join is presented on your
										Dashboard, which opens Zoom.
									</span>
								</div>
								<div className="thing-to-do">
									<LazyLoad
										src="/icons/tick.png"
										alt="tick icon"
										style={{
											width: "25px",
											height: "25px",
										}}
									/>
									<span>
										<span className="bold-item">Cancellation Policy:</span> You
										can get a full refund if you cancel by 11:59 PM 2 days
										before the day of experience/course. No refund after that.
										Send an email to hello@helloworlds.co to cancel.
									</span>
								</div>
								<div className="thing-to-do">
									<LazyLoad
										src="/icons/tick.png"
										alt="tick icon"
										style={{
											width: "25px",
											height: "25px",
										}}
									/>
									<span>
										<span className="bold-item">Payment Protection:</span> Never
										make payments or communicate outside of HelloWorlds website.
										This is the only way we can protect your payments for your
										experiences and courses.
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-3">
						<div
							className="no-padding webinar-book-box"
							style={{
								opacity: this.state.opacity,
								transition: this.state.transition,
							}}
						>
							<div
								className="exp-date-box"
								style={
									light ? { backgroundColor: "transparent", boxShadow: "#d9d9d9 0px 2px 10px" } : {
										boxShadow: "rgb(58 58 58) 0px 2px 10px"
									}
								}
							>
								<CourseDateSelection
									update_token={this.props.update_token}
									access_token={this.props.access_token}
									show_time_box={this.state.show_time_box}
									student_id={this.props.student.id}
									price={this.state.fee}
									original_price={this.state.original_fee}
									n_classes={this.state.classes.length}
									course_id={this.props.match.params.course_id}
									title={this.state.title}
									featured_image={featured_image}
									student_name={this.props.student.first_name}
									mentor_name={this.state.mentor_name}
									language={this.state.language}
								/>
							</div>
						</div>
						<div className="request-mentor-lg">
							<RequestMentor
								type="courses"
								id={this.props.match.params.course_id}
								token={this.props.access_token}
								update_token={this.props.update_token}
							/>
							<NotifyMe
								type="courses"
								id={this.props.match.params.course_id}
								token={this.props.access_token}
								update_token={this.props.update_token}
							/>
						</div>

						<div
							className="no-padding coupon-code-box"
							style={{
								backgroundColor: light
									? "var(--background_primary)"
									: "var(--background_secondary)",
							}}
						>
							<div className="coupon-code-box-height">
								<div className="coupon-code-box-heading">HELLOWORLDS OFFERS</div>
								{this.state.coupons_list.length ? (
									this.state.coupons_list.map((coupons) => (
										<div className="single-coupon-wrapper" key={coupons.id}>
											<div className="time-schedule-exp col-sm-12 col-lg-12 col-md-12 col-xs-12">
												<p>
													{coupons.name}{" "}
													<OverlayTrigger
														placement="right"
														overlay={<Tooltip>Click to copy code</Tooltip>}
													>
														<i
															className="fa fa-clone ms-2"
															aria-hidden="true"
															onClick={() => {
																navigator.clipboard.writeText(coupons.name);
															}}
															style={{
																cursor: "pointer",
															}}
														></i>
													</OverlayTrigger>
												</p>
											</div>
											<div className="coupon-description">
												<p>{coupons.description}</p>
											</div>
										</div>
									))
								) : (
									<div className="no-upcoming-webinar">
										Uh oh. No coupons available :(
									</div>
								)}
							</div>
						</div>
					</div>
					<Title title="Similar Workshops/Courses For You" img="no-img" />
					<Recommendations
						type="course"
						id={this.props.match.params.course_id}
					/>
					<div className="raise-issue-div">
						<a
							href="https://pw6l23e2n1z.typeform.com/to/M2Ju4V8N"
							target="_blank"
							rel="noopener noreferrer"
						>
							Raise an issue with the class
						</a>
					</div>
				</div>

				{
					this.state.showScrollBtn && (
						<div className={styles.scrollToTop}>
							<button
								type="button"
								className="btn text-white border-0"
								onClick={() => {
									window.scrollTo({
										top: 0,
										behavior: "smooth",
									});
								}}
							>
								<i className="fa fa-arrow-up" />
							</button>
						</div>
					)
				}

				<div className="clearfix"></div>
				<Footer />
			</div>
		);
	}
}

class ConfPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			language: "English",
			fee: -1,
			featured_image: null,
			start_time: 0,
			end_time: 0,
		};
	}

	componentDidMount() {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({ random: "random" });

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/courses/payment/${this.props.payment_id}`;

		fetch(url, requestOptions)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then(
				(result) => {
					if (result["success"] === true) {
						this.setState({
							title: result.data.courses.title,
							featured_image: result.data.courses.featured_image,
							start_time: result.data.batch.start_time,
							end_time: result.data.batch.end_time,
							language: result.data.courses.language.language,
							created_at: result.data.created_ts,
							amount: result.data.total_amount,
							order_id: result.data.order_id,
						});

						// join the group
						joinGroup(
							this.props.course_id,
							"course",
							result.data.batch.start_time,
							this.props.student
						);
					}
				},
				(error) => { }
			);
	}

	closePopup() {
		swal.close();
	}

	render() {
		if (this.state.start_time !== 0) {
			//Moengage.add_first_name(this.props.student.first_name);
			//Moengage.add_last_name(this.props.student.last_name);
			//Moengage.add_email(this.props.student.email);
			window.Moengage.track_event("CoursePurchase", {
				order_id: this.state.order_id,
				first_name: this.props.student.first_name,
				last_name: this.props.student.last_name,
				grade: this.props.student.grade,
				age: this.props.student.dob,
				email: this.props.student.email,
				mobile_number: this.props.student.mobile_number,
				signup_date: onlyDate(this.props.student.created_at),
				signup_time: onlyTime(this.props.student.created_at),
				course_title: this.state.title,
				purchase_date: onlyDate(this.state.created_at),
				purchase_time: onlyTime(this.state.created_at),
				amount: this.state.amount,
				time_slot: scheduleStartTime(this.state.start_time),
			});
		}

		return (
			<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 payment-popup-conf no-padding">
				<div className="payment-popup-content-conf ">
					<p className="payment-participating-text-conf">
						Thank you! Let’s get the learning started :)
					</p>
					<div className="row no-padding first-section-payment">
						<div className="col-4 col-xs-5 no-padding">
							<LazyLoad
								src={this.state.featured_image}
								alt={this.state.title}
								classes="payment-img-conf"
							/>
						</div>
						<div className="col-8 col-xs-7 ps-3">
							<p className="exp-title-payment-conf">{this.state.title}</p>
							<p className="exp-title-payment-conf">
								By {this.props.mentor_name}
							</p>
							<p className="exp-language-payment-conf">
								Hosted in {this.state.language}
							</p>
							<p className="date-payment-popup-conf">
								From {scheduleStartTime(this.state.start_time)} -{" "}
								{scheduleEndTime(this.state.end_time)}
							</p>
						</div>
					</div>
					<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
						<p className="link-payment-popup-conf">
							You will find the link to attend the session on your dashboard.
							Join a few minutes early so that you don’t miss anything!
						</p>
					</div>
					<div className="payment-popup-btn-div">
						<button className="pay-btn" onClick={this.closePopup}>
							Okay
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default SingleCourse;
