import React, { Component } from "react";

class Banner extends Component {
    render() {
        //if (this.props.access_token===null) {
        // return <Redirect to='/login'/>;
        //}
        return (
            <div className="background-banner-listings">
                <img src="/images/exp_bg.png" alt="" />
                <div className="col-10 col-md-8 text-in-listings-banner">
                    <h2>Interactive experiences + introductory workshops</h2>
                    <p>
                        Try your hand in topics that fascinate you. Connect with
                        experts via virtual trips and events!
                    </p>
                </div>
            </div>
        );
    }
}

export default Banner;
