import PubNub from "pubnub";
import moment from "moment";

const pnKeys = {
	publishKey: process.env.REACT_APP_PUBNUB_PK,
	subscribeKey: process.env.REACT_APP_PUBNUB_SK,
};

const createPubNubInstance = (userId) => {
	const pubnub = new PubNub({
		...pnKeys,
		uuid: `${userId}`,
		ssl: true,
		presenceTimeout: 150,
	});

	return pubnub;
};

export const joinGroup = async (classId, classType, classBatch, student) => {
	const pubnub = createPubNubInstance(student.id);

	await registerUser(student);

	const batch = moment(classBatch).format("MMM Do YY").toString();

	let channel;
	if (classType === "club") channel = `group.${classType}.${classId}`;
	else channel = `group.${classType}.${classId}.${batch.split(" ").join("")}`;

	await pubnub.objects.setMemberships({
		uuid: pubnub.getUUID(),
		channels: [channel],
	});
};

export const registerUser = async (student) => {
	const pubnub = createPubNubInstance(student.id);

	let user;
	try {
		user = await pubnub.objects.getUUIDMetadata();
	} catch (e) {
		console.log(e);
		user = await pubnub.objects.setUUIDMetadata({
			uuid: pubnub.getUUID(),
			data: {
				name: `${student.first_name} ${student.last_name}`,
				email: student.email,
				profileUrl: student.avatar_url,
				custom: {
					title: `Grade ${student.grade}`,
				},
			},
		});
	} 
	// finally {
	// 	await pubnub.objects.setChannelMembers({
	// 		channel: "group.general",
	// 		uuids: [pubnub.getUUID()],
	// 	});
	// 	pubnub.subscribe({ channels: ["group.general"] });
	// }
	return user;
};
