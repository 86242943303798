import React, { Component } from "react";
import ReactDOM from "react-dom";
import swal from "sweetalert";
// import { Redirect } from "react-router-dom";
import LoginPopup from "../Auth/LoginPopup";
import NotifyMe from "../Commons/NotifyMe";
import RequestMentor from "../Commons/RequestMentor";

var dateStartTime = require("../utils.js").dateStartTime;
var timeRange = require("../utils.js").timeRange;
var scheduleStartTime = require("../utils.js").scheduleStartTime;
var scheduleEndTime = require("../utils.js").scheduleEndTime;
var cancellationTime = require("../utils.js").cancellationTime;

class TimeBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time_slots: [],
		};
		this.attendSchedule = this.attendSchedule.bind(this);
	}

	componentDidMount() {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/experiences/${this.props.exp_id}/schedule`;

		console.log(url);

		fetch(url, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					this.setState({
						time_slots: result.data.results,
					});
				},
				(error) => {}
			);
	}

	attendSchedule(schedule_id, start_time, end_time, cancellation_time) {
		if (this.props.access_token === null) {
			var swal_popup = document.createElement("div");
			ReactDOM.render(
				<React.StrictMode>
					<LoginPopup update_token={this.props.update_token} from="mindpod" />
				</React.StrictMode>,
				swal_popup
			);
			//swal_welcome_content.innerHTML = <QnaPopup />;
			swal({
				content: swal_popup,
				buttons: false,
				className: "swal-login",
				closeOnClickOutside: false,
			});
		} else {
			// eslint-disable-next-line no-redeclare
			var swal_popup = document.createElement("div");
			ReactDOM.render(
				<React.StrictMode>
					<AttendPopup
						access_token={this.props.access_token}
						schedule_id={schedule_id}
						student_id={this.props.student_id}
						student_name={this.props.student_name}
						mentor_name={this.props.mentor_name}
						exp_name={this.props.title}
						featured_image={this.props.featured_image}
						start_time={start_time}
						end_time={end_time}
						cancellation_time={cancellation_time}
						price={this.props.price}
						language={this.props.language}
					/>
				</React.StrictMode>,
				swal_popup
			);
			//swal_welcome_content.innerHTML = <QnaPopup />;
			swal({
				content: swal_popup,
				buttons: false,
				className: "swal-qna",
			});
		}
	}

	closeAvailability() {
		this.props.close_availability();
	}

	render() {
		return (
			<div>
				<div className="date-exp-container-second">
					<div className="title-mob-time-box hidden-sm hidden-md hidden-lg col-xs-12">
						Availability
					</div>
					<i
						className="fa fa-times-circle-o fs-4 position-absolute top-0 end-0 mt-2 me-4 d-md-none"
						onClick={() => this.closeAvailability()}
						aria-hidden="true"
					></i>
					{this.state.time_slots.length ? (
						this.state.time_slots.map((time_slot) => (
							<div className="row single-schedule-exp">
								<div className="time-schedule-exp col-sm-8 col-lg-8 col-md-8 col-xs-8">
									<p>{dateStartTime(time_slot.start_time)}</p>
									<p className="time-range-box">
										{timeRange(time_slot.start_time, time_slot.end_time)}
									</p>
								</div>
								<div className="col-sm-4 col-lg-4 col-md-4 col-xs-4">
									{time_slot.group_size.split(" ")[0] >
									time_slot.registration_count ? (
										<button
											className="attend-now-btn-schedule"
											onClick={() =>
												this.attendSchedule(
													time_slot.id,
													scheduleStartTime(time_slot.start_time),
													scheduleEndTime(time_slot.end_time),
													cancellationTime(time_slot.start_time)
												)
											}
										>
											Enrol
										</button>
									) : (
										<button className="attend-now-btn-schedule-grey">
											Sold out
										</button>
									)}
								</div>
							</div>
						))
					) : (
						<div className="no-upcoming-webinar py-3">
							<img
								src="/icons/calendar.png"
								alt="calendar-dashboard"
								className="calendar-dashboard w-25"
							/>
							<p className="no-bookings-yet">
								Uh oh. No upcoming schedule found :(
							</p>
						</div>
					)}
					<div className="request-mentor-sm">
						<RequestMentor
							type="experiences"
							id={this.props.exp_id}
							token={this.props.access_token}
							update_token={this.props.update_token}
						/>
						<NotifyMe
							type="experiences"
							id={this.props.exp_id}
							token={this.props.access_token}
							update_token={this.props.update_token}
						/>
					</div>
				</div>
			</div>
		);
	}
}

class CouponPopup extends Component {
	constructor(props) {
		super(props);
		this.closePopup = this.closePopup.bind(this);
		this.state = {
			coupons: [],
		};
	}

	componentDidMount() {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/coupons`;

		console.log(url);

		fetch(url, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					console.log(result["data"]["results"]);
					this.setState({
						coupons: result.data.results,
					});
				},
				(error) => {}
			);
	}

	closePopup() {
		swal.close();

		var swal_popup = document.createElement("div");
		ReactDOM.render(
			<React.StrictMode>
				<AttendPopup
					access_token={this.props.access_token}
					schedule_id={this.props.schedule_id}
					student_id={this.props.student_id}
					student_name={this.props.student_name}
					mentor_name={this.props.mentor_name}
					exp_name={this.props.title}
					featured_image={this.props.featured_image}
					start_time={this.props.start_time}
					end_time={this.props.end_time}
					cancellation_time={this.props.cancellation_time}
					price={this.props.price}
					language={this.props.language}
				/>
			</React.StrictMode>,
			swal_popup
		);
		//swal_welcome_content.innerHTML = <QnaPopup />;
		swal({
			content: swal_popup,
			buttons: false,
			className: "swal-qna",
		});
	}

	render() {
		return (
			<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 coupon-popup no-padding">
				<i
					className="fa fa-times login-popup-cross-icon"
					onClick={() => this.closePopup()}
					aria-hidden="true"
				></i>
				<div className="coupon-popup-content">
					{this.state.coupons.length ? (
						this.state.coupons.map((coupon) => (
							<div>
								<p className="bold-text">{coupon.name}</p>
								<p>{coupon.description}</p>
							</div>
						))
					) : (
						<div className="no-upcoming-webinar filter-error-message">
							Uh oh. No coupon found :(
						</div>
					)}
				</div>
			</div>
		);
	}
}

class AttendPopup extends Component {
	constructor(props) {
		super(props);
		this.redirectPayment = this.redirectPayment.bind(this);
		this.couponPopup = this.couponPopup.bind(this);
		this.applyCoupon = this.applyCoupon.bind(this);
		this.state = {
			coupon_code: this.props.coupon_code,
			final_coupon_code: "not_set",
			coupon_id: 0,
			coupon_discount: 0,
			subtotal: this.props.price,
			discount_amount: 0,
			tax_amount: (0.18 * Number(this.props.price)).toFixed(2),
		};
	}

	couponPopup() {
		var swal_popup = document.createElement("div");
		ReactDOM.render(
			<React.StrictMode>
				<CouponPopup
					access_token={this.props.access_token}
					student_id={this.props.student_id}
					schedule_id={this.props.schedule_id}
					student_name={this.props.student_name}
					mentor_name={this.props.mentor_name}
					exp_name={this.props.title}
					featured_image={this.props.featured_image}
					start_time={this.props.start_time}
					end_time={this.props.end_time}
					cancellation_time={this.props.cancellation_time}
					price={this.props.price}
					language={this.props.language}
				/>
			</React.StrictMode>,
			swal_popup
		);
		//swal_welcome_content.innerHTML = <QnaPopup />;
		swal({
			content: swal_popup,
			buttons: false,
			className: "swal-qna",
		});
	}

	redirectPayment() {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

		var urlencoded = new URLSearchParams();
		if (this.state.final_coupon_code !== "not_set") {
			urlencoded.append("coupon_code", this.state.final_coupon_code);
		}

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: urlencoded,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/experiences/${this.props.schedule_id}/payment`;

		fetch(url, requestOptions)
			.then(function (response) {
				console.log(response);
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then(
				(result) => {
					console.log(result);
					if (result["success"] === true) {
						var redirect_url = result.data.payment_link;
						window.location.replace(redirect_url);
					}
				},
				(error) => {}
			);
	}

	closePopup() {
		swal.close();
	}

	changeCouponCode(e) {
		this.setState({
			coupon_code: e.target.value,
		});
	}

	applyCoupon() {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

		var urlencoded = new URLSearchParams();
		urlencoded.append("coupon_code", this.state.coupon_code);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: urlencoded,
			redirect: "follow",
		};

		var url = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.props.student_id}/coupons/redemption`;

		fetch(url, requestOptions)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					alert("Incorrect coupon code.");
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then(
				(result) => {
					//console.log(result);
					if (result["success"] === true) {
						let discount_amount = Math.ceil(
							(Number(this.props.price) * Number(result.data.discount)) / 100
						);
						let subtotal = Number(this.props.price) - discount_amount;
						let tax_amount = (0.18 * Number(subtotal)).toFixed(2);
						this.setState({
							coupon_id: result.data.coupon_id,
							coupon_discount: result.data.discount,
							discount_amount: discount_amount,
							subtotal: subtotal,
							tax_amount: tax_amount,
							final_coupon_code: this.state.coupon_code,
						});
						alert(
							"Awesome! You just got " +
								result.data.discount +
								"% off on this purchase"
						);
					} else {
						alert("Incorrect coupon code.");
					}
				},
				(error) => {}
			);
	}
	//<p className="payment-participating-text">{this.props.student_name} is participating in</p>
	render() {
		return (
			<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 payment-popup no-padding">
				<div className="payment-popup-content">
					<div className="row no-margin first-section-payment">
						<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
							<img
								className="availability-cross-icon"
								src="/icons/cross.png"
								alt="availability-cross-icon"
								onClick={() => this.closePopup()}
							/>
							<img
								src={this.props.featured_image}
								alt={this.props.exp_name}
								className="payment-img"
							/>
						</div>
					</div>
					<div className="payment-popup-text-content">
						<div className="row no-margin second-section-payment">
							<h3>Order Summary</h3>
							<p>{this.props.exp_name}</p>
							<p>
								On {this.props.start_time} - {this.props.end_time}
							</p>
						</div>
						<hr />
						<div className="row no-margin coupon-section-payment margin-bottom-five">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
								<input
									type="text"
									placeholder="Enter Coupon Code"
									name="coupon_code"
									className="coupon-input-box"
									onChange={(event) => {
										this.changeCouponCode(event);
									}}
								/>
								<span onClick={() => this.applyCoupon()}>Apply</span>
							</div>
						</div>
						<div className="row no-margin coupon-section-payment">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
								<span onClick={() => this.couponPopup()}>
									Check Available Coupons
								</span>
							</div>
						</div>
						<div className="row no-margin third-section-payment">
							<div className="col-6 no-padding">
								{this.state.coupon_discount > 0 ? (
									<div>
										<h4>Price</h4>
										<h4>Discount</h4>
									</div>
								) : (
									<div></div>
								)}
								<h4>Subtotal</h4>
								<h4>Tax</h4>
							</div>
							<div className="col-6 no-padding">
								{this.state.coupon_discount > 0 ? (
									<div>
										<p>INR {this.props.price}</p>
										<p>INR {this.state.discount_amount}</p>
									</div>
								) : (
									<div></div>
								)}
								<p>INR {this.state.subtotal}</p>
								<p>INR {this.state.tax_amount}</p>
							</div>
						</div>
						<hr />
						<div className="row no-margin fourth-section-payment">
							<div className="col-6 no-padding">
								<h4>Total</h4>
							</div>
							<div className="col-6 no-padding">
								<p>
									INR{" "}
									{(
										Number(this.state.subtotal) + Number(this.state.tax_amount)
									).toFixed(2)}
								</p>
							</div>
						</div>
						<div className="row no-margin">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
								<p className="cancellation-payment-popup">
									Cancellation/Refund Policy
								</p>
								<p className="cancellation-text-popup">
									This booking can be cancelled and fully refunded if the
									cancellation request is made by 11:59 PM on{" "}
									{this.props.cancellation_time}.
								</p>
							</div>
							<div className="clear"></div>
							<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 t-c-accept no-padding">
								<p className="cancellation-text-popup">
									By clicking on the 'Proceed to Payment' button, you agree to
									the{" "}
									<a
										href="https://www.helloworlds.co/refund-policy"
										target="_blank"
										rel="noopener noreferrer"
									>
										Cancellation and Refunds Policy
									</a>
								</p>
							</div>
						</div>
						<div className="payment-popup-btn-div">
							<button
								className="pay-btn"
								onClick={this.redirectPayment}
							>
								Proceed to Payment
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TimeBox;
