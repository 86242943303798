import React, { useRef } from "react";
import ExperienceContent from "./ExperienceContent";
import SectionContent from "./SectionContent";
import ThingsToDo from "./ThingsToDo";
import Title from "./Title";
import styles from "./SingleExpDetails.module.css";

const SingleExpDetails = (props) => {
    const detailsRef = useRef(null);
    const requirementsRef = useRef(null);
    const mentorRef = useRef(null);

    const executeScroll = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <>
            <div className={styles.expContent}>
                <ul>
                    <li
                        onClick={() => {
                            executeScroll(detailsRef);
                        }}
                    >
                        Details
                    </li>
                    <li
                        onClick={() => {
                            executeScroll(requirementsRef);
                        }}
                    >
                        Requirements
                    </li>
                    <li
                        onClick={() => {
                            executeScroll(mentorRef);
                        }}
                    >
                        Mentor
                    </li>
                </ul>
            </div>
            <div ref={detailsRef}>
                <h3>Details</h3>
                <ExperienceContent
                    content={props.description}
                    img="no-img"
                    url="no-url"
                />
                <SectionContent
                    title="What you'll learn"
                    content={props.outcome}
                    img="no-img"
                    url="no-url"
                />
            </div>

            <div ref={requirementsRef}>
                <SectionContent
                    title="Requirements"
                    content={props.skill}
                    img="no-img"
                    url="no-url"
                />
                <Title title="Things to note" img="no-img" />
                <ThingsToDo notes={props.notes} />
            </div>

            <div ref={mentorRef}>
                <h3>Mentor</h3>
                <SectionContent
                    title={props.mentor_title}
                    content={props.mentor.about_me}
                    img={props.mentor.avatar_url}
                    url={props.mentor_url}
                />
            </div>
        </>
    );
};

export default SingleExpDetails;
