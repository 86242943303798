import React, { Component } from "react";
import LazyLoad from "../Commons/LazyLoad";

class ThingsToDo extends Component {
	render() {
		return (
			<div className="webinar-content">
				{this.props.notes.length ? (
					this.props.notes.map((note) => (
						<div className="thing-to-do" key={note.id}>
							<LazyLoad
								src="/icons/tick.png"
								alt="tick icon"
								style={{
									height: "25px",
									width: "25px",
								}}
							/>
							<span>{note.notes}</span>
						</div>
					))
				) : (
					<div className="thing-to-do">
						<LazyLoad
							src="/icons/tick.png"
							alt="tick icon"
							style={{
								height: "25px",
								width: "25px",
							}}
						/>
						<span>Make sure you have an active internet connection.</span>
					</div>
				)}
			</div>
		);
	}
}

export default ThingsToDo;
