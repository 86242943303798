import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const DobPicker = (props) => {
    const today = new Date();
    const selectedDate = props.value ? new Date(props.value) : today;

    return (
        <DatePicker
            className="dob-picker"
            placeholderText="Date of Birth"
            selected={selectedDate}
            onChange={(date) => props.onChange(moment(date).format("YYYY-MM-DD"))}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={today}
        />

    );
};

export default DobPicker;
