import { post, get, put } from './api'

const preURL = '/rest/students';

export const authApi = {
    googleLogin: () => {
        return get("/login/google/redirect")
    },

    sendOpt: (payload) => {
        return post("/auth/students/sendotp", payload);
    },

    verifyOpt: (payload) => {
        return post("/auth/students/verifyotp", payload);
    }
}

export const userApi = {
    getUser: (payload) => {
        return get(`${preURL}/${payload}`);
    },

    getWorldBookings: () => {
        return get(`${preURL}/world/bookings`);
    },

    getClassBookings: (payload) => {
        return get(`${preURL}/${payload}/courses/bookings`);
    },

    getNotifications: () => {
        return get(`${preURL}/world/user-notification-feed`);
    },

    getDashboardWebinars: () => {
        return get(`${preURL}/webinars/dashboard`);
    },

    getUpcomingClasses: (payload) => {
        console.log(payload)
        return get(`${preURL}/${payload}/courses/bookings`);
    },

    editProfile: (payload) => {
        return put(`${preURL}/${payload.id}`, payload);
    },
}

export const worldApi = {
    getAll: () => {
        return get(`${preURL}/world`);
    },

    getById: (payload) => {
        return get(`${preURL}/world/${payload}`);
    },

    getMembers: (payload) => {
        return get(`${preURL}/world/${payload}/members`);
    },

    getRecommendations: () => {
        return get(`${preURL}/recommendation/worlds`);
    },

    getUserFeed: () => {
        return get(`${preURL}/world/user-feed`);
    },

    getPaymentLink: (payload) => {
        return post(`${preURL}/world/${payload.id}/subscribe`, { payment: payload.payment });
    },

    getSubscriptionStatus: (payload) => {
        return get(`${preURL}/world/${payload.id}/subscribe/confirm/${payload.token}`);
    },

    getActivityFeed: (payload) => {
        return get(`${preURL}/world/${payload}/feed`);
    },

    getPastWebinars: (payload) => {
        return get(`${preURL}/webinars?type=past&world_id=${payload}`);
    },

    getUpcomingWebinars: (payload) => {
        return get(`${preURL}/webinars?&world_id=${payload}`);
    },

    getLibrary: (payload) => {
        return get(`${preURL}/world/${payload}/library`);
    },

    getChatToken: () => {
        return get(`${preURL}/world/chat/token`);
    },

    getChatChannels: () => {
        return get(`${preURL}/world/chat/get-channels`);
    }
}

export const mentorApi = {
    getAll: () => {
        return get(`${preURL}/mentors`);
    },

    getBySlug: (payload) => {
        return get(`${preURL}/mentors/${payload}/slug`);
    },
}