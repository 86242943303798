import Header from '../Header'
import Footer from "../Footer";
import { Container, Accordion } from 'react-bootstrap'


const data = [
    {
        que: 'What is HelloWorlds?',
        ans: 'HelloWorlds is a unique online marketplace where educators from around the world can create their own learning clubs, called "Worlds," for kids aged 6-18. It offers a wide range of interactive and engaging sessions, resources, and exclusive content that the educators create.'
    },
    {
        que: 'How can my child join a club on HelloWorlds?',
        ans: 'Kids can freely explore all the available clubs on HelloWorlds. Once they find a club that matches their interests and skills, they can become a member by signing up on the platform.'
    },
    {
        que: 'What are the types of clubs available on HelloWorlds?',
        ans: 'Educators create three types of clubs based on their preferences: paid clubs, paid clubs with a free trial, and free clubs. Kids can choose the type of club they wish to be a part of based on their preferences.'
    },
    {
        que: 'What kind of sessions can be found inside a club?',
        ans: 'Inside each club, educators can host a variety of sessions, such as classes, doubt-clearing sessions, general chats, debates, shows, workshops, webinars, and more. These sessions are designed to enhance the learning experience and foster creativity.'
    },
    {
        que: 'Can educators share additional resources with the club members?',
        ans: 'Yes, educators can share useful resources like PDFs and images with the club members, enabling them to access supplementary materials and enrich their learning.'
    },
    {
        que: 'Can we chat with other members of the club?',
        ans: 'Absolutely! Each club comes with a group chat feature that allows all members of the club to interact, collaborate, and build a community of learners.'
    },
    {
        que: 'Do all clubs on HelloWorlds require a membership fee?',
        ans: ' No, not all clubs require a membership fee. Educators have the flexibility to create both paid and free clubs, providing a diverse range of options for kids to choose from.'
    },
    {
        que: 'What if I want to cancel my membership for a club?',
        ans: 'Simple. Go to “My Journey” page and find the club you wish to cancel your membership to, and click on “Exit”. However, you will continue to have access till the end of the membership period you have previously paid for.'
    },
    {
        que: 'How does the free trial for paid clubs work?',
        ans: "Some educators may offer a free trial period for their paid clubs. During this trial, kids can experience the club's offerings without any cost. After the trial ends, they can decide whether to become a paying member or leave the club."
    },
    {
        que: 'How secure is HelloWorlds for kids?',
        ans: 'HelloWorlds takes the safety and privacy of its users very seriously. The platform employs robust security measures to ensure a safe and child-friendly environment for all participants.'
    },
    {
        que: 'Can kids access the exclusive content shared by educators?',
        ans: 'Yes, kids who are members of a club can access the exclusive content created and shared by the educators, including text, images, and videos.'
    },
    {
        que: 'How can educators join HelloWorlds as a club creator?',
        ans: 'Educators can apply to become club creators on HelloWorlds by following the application process provided on the platform. Once approved, they can start building their own learning clubs.'
    },
    {
        que: 'Is there any age limit for educators to create clubs?',
        ans: 'No! Even passionate kids can create and manage clubs on HelloWorlds.'
    },
    {
        que: 'How can I contact HelloWorlds support if I have additional questions or concerns?',
        ans: "If you have any more questions or concerns, you can reach out to us via email [hello@helloworlds.com]. We're here to assist you!"
    },
]

const Faq = (props) => {
    return (
        <>
            <Header
                current_route={props.current_route}
                avatar_url={props.avatar_url}
                access_token={props.access_token}
            />
            <Container className="my-5 accordion-wrapper">
                <h3>Frequently Asked Questions</h3>
                <Accordion defaultActiveKey="0" className='mt-3'>
                    {
                        data.map((item, index) => (
                            <Accordion.Item eventKey={index?.toString()} key={index}>
                                <Accordion.Header>
                                    {item.que}
                                </Accordion.Header>

                                <Accordion.Body>
                                    {item.ans}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                    }
                </Accordion>
            </Container>
            <Footer />
        </>
    )
}

export default Faq