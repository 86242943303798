import React, { Component } from "react";
import Header from "../Header";
import SubHeader from "../SubHeader";
// import Title from './Title';
// import WebinarContent from './WebinarContent';
// import ThingsToDo from './ThingsToDo';
// import SingleWebinarInfo from './SingleWebinarInfo';
import Qna from "./Qna";
import { capitalizeName } from "../utils";

import { Redirect } from "react-router";

class WebinarQna extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			start_time: 0,
			end_time: 0,
			description: "",
			subjects: [],
			notes: [],
			featured_image: null,
			mentor: {
				id: 0,
				first_name: "",
				last_name: "",
				avatar_url: null,
				about_me: "",
			},
			mentor_name: "",
		};
	}

	componentDidMount() {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/${this.props.match.params.webinar_id}`;

		console.log(url);

		fetch(url, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					//console.log(result["data"]["results"]);
					this.setState(
						{
							title: result.data.title,
							start_time: result.data.start_time,
							end_time: result.data.end_time,
							description: result.data.description,
							subjects: result.data.subjects,
							notes: result.data.notes,
							featured_image: result.data.featured_image,
							mentor: {
								id: result.data.mentor.id,
								first_name: result.data.mentor.first_name,
								last_name: result.data.mentor.last_name,
								avatar_url: result.data.mentor.avatar_url,
								about_me: result.data.mentor.about_me,
							},
						},
						() => {
							this.setState({
								mentor_name: capitalizeName(
									`${this.state.mentor.first_name} ${
										this.state.mentor.last_name || ""
									}`
								),
							});
						}
					);
				},
				(error) => {}
			);
	}

	render() {
		if (this.props.access_token === null) {
			return <Redirect to="/login" />;
		}
		// let mentor_title = "Meet your coach, " + this.state.mentor.first_name + " " + this.state.mentor.last_name;
		// let featured_image = "/default_webinar_img.png";
		// if (this.state.featured_image !== null) {
		//     featured_image = this.state.featured_image;
		// }

		return (
			<div>
				<Header avatar_url={this.props.avatar_url} />
				<SubHeader />
				<div className="clearfix"></div>
				<div className="qna-page-container col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 col-xs-12">
					<div>
						<h1>{this.state.title}</h1>
					</div>
					<div>
						<h2>By {this.state.mentor_name}</h2>
					</div>
				</div>
				<div className="clearfix"></div>
				<hr />
				<div className="qna-page-qnas col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 col-xs-12">
					<Qna
						show={10}
						access_token={this.props.access_token}
						mentor_name={capitalizeName(this.state.mentor.first_name)}
						webinar_id={this.props.match.params.webinar_id}
					/>
				</div>
			</div>
		);
	}
}

export default WebinarQna;
