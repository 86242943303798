import React, { useContext } from "react";
import ReactDOM from "react-dom";
import styles from "./UserFeed.module.css";
import { Container, Row, Col } from "react-bootstrap";
import UserFeedCard from "./UserFeedCard";
import { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import WorldsListItem from "./WorldsListItem";
import MoreWorlds from "./MoreWorlds";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ThemeContext } from "../../../context/ThemeContext";
import LikedModal from "../LikedModal";

const UserFeed = (props) => {
    const dark = useContext(ThemeContext).isDark;
    const [userFeed, setUserFeed] = useState([]); // initial posts
    const [allUserFeed, setAllUserFeed] = useState([]); // all posts

    const [loading, setLoading] = useState({
        feedLoading: true,
        clubLoading: true,
        joinedClubsLoading: true,
    });

    const DATA_LENGTH = allUserFeed.length; // total posts length
    const FEED_LENGTH = userFeed.length; // initial posts length

    const [clubs, setClubs] = useState([]); // New world on HelloWorlds
    const [joinedClubs, setJoindedClubs] = useState([]); // Your worlds

    const [likedModal, setLikedModal] = useState(false); // Show who liked the post
    const [likedModalData, setLikedModalData] = useState([]); // Data of who liked the post

    let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/`;
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.access_token);

    let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    // Fetching posts
    const fetchUserFeed = async () => {
        try {
            const response = await fetch(`${url}user-feed`, requestOptions);
            const data = await response.json();
            setUserFeed(data.data.results.slice(0, 8)); // setting 8 initial posts
            setAllUserFeed(data.data.results); // setting all posts
            setLoading((prev) => ({ ...prev, feedLoading: false }));
        } catch (error) {
            console.error("UserFeed Error : ", error);
        }
    };

    // Fetching more posts for infinite scroll
    const fetchMoreUserFeed = () => {
        setTimeout(() => {
            setUserFeed((data) => allUserFeed.slice(data.Length, data.length + 8)); // adding 8 more posts
        }, 500); // 0.5 second delay
    };

    // New worlds on evolve
    const fetchClubs = async () => {
        try {
            let newUrl = `${process.env.REACT_APP_BASE_URL}/rest/students/recommendation/worlds`;
            const response = await fetch(`${newUrl}`, requestOptions);
            const data = await response.json();
            setClubs(data.data.results.slice(0, 5));
            setLoading((prev) => ({ ...prev, clubLoading: false }));
        } catch (error) {
            console.error(error);
        }
    };

    // Your worlds
    const fetchJoindedClubs = async () => {
        try {
            const response = await fetch(`${url}bookings`, requestOptions);
            const data = await response.json();
            setJoindedClubs(data.data.results.slice(0, 5));
            setLoading((prev) => ({ ...prev, joinedClubsLoading: false }));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        document.title = "Timeline | HelloWorlds";
        if (props.access_token) {
            fetchUserFeed();
            fetchJoindedClubs();
            fetchClubs();
        } else {
            localStorage.setItem("redirect", window.location.pathname);
        }
    }, []);

    /*
              Loading Skeleton for the feed
          */
    const FeedSkeleton = () => {
        return (
            <div
                style={{
                    background: "var(--background_primary)",
                    borderRadius: "30px",
                    padding: window.innerWidth < 768 ? "0.5rem 1rem" : "1rem 2rem",
                    maxWidth: "650px",
                    boxShadow: "rgb(136 136 136 / 30%) 0px 2px 10px",
                    margin: "1rem auto",
                }}
            >
                <SkeletonTheme baseColor="gray" highlightColor="white">
                    <div className="d-flex align-items-center mb-3">
                        <Skeleton inline circle height={50} width={50} />
                        <p className="m-0 ms-3">
                            <Skeleton inline height={30} width={200} />
                        </p>
                    </div>

                    <div className="feed-skeleton">
                        <p className="my-1 ms-1">
                            <Skeleton height={22} width="50%" />
                        </p>
                        <Skeleton height="100%" width="100%" className="ms-1" />
                    </div>

                    <p className="mt-5 ms-2">
                        <Skeleton inline circle height={22} width={22} />
                    </p>

                    <div className="my-1">
                        <div
                            className="d-flex align-items-center mb-3"
                            style={{ marginLeft: "3px" }}
                        >
                            <Skeleton circle height={38} width={38} />
                            <p className="m-0 ms-4" style={{ width: "70%" }}>
                                <Skeleton height={35} />
                            </p>
                        </div>

                        <div
                            className="d-flex align-items-center"
                            style={{ marginLeft: "2px" }}
                        >
                            <Skeleton circle height={40} width={40} />
                            <p className="m-0 ms-4">
                                <Skeleton height={25} width={200} />
                            </p>
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
        );
    };

    const SidebarSkeletorn = () => {
        return (
            <div style={{ borderRadius: "30px", padding: "0.2rem 3rem" }}>
                <SkeletonTheme baseColor="gray" highlightColor="white">
                    <div className="d-flex flex-column mb-0 pe-3">
                        <div
                            className="d-flex align-items-center justify-content-between py-2 px-2 mb-3"
                            style={{
                                boxShadow: "var(--shadow_primary)",
                                borderRadius: "30px"
                            }}
                        >
                            <Skeleton inline circle height={50} width={50} />
                            <p className="m-0 ms-3 me-3 mt-2">
                                <Skeleton inline height={20} width={120} /> <br />
                                <Skeleton inline height={10} width={40} />
                            </p>
                            <Skeleton
                                inline
                                height={10}
                                width={20}
                                className="rounded-pill"
                            />
                        </div>

                        <div
                            className="d-flex align-items-center justify-content-between py-2 px-2 mb-3"
                            style={{
                                boxShadow: "var(--shadow_primary)",
                                borderRadius: "30px"
                            }}
                        >
                            <Skeleton inline circle height={50} width={50} />
                            <p className="m-0 ms-3 me-3 mt-2">
                                <Skeleton inline height={20} width={120} /> <br />
                                <Skeleton inline height={10} width={40} />
                            </p>
                            <Skeleton
                                inline
                                height={10}
                                width={20}
                                className="rounded-pill"
                            />
                        </div>

                        <div
                            className="d-flex align-items-center justify-content-between py-2 px-2 mb-3"
                            style={{
                                boxShadow: "var(--shadow_primary)",
                                borderRadius: "30px"
                            }}
                        >
                            <Skeleton inline circle height={50} width={50} />
                            <p className="m-0 ms-3 me-3 mt-2">
                                <Skeleton inline height={20} width={120} /> <br />
                                <Skeleton inline height={10} width={40} />
                            </p>
                            <Skeleton
                                inline
                                height={10}
                                width={20}
                                className="rounded-pill"
                            />
                        </div>

                        <div
                            className="d-flex align-items-center justify-content-between py-2 px-2 mb-3"
                            style={{
                                boxShadow: "var(--shadow_primary)",
                                borderRadius: "30px"
                            }}
                        >
                            <Skeleton inline circle height={50} width={50} />
                            <p className="m-0 ms-3 me-3 mt-2">
                                <Skeleton inline height={20} width={120} /> <br />
                                <Skeleton inline height={10} width={40} />
                            </p>
                            <Skeleton
                                inline
                                height={10}
                                width={20}
                                className="rounded-pill"
                            />
                        </div>

                        <div
                            className="d-flex align-items-center justify-content-between py-2 px-2 mb-3"
                            style={{
                                boxShadow: "var(--shadow_primary)",
                                borderRadius: "30px"
                            }}
                        >
                            <Skeleton inline circle height={50} width={50} />
                            <p className="m-0 ms-3 me-3 mt-2">
                                <Skeleton inline height={20} width={120} /> <br />
                                <Skeleton inline height={10} width={40} />
                            </p>
                            <Skeleton
                                inline
                                height={10}
                                width={20}
                                className="rounded-pill"
                            />
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
        );
    };

    return (
        <>
            <Header
                update_token={props.update_token}
                avatar_url={props.avatar_url}
                access_token={props.access_token}
                current_route="timeline"
            />
    
            <Row
                className={`${styles.userFeedContainer} w-100 justify-content-center gopher-font video-bg-content`}
            >
                <Col
                    xs={12}
                    className={`ps-3 py-2 pt-5 ${styles.userFeedSidebar} `}
                    style={{
                        width: "22%",
                        marginTop: "35px",
                    }}
                >
                    <div style={{ position: "sticky", top: "100px", }}>
                        <div
                            className="left-stat-div2 row flex-column "
                        >
                            <h4
                                style={{ marginTop: "14px" }}
                                className="gopher-font fw-bolder"
                            >
                                Your Worlds
                            </h4>

                            <div
                                className="Worlds d-flex flex-column"
                                style={{
                                    position: "relative",
                                    top: "120px"
                                }}
                            >
                                {loading.clubLoading ? (
                                    <SkeletonTheme baseColor="gray" highlightColor="white">
                                        <Skeleton
                                            containerClassName="no-upcoming-webinar"
                                            wrapper={SidebarSkeletorn}
                                            count={1}
                                        />
                                    </SkeletonTheme>
                                ) : joinedClubs.length ? (
                                    joinedClubs.map((club) => (
                                        <WorldsListItem
                                            club={club} key={club.world.id}
                                            dark={dark}
                                        />
                                    ))
                                ) : (
                                    <div>
                                        <p className="gopher-font">No worlds joined</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>

                <Col
                    xs={12}
                    className={styles.userFeedContainerMid}
                >
                    <div
                        className="mb-4 mt-2 user-feed-container"
                        style={{
                            display: "grid",
                            placeContent: "center",
                        }}
                    >
                        {/* <h2 className="py-3 fw-bolder">Your Feed</h2> */}
                        {loading.feedLoading ? (
                            <SkeletonTheme
                                baseColor="gray"
                                highlightColor="white"
                            >
                                <Skeleton
                                    wrapper={FeedSkeleton}
                                    count={5}
                                />
                            </SkeletonTheme>
                        ) : userFeed.length === 0 ? (
                            <>
                                <div
                                    style={{
                                        textAlign: "left",
                                        boxShadow: "var(--shadow_primary)",
                                        borderRadius: "30px",
                                        marginTop: "50px",
                                    }}
                                >
                                    <div className="d-flex justify-content-center py-4">
                                        <img
                                            src="/userFeed.png"
                                            alt="helloworlds no feed"
                                            style={{ maxWidth: "100%" }}
                                        />
                                    </div>
                                    <h5
                                        className="text-info px-4 pb-4"

                                    >
                                        No new posts! This serves as your main wall, showcasing new posts from all the Worlds you've joined.
                                    </h5>
                                </div>
                                <div className="d-block m-auto">
                                    <button
                                        type="button"
                                        className={`${styles.subscribeButton} text-white`}
                                        onClick={() => {
                                            props.history.push(`/worlds`);
                                        }}
                                    >
                                        Explore Worlds
                                    </button>
                                </div>
                            </>
                        ) : (
                            <InfiniteScroll
                                dataLength={FEED_LENGTH} // initial posts length
                                next={fetchMoreUserFeed} // fetching more posts
                                hasMore={FEED_LENGTH !== DATA_LENGTH} // when to call next
                                loader={
                                    <h2 className="text-center my-3">
                                        <Spinner animation="border" variant="danger" />
                                    </h2>
                                }
                                style={
                                    dark
                                        ? {
                                            boxShadow: "rgb(136 136 136 / 30%) 0px 2px 10px",
                                            overflow: "hidden",
                                            borderRadius: "30px",
                                            marginTop: "50px",
                                        }
                                        : {
                                            boxShadow: "#d9d9d9 0px 2px 10px",
                                            overflow: "hidden",
                                            borderRadius: "30px",
                                            marginTop: "50px",
                                        }
                                }
                            >
                                <Container fluid className={styles.activity}>
                                    {userFeed.map((userFeed, index) => (
                                        <Container
                                            key={index}
                                            className={styles["individual-activity"]}
                                        >
                                            <UserFeedCard
                                                userFeed={userFeed}
                                                worlds={props.worlds}
                                                access_token={props.access_token}
                                                fetchUserFeed={fetchUserFeed}
                                                student={props.student}
                                                worldMembers={props.worldMembers}
                                                history={props.history}
                                                setLikedModal={setLikedModal}
                                                setLikedModalData={setLikedModalData}
                                            />
                                        </Container>
                                    ))}

                                    {DATA_LENGTH === FEED_LENGTH && (
                                        <Container className="d-flex justify-content-center flex-column align-items-center pb-5 pt-3">
                                            <h4>All done! Explore more clubs</h4>
                                            <button
                                                className="my-2 fs-6 px-3 py-2 text-white"
                                                onClick={() => props.history.push("/worlds")}
                                            >
                                                Explore
                                            </button>
                                        </Container>
                                    )}
                                </Container>
                            </InfiniteScroll>
                        )}
                    </div>
                </Col>

                <Col
                    xs={12}
                    className={`ps-3 py-2 pt-5 ${styles.userFeedSidebar}`}
                    style={{
                        width: "22%",
                        marginTop: "35px"
                    }}
                >
                    <div style={{ position: "sticky", top: "100px" }}>
                        <div
                            className="left-stat-div2 row flex-column"
                        >
                            <h4 style={{ marginTop: "14px" }} className="gopher-font fw-bolder">New Worlds on HelloWorlds</h4>

                            <div className="Worlds d-flex flex-column"
                                style={{ position: "relative", top: "120px" }}
                            >
                                {loading.joinedClubsLoading ? (
                                    <SkeletonTheme baseColor="gray" highlightColor="white">
                                        <Skeleton
                                            containerClassName="no-upcoming-webinar"
                                            wrapper={SidebarSkeletorn}
                                            count={1}
                                        />
                                    </SkeletonTheme>
                                ) : clubs.length ? (
                                    clubs.map((club) => <MoreWorlds dark={dark} club={club} key={club.id} />)
                                ) : (
                                    <div>
                                        <p className="gopher-font">No worlds found</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            {/*Showing who liked the post */}
            {
                likedModal && (
                    <LikedModal
                        show={likedModal}
                        handleClose={() => setLikedModal(false)}
                        dark={dark}
                        data={likedModalData}
                    />
                )
            }

            <div className="mt-5">
                <Footer />
            </div>
        </>
    );
};

export default UserFeed;
