import React, { Component } from "react";
import Header from "../Header";
import UpcomingWebinars from "./UpcomingWebinars";
import PastWebinars from "./PastWebinars";
import LiveWebinars from "./LiveWebinars";
import Banner from "./Banner";
import styles from "./styleHome.module.css";
import Footer from "../Footer";

class Home extends Component {
	componentDidMount() {
		document.title = "Sound Pod | HelloWorlds";
	}
	render() {
		//if (this.props.access_token===null) {
		// return <Redirect to='/login'/>;
		//}

		return (
			<div>
				<Header
					update_token={this.props.update_token}
					current_route={this.props.current_route}
					avatar_url={this.props.avatar_url}
					access_token={this.props.access_token}
				/>
				<Banner />
				<div className={`row m-0 py-5 ${styles.room_container}`}>
					<div className={`col-12 col-lg-6`}>
						<LiveWebinars
							location={{ ...this.props.location }}
							access_token={this.props.access_token}
						/>
					</div>
					<div className="col-12 col-lg-6">
						<UpcomingWebinars
							location={{ ...this.props.location }}
							access_token={this.props.access_token}
						/>
					</div>
					<div className={`col-12 col-lg-6 float-left`}>
						<PastWebinars
							location={{ ...this.props.location }}
							access_token={this.props.access_token}
						/>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default Home;
