import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyLoad = ({ src, alt, classes, wrapper, ...props }) => {
	return (
		<LazyLoadImage
			src={src}
			alt={alt}
			className={classes}
			effect="blur"
			wrapperClassName={wrapper}
			{...props}
		/>
	);
};

export default LazyLoad;
