import React, { Component } from "react";
// import Title from "./Title";
import ReadMoreReact from "read-more-react";

class AboutMentor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minimumLength: 150,
            maximumLength: 100000,
            idealLength: 15000,
        };
    }

    render() {
        return (
            <div className="row mentor-about pt-0" style={{ maxWidth: "1020px", fontSize:"17px" }}>
                {typeof this.props.about_me !== "undefined" &&
                    this.props.about_me.length > 0 ? (
                    <ReadMoreReact
                        text={this.props.about_me}
                        max={this.state.maximumLength}
                        min={this.state.minimumLength}
                        ideal={this.state.idealLength}
                        readMoreText={
                            <div className="text-decoration-underline">
                                Read more
                            </div>
                        }
                    />
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default AboutMentor;
