import Header from "../Header";
import Footer from "../Footer";
// import Banner from "./Banner";
import ClubCards from "./ClubCards";
import { useEffect, useContext, useState } from "react";
import GoogleLoginSuggestion from "../Auth/GoogleLoginSuggestion";
import { LoginSuggestionContext } from "../../context/LoginSuggestionContext";

const Worlds = (props) => {
	const { showGoogleLoginSuggestion, setShowGoogleLoginSuggestion } = useContext(LoginSuggestionContext);
	const [show, setShow] = useState(false);
	useEffect(() => {
		document.title = "Worlds | HelloWorlds";
		if (props.access_token === null || props.access_token === undefined) {
			localStorage.setItem("redirect", "/worlds");
			setTimeout(() => {
				setShow(true);
			}, 10000);
		}
		return () => {
			if (show) {
				setShow(false);
			}
		}
	}, []);

	return (
		<>
			<Header
				update_token={props.update_token}
				avatar_url={props.avatar_url}
				access_token={props.access_token}
				current_route="worlds"
			/>
		
			{showGoogleLoginSuggestion && show && (
				window.innerWidth > 768 ? (
					<GoogleLoginSuggestion setShowGoogleLoginSuggestion={setShowGoogleLoginSuggestion} />
				) : null
			)}
			{/* <Banner /> */}
			<ClubCards access_token={props.access_token} history={props.history}/>
			<Footer />
		</>
	);
};

export default Worlds;
