import React, { Component } from 'react';

class LoginGoogle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            data: {},
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_BASE_URL}/login/google/callback${this.props.location.search}`, { headers: new Headers({ accept: 'application/json' }) })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => {
                this.setState({ loading: false, data });
                localStorage.setItem('student_uuid', data.data.uuid.uuid);
	          	localStorage.setItem('student_token', data.data.api_token);
	          	window.Moengage.add_unique_user_id(data.data.id);
                if(data.data.first_time) {
                    window.location = "/edit-profile";
                } else {
                    window.location = localStorage.getItem("redirect") || "/";
                }
            })
            .catch((error) => {
                this.setState({ loading: false, error });
                console.error(error);
            });
    }

    render() {
        const { loading, error } = this.state;
        if (loading) {
            return <div>Redirecting....</div>;
        }

        if (error) {
            return (
                <div>
                    <div>
                        <p>Error:</p>
                        <code className="Code-block">{error.toString()}</code>
                    </div>
                </div>
            );
        }

        return (
            <div>
                Redirecting to home page
            </div>
        );
    }
}

export default LoginGoogle;