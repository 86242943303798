import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import userReducer from './slices/user';
import mentorsReduces from './slices/mentors';
import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        user: userReducer,
        mentors: mentorsReduces,
    },
    middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;