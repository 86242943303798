import React, { Component } from 'react';
import { Redirect } from 'react-router';
import LoginPopup from "./LoginPopup";
import ReactDOM from "react-dom";
import swal from "sweetalert";

class Logout extends Component {

	constructor(props) {
		super(props);
		this.state = {
			redirect_to_login: false
		};
	}

	componentDidMount() {
		localStorage.removeItem("student_token");
		localStorage.removeItem("student_uuid");
		localStorage.removeItem("redirect");
		localStorage.removeItem("student");
		window.Moengage.destroy_session();
		window.location.replace("/worlds");
	}

	render() {
		return (
			this.state.redirect_to_login ? (
				<Redirect to="/worlds" />
			) : (
				<div>
					Logging Out...
				</div>
			)
		);
	}
}

const loginPopup = () => {
	var swal_popup = document.createElement("div");
	ReactDOM.render(
		<React.StrictMode>
			<LoginPopup />
		</React.StrictMode>,
		swal_popup
	);
	swal({
		content: swal_popup,
		buttons: false,
		className: "swal-login",
		closeOnClickOutside: false,
	});
};



export default Logout;
