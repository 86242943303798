import Styles from "./Chat.module.css";

const ChannelHeader = (props) => {
    return (
        <div className="str-chat__header-livestream str-chat__channel-header">
            <div className={Styles.header}>
                <button className={`${Styles.hamburger} no-hover`} onClick={props.handleMobMenu}>
                    <img src="/icons/back.png" alt="" />
                </button>
                <div className={Styles.info}>
                    <div className={Styles.left}>
                        <div className={Styles.image}>
                            {
                                props?.currentChannel?.channel?.member_count === 1 ? (
                                    <img
                                        src={
                                            props?.currentChannel?.members[0]?.user?.id === props?.userId ? (
                                                props?.currentChannel?.channel?.created_by?.avatar_url ? props?.currentChannel?.channel?.created_by?.avatar_url : "/student.png"
                                            ) : (
                                                props?.currentChannel?.members[0]?.user?.avatar_url ? props?.currentChannel?.members[0]?.user?.avatar_url : "/student.png"
                                            )
                                        }
                                        alt={props?.currentChannel?.channel?.created_by?.name}
                                    />
                                ) : (
                                    <img
                                        src="/student.png"
                                        alt="group"
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div className={Styles.right}>
                        <h6>
                            {
                                props?.currentChannel?.channel?.member_count === 1 ? (
                                    props?.currentChannel?.members[0]?.user?.id === props?.userId ? (
                                        props?.currentChannel?.channel?.created_by?.name
                                    ) : (
                                        props?.currentChannel?.members[0]?.user?.name
                                    )
                                ) : props?.currentChannel?.channel?.channel_name
                            }
                        </h6>
                        <p>
                            {
                                !props?.currentChannel?.channel?.member_count ? (
                                    ""
                                ) : props?.currentChannel?.channel?.member_count === 1 ? (
                                    ""
                                ) : (
                                    `${props?.currentChannel?.channel?.member_count + 1} members`
                                )
                            }
                        </p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default ChannelHeader