import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "./styleExperience.module.css";
import { ThemeContext } from "../../context/ThemeContext";
import { capitalizeName } from "../utils";
import LazyLoad from "../Commons/LazyLoad";
import { Col, Card } from "react-bootstrap"

class NewSnippetExp extends Component {
    static contextType = ThemeContext;

    render() {
        let light = !this.context.isDark;
        let snippet = this.props.snippet;
        // let duration = this.props.snippet.duration;
        let featured_image = "/default_webinar_img.png";
        if (snippet.featured_image !== null) {
            featured_image = snippet.featured_image;
        }
        let snippet_url = "/experience/" + snippet.id;
        // let attend_url = "/experience/" + snippet.id + "/attend";
        let city = "";
        if (this.props.snippet.mentor.city !== null) {
            city = this.props.snippet.mentor.city.name;
        }

        let class_snippet = "experience-snippet";

        if (this.props.class_set) {
            class_snippet = "exp-snippet-mentor";
        }

        const stripHtml = (text = "") => {
            const regex = /(<([^>]+)>)/gi;
            let data = text.replace(regex, "");
            data = data.replace(/&nbsp;/g, " ");
            data = data.replace(/Powered by Froala Editor/, "");
            return data;
        };

        return (
            <Col className={styles.cardOuter + class_snippet + "col-xs-12 col-sm-6 col-lg-4 py-3"}>
                <Link
                    to={snippet_url}
                    className={styles.cardLink}
                >
                    <Card
                        className={`${styles.Card}`}
                    >
                        <Card.Header
                            style={{
                                padding: "10px 5px",
                                backgroundColor: " rgb(100, 27, 43),",
                                borderRadius: "20px",
                                border: "0"
                            }}
                        >
                            <div
                                className={`position-absolute top-0 ps-3 pe-2 mt-1 fw-bolder ${styles.courseTypeRibbon}`}
                            >
                                Grades {snippet?.grades[0]?.grade}-{snippet?.grades[snippet?.grades.length - 1]?.grade}
                            </div>

                            <div
                                className={styles.cardHeader}
                                style={{
                                    height: "200px",
                                    padding: "0",
                                    margin: "0",
                                    borderRadius: "20px",
                                }}
                            >
                                <LazyLoad
                                    src={featured_image}
                                    alt="club image"
                                    classes={styles.banner}
                                    wrapper="image-lazyload"
                                />
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-between my-2">
                                <p className="mb-2 mt-1 fw-bold"> WORKSHOP </p>
                                <p className="mb-2 mt-1 fw-bold"> {snippet.duration }</p>
                            </div>
                            <Card.Title style={{ fontWeight: "600" }}>{snippet.title}</Card.Title>
                            <Card.Text>
                                {
                                    stripHtml(snippet.description).slice(0, 80) + "..."
                                }
                            </Card.Text>
                            <p className="m-0 mt-4 p-0 d-flex align-items-center">
                                <img
                                    src={snippet.mentor.avatar_url}
                                    alt="Abhishek"
                                    style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        display: "flex"
                                    }}
                                />
                                <span className="ps-2">
                                    {capitalizeName(
                                        `${snippet.mentor.first_name} ${snippet.mentor.last_name}`
                                    )}
                                </span>
                            </p>
                        </Card.Body>
                    </Card>
                </Link>
            </Col>
        );
    }
}
export default NewSnippetExp;
