import React, { Component } from "react";
import swal from "sweetalert";
import ReactDOM from "react-dom";
import LoginPopup from "../Auth/LoginPopup";
import { Modal } from "react-bootstrap";

class WebinarDateSelection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// show_time_box: this.props.show_time_box,
			zoom_url: "",
			showModal: false,
		};
		// this.showTimeBox = this.showTimeBox.bind(this);
		// this.closeAvailability = this.closeAvailability.bind(this);
		this.registerStudent = this.registerStudent.bind(this);
	}

	componentDidMount() {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/${this.props.webinar_id}/recording`;

		fetch(url, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					//window.location.href = result["data"].recording;
					this.setState({
						zoom_url: result.data?.recording,
					});
				},
				(error) => {
					this.setState({ zoom_url: "This recording does not exist." });
				}
			);
	}

	// showTimeBox() {
	// 	this.setState({
	// 		show_time_box: 1,
	// 	});
	// }

	// closeAvailability() {
	// 	this.setState({
	// 		show_time_box: 0,
	// 	});
	// }

	registerStudent() {
		if (
			this.props.access_token === null ||
			this.props.access_token === undefined
		) {
			//alert("Please login to register.");
			//window.location.href = '/login';
			var swal_popup = document.createElement("div");
			ReactDOM.render(
				<React.StrictMode>
					<LoginPopup update_token={this.props.update_token} from="soundpod" />
				</React.StrictMode>,
				swal_popup
			);
			//swal_welcome_content.innerHTML = <QnaPopup />;
			swal({
				content: swal_popup,
				buttons: false,
				className: "swal-login",
				closeOnClickOutside: false,
			});
		} else {
			this.props.register_student();
		}
	}

	render() {
		let attend_url = "/room/" + this.props.webinar_id + "/attend";

		const closeModal = () => {
			this.setState({ showModal: false });
			console.log("shit");
		};

		const getZoomLink = () => {
			if (this.state.zoom_url === "This recording does not exist.") {
				this.setState({ showModal: true });
			} else {
				window.open(this.state.zoom_url, "_blank");
			}
		};

		return (
			<>
				<div className="date-exp-container-wrapper">
					{
						this.props.location.state?.path === "worlds" ? (
							<div
								className="price-experience"
								style={{ visibility: "hidden" }}
							>
								<span></span>
							</div>
						) : (
							<div className="price-experience">
								₹ <span>{this.props.price}</span> / person
							</div>
						)
					}

					{this.props.past ? (
						<>
							{this.state.zoom_url !== "" ? (
								<button
									className="attend-now-btn-webinar text-white"
									onClick={getZoomLink}
									style={{
										width: window.innerWidth > 768 ? "" : "100%",
										padding: window.innerWidth > 768 ? "10px" : "10px 0",
									}}
								>
									Watch Recording
								</button>
							) : (
								<button
									className="attend-now-btn-webinar text-white"
									style={{
										width: window.innerWidth > 768 ? "" : "100%",
										padding: window.innerWidth > 768 ? "10px" : "10px 0",
									}}
								>
									Loading...
								</button>
							)}
							<Modal
								show={this.state.showModal}
								centered
								onHide={closeModal}
								dialogClassName="zoom-nolink-popup"
							>
								<div
									style={{
										backgroundColor: "var(--background_primary)",
										color: "var(--fontColor-primary)",
									}}
									className="row login-page-popup"
								>
									<div className="login-box-popup col-sm-12 col-md-12 col-lg-12 col-xs-12 border-bottom">
										<h5>Oops...</h5>
										<i
											className="fa fa-times login-popup-cross-icon"
											onClick={closeModal}
											aria-hidden="true"
										></i>
									</div>
									<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 social-login-pop mt-3">
										No recording link found for this Room.
									</div>
								</div>
							</Modal>
						</>
					) : this.props.registered === 0 ? (
						<button
							className="attend-now-btn-webina text-white"
							onClick={() => this.registerStudent()}
							style={{
								width: window.innerWidth > 768 ? "" : "100%",
								padding: window.innerWidth > 768 ? "10px" : "10px 0",
							}}
						>
							Attend
						</button>
					) : (
						<button
							className="attend-now-btn-webinar text-white"
							style={{
								width: window.innerWidth > 768 ? "" : "100%",
								padding: window.innerWidth > 768 ? "10px" : "10px 0",
							}}
							onClick={() => { window.open(attend_url, "_blank"); }}
						>
							Join Now
						</button>
					)}
				</div>
				{/* {this.state.show_time_box === 1 && (
					<TimeBox
						update_token={this.props.update_token}
						access_token={this.props.access_token}
						close_availability={this.closeAvailability}
						register_student={this.props.register_student}
						registered={this.props.registered}
						past={this.props.past}
						start_time={this.props.start_time}
						end_time={this.props.end_time}
						webinar_id={this.props.webinar_id}
						mentor_name={this.props.mentor_name}
						featured_image={this.props.featured_image}
						price={this.props.price}
						title={this.props.title}
					/>
				)} */}
			</>
		);
	}
}

export default WebinarDateSelection;
