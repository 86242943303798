import React, { Component } from "react";
import LazyLoad from "../Commons/LazyLoad";

class ThingsToDo extends Component {
	render() {
		return (
			<div className="webinar-content border-0 mt-2" style={{ fontSize: "17px" }}>
				{this.props.notes.map((note, index) => (
					<div className="thing-to-do" key={index}>
						<LazyLoad
							src="/icons/tick.png"
							alt="tick icon"
							style={{
								height: "25px",
								width: "25px",
							}}
						/>
						<span>{note.notes}</span>
					</div>
				))}
			</div>
		);
	}
}

export default ThingsToDo;
