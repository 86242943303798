import { Modal } from 'react-bootstrap'
/**
 * For handling profile picture
 * @param {string} image url
 * @returns {string} url if image is not null or undefined or empty string else default image
 */
const handleProfilePic = (img) => {
    if (img === null || img === undefined) {
        return "/student.png"
    } else if (img === "" || img === "NA") {
        return "/student.png"
    } else {
        return img
    }
}

const LikedModal = ({ show, handleClose, dark, data }) => {
    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
        >
            <Modal.Header
                closeButton
                className="text-warning border-0 px-4"
                style={
                    dark ? {
                        filter: "invert(1)",
                        borderRadius: "30px",
                    } : {}
                }
            >
                <Modal.Title id="contained-modal-title-vcenter"
                    style={
                        dark ? {
                            filter: "invert(1)",
                            color: "var(--border)"
                        } : {
                            color: "var(--border)",
                        }
                    }
                >
                    Liked by:
                </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{
                    borderRadius: "30px",
                    minWidth: window.innerWidth > 500 ? "400px" : "300px",
                }}
            >

                <div className=" gopher-font">
                    {
                        data?.map((like, index) => (
                            <div className='d-flex align-items-center mb-3' key={index}>
                                <img
                                    src={handleProfilePic(like?.user?.data?.avatar_url)}
                                    alt="HellowWorlds Student"
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        marginRight: "10px",
                                    }}
                                />
                                <p>
                                    {
                                        like?.user?.data?.name
                                    }
                                </p>
                            </div>
                        ))
                    }
                </div>

            </Modal.Body>
        </Modal >
    )
}

export default LikedModal