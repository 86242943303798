import React, { useState, useEffect } from "react";
import ClubCard from "./ClubCard";
import { Row, Container, Modal, Col } from "react-bootstrap";
import { useContext } from "react";
import styles from "./ClubCards.module.css";
import LazyLoad from "../Commons/LazyLoad";
import { ThemeContext } from "../../context/ThemeContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import "react-loading-skeleton/dist/skeleton.css";
import Search from "./Search"
import Banner from "./Banner";
import { useLocation } from "react-router-dom";
import cardStyles from "./ClubCard.module.css"

const ClubSection = (props) => {
	const dark = useContext(ThemeContext).isDark;
	const [filterOne, setFilterOne] = useState("Recent");
	const [showModal, setShowModal] = useState(false);
	const [filters, setFilters] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [worlds, setWorlds] = useState([]);
	const [totalPage, settotalPage] = useState(1);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [currPage, setcurrPage] = useState(1);
	const [loading, setloading] = useState(true);

	// const [searchValue, setSearchValue] = useState("");

	let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world`;

	/*
		Fetch World from get world API
	*/
	const fetchWorlds = async (url) => {
		try {
			setloading(true);
			let myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + props.access_token);

			let requestOptions = {
				method: "GET",
				headers: myHeaders,
				redirect: "follow",
			};
			const response = await fetch(url, requestOptions);
			const data = await response.json();
			setWorlds(data.data.results);
			settotalPage(data.data.metadata.total_pages);
			setcurrPage(data.data.metadata.current_page);
		} catch (error) {
			console.log(error);
		}
		setloading(false);
	}

	/*
		Fetch Subjects Filter from subject API
	*/
	const fetchSubjects = async () => {
		try {
			let url = `${process.env.REACT_APP_BASE_URL}/rest/students/subjects`;
			let myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + props.access_token);

			let requestOptions = {
				method: "GET",
				headers: myHeaders,
				redirect: "follow",
			};
			const response = await fetch(url, requestOptions);
			const data = await response.json();
			setSubjects(data.data.results);
		} catch (error) {
			console.log(error);
		}
	}

	/*
		Fetch data of subjects and worlds and load page
	*/
	useEffect(() => {
		fetchSubjects();
	}, []);

	useEffect(() => {
		setcurrPage(queryParams.get("page") || 1);
		fetchWorlds(
			`${process.env.REACT_APP_BASE_URL}/rest/students/world?type=${filterOne
			}&filter=[${filters.join(",")}]&page=${queryParams.get("page") || 1}`
		);
	}, [location.search])

	const handlePageChange = (event) => {
		setWorlds([]);
		queryParams.set("page", event.selected + 1);
		props.history.push({
			pathname: '/worlds',
			search: queryParams.toString()
		});
		window.scrollTo(0, 0);
	};

	const stripHtml = (text = "") => {
		const regex = /(<([^>]+)>)/gi;
		let data = text.replace(regex, "");
		data = data.replace(/&nbsp;/g, " ");
		data = data.replace(/Powered by Froala Editor/, "");
		return data;
	};

	/*
		Loading Skeleton for the Club Card
	*/
	const WorldSkeleton = () => {
		return (
			<Col md={6} lg={4} className={cardStyles.cardOuter}>
				<div className={cardStyles.card}>
					<div className={cardStyles.cardImg}>
						<Skeleton height={200} style={{ borderRadius: "20px" }} />
					</div>
					<div className={cardStyles.cardContent}>
						<h5>
							<Skeleton height={20} />
						</h5>
						<div className={cardStyles.features}>
							<Skeleton height={30} width={100} className="rounded-pill" />
							<Skeleton height={30} width={100} className="rounded-pill" />
						</div>
						<p>
							<Skeleton height={10} />
							<Skeleton height={10} />
							<Skeleton height={10} />
						</p>
						<div className={cardStyles.mentor}>
							<Skeleton circle={true} height={40} width={40} />
							<Skeleton height={20} width={100} />
						</div>
					</div>
				</div >
			</Col>
		);
	};

	/*
		World Pagination Implementation 
	*/
	const PaginatedWorld = () => {
		return (
			<>
				{worlds.length === 0 && (
				< >
					<WorldSkeleton />
					<WorldSkeleton />
					<WorldSkeleton />
					<WorldSkeleton />
					<WorldSkeleton />
					<WorldSkeleton />
					<WorldSkeleton />
					<WorldSkeleton />
					<WorldSkeleton />
					<WorldSkeleton />
					<WorldSkeleton />
					<WorldSkeleton />
				</>
				)}
				{worlds.length !== 0 &&
					<>
						{
							worlds.map((world) => (
								<ClubCard
									key={world?.id}
									club_id={world?.id}
									img_url={world?.image}
									title={world?.title}
									instructor={world?.uuid?.user?.first_name + " " + world?.uuid?.user?.last_name}
									instructorImg={world?.uuid?.user?.avatar_url}
									subject={world?.primary_subject?.name}
									grades={`${world?.grades[0]?.grade}-${world?.grades[world?.grades.length - 1]?.grade}`}
									description={
										world.tagline && (
											stripHtml(world.tagline)
										)
									}
								/>
							))
						}
					</>
				}
				<div className={styles.pagination}>
					<ReactPaginate
						previousLabel="<<"
						nextLabel=">>"
						pageClassName="page-item"
						pageLinkClassName="page-link"
						previousClassName="page-item"
						previousLinkClassName="page-link"
						nextClassName="page-item"
						nextLinkClassName="page-link"
						breakLabel="..."
						breakClassName="page-item"
						breakLinkClassName="page-link"
						pageCount={totalPage}
						onPageChange={handlePageChange}
						containerClassName="pagination"
						activeClassName="active"
						forcePage={currPage - 1}
					/>
				</div>
			</>
		);
	};

	/*
		Filter for relevance (All, Popular, Recent)
	*/
	const handleTypeChange = (type) => {
		setFilterOne(type);
		const url = `${process.env.REACT_APP_BASE_URL
			}/rest/students/world?type=${type}&filter=[${filters.join(
				","
			)}]`;

		fetchWorlds(url);
	};

	/*
		Open Subject Filter Popup
	*/
	const openFiltersPopup = () => {
		setShowModal(true);
	};

	/*
		Close Subject Filter Popup
	*/
	const closeFiltersPopup = () => {
		setShowModal(false);
		// setSearchValue("");
	};

	/*
		Filter for subject
	*/
	const search = (data) => {
		let filterIds = [];
		if (data.length) {
			filterIds = data?.map(filter => filter?.id);
		}
		const url = `${process.env.REACT_APP_BASE_URL}/rest/students/world?type=${filterOne
			}&filter=[${filterIds.join(",")}]`;
		fetchWorlds(url);
	};

	const handleResetFilters = () => {
		setFilters([]);
		fetchWorlds(url);
	}

	/*
		Get class of world type 
	*/
	const getclassWorldType = (type) => {
		if (filterOne === "" && type === "All") {
			return styles["is-active"];
		}

		if (filterOne === type.toLowerCase()) {
			return styles["is-active"];
		}

		return "";
	}

	/*
		Subject Filter Modal
	*/
	const FilterModal = () => {
		const cardOuterClassName = "col m-2";
		return (
			<Modal
				show={showModal}
				centered
				onHide={closeFiltersPopup}
				animation={false}
			>
				<Modal.Header className=".dark-bg-pri d-flex justify-content-between">
					<Modal.Title>Select topics</Modal.Title>
					<i
						className="fa fa-times fs-4"
						style={{ cursor: "pointer" }}
						aria-hidden="true"
						onClick={closeFiltersPopup}
					></i>
				</Modal.Header>
				<Modal.Body className=".dark-bg-pri row py-3 px-4" style={{ borderRadius: "20px" }}>
					<div>

					</div>
					{subjects.length ? (
						subjects.sort((a, b) => (a.name > b.name ? 1 : -1))
							.map((subject) => (
								<FilterItems
									key={subject.id}
									type="checkbox"
									id={subject.id}
									label={subject.name}
									checked={filters.includes(subject.id)}
									onChange={(e) => {
										let filter = [...filters];
										if (e.target.checked) {
											filter.push(subject.id);
										} else {
											filter = filters.filter(
												(id) => id !== subject.id
											);
										}
										setFilters(filter);
									}}
								/>
							))
					) : (
						<div className="no-upcoming-webinar filter-error-message">
							Uh oh. No filters found :(
						</div>
					)}
					<div
						className="d-flex justify-content-center position-fixed bottom-0 w-100"
						style={{
							maxWidth: "480px",
							paddingTop: "15px",
							background: "var(--background_primary)",
						}}
					>
						<button
							className="px-5 me-2 mb-3 py-2 text-white"
							onClick={() => {
								search();
								closeFiltersPopup();
							}}
						>
							Search
						</button>
						<button
							className="px-3 ms-2 mb-3 py-2"
							onClick={() => {
								// setSearchValue("");
								setFilters([])
								fetchWorlds(url);
							}}
							style={{ background: "transparent", border: "2px solid var(--border)" }}
						>
							Reset Filters
						</button>
					</div>

				</Modal.Body>
			</Modal>
		);
	};

	return (
		<>
			{
				currPage === 1 && (
					<Banner />
				)
			}
			<Container className="mb-4 video-bg-content">
				<Row className={styles["top-row"]}>
					{subjects.length !== 0 ? (
						<Row className="justify-content-center align-items-center mb-3">
							<Col md={9}>
								<Search options={subjects} selected={filters} setSelected={setFilters} search={search} />
							</Col>
							<Col md={3}>
								<div className="d-flex gap-2">
									{
										filters.length !== 0 && (
											<button
												style={{
													height: "40px",
													display: "flex",
													alignItems: "center",
													color: "var(--fontColor-secondary)",
													border: "1px solid var(--fontColor-secondary)",
												}}
												className="search-btn"
												onClick={handleResetFilters}
											>
												Reset
											</button>
										)
									}
								</div>
							</Col>
						</Row>
					) : (
						<SkeletonTheme baseColor="gray" highlightColor="white">
							<Skeleton
								width={144}
								height={44}
								style={{
									margin: "0.5rem 0 1rem 0.5rem",
									borderRadius: "50rem",
								}}
							/>
						</SkeletonTheme>
					)
					}
				</Row>
				<Row>
					{(!loading && worlds.length === 0 && (
						<div className="no-upcoming-webinar mt-5">
							<LazyLoad src="/icons/calendar.png" alt="No worlds found." />
							<p className="text-center">
								Uh oh. No worlds found for the selected filter.
							</p>
						</div>
					)) || <PaginatedWorld />}
				</Row>
				<FilterModal />
			</Container>
		</>
	);
};

const Card = ({
	onClick,
	noSelectedFilters,
	isSelected,
	title,
	itemId,
	outerClassName,
	image,
	fullWidth,
}) => {
	const [selected, setSelected] = useState(isSelected);

	return (
		<div className="col-12 col-md-6" style={{ overflow: "hidden" }}>
			<div
				onClick={() => {
					onClick(!selected);

					setSelected(!selected);
				}}
				style={{
					...(!fullWidth
						? {
							display: "flex",
							justifyContent: "center",
							backgroundColor:
								selected || (noSelectedFilters && itemId === "all")
									? "var(--background_tertiary)"
									: "var(--background_primary)",
							borderRadius: "10px",
							border:
								selected || (noSelectedFilters && itemId === "all")
									? "1px solid var(--border)"
									: "none",
						}
						: {}),
				}}
				tabIndex={0}
				className={outerClassName}
			>
				<div
					className={
						JSON.stringify(!!selected) === "true" && fullWidth
							? "filter-card" +
							(title.length < 9
								? " filter-card-active"
								: title.length < 16
									? " filter-card-active-big"
									: " filter-card-active-supreme")
							: "filter-card"
					}
					style={{
						position: "relative",
					}}
				>
					{image && (
						<div
							style={{
								height: "100px",
								width: "100%",
								backgroundColor: "#555",
								borderRadius: "10px",
								overflow: "hidden",
							}}
						>
							<LazyLoad
								src={image}
								alt={title}
								style={{
									height: "100px",
									width: "160px",
									objectFit: "cover",
								}}
							/>
						</div>
					)}
					<div
						style={{
							textAlign: "center",
							marginTop: !fullWidth ? "12px" : 0,
							...(JSON.stringify(!!selected) === "true" && !fullWidth
								? { fontWeight: "bold" }
								: undefined),
						}}
					>
						{title}
					</div>
				</div>
			</div>
		</div>
	);
};

const FilterItems = ({
	id,
	label,
	checked,
	onChange,
	disabled,
	...props
}) => {
	const [isChecked, setIsChecked] = useState(checked)

	return (
		<div className="form-check my-2 col-12 col-md-6">
			<input
				className="form-check-input"
				type="checkbox"
				id={id}
				checked={isChecked}
				onChange={onChange}
				disabled={disabled}
				{...props}
			/>
			<label className="form-check-label" htmlFor={id}>
				{label}
			</label>
		</div>

	);
};

export default ClubSection;
