import React, { Component } from "react";
import { Link } from "react-router-dom";

class SubHeader extends Component {
	render() {
		return (
			<div className="px-0">
				<div className="subheader-div">
					<Link to={this.props.backUrl}>
						<span className="d-none d-md-inline">
							<i className="fa fa-chevron-left" aria-hidden="true"></i> Back to{" "}
							{this.props.back}
						</span>
						<button className="d-md-none">
							<i
								className="fa fa-chevron-left"
								style={{ color: "white" }}
								aria-hidden="true"
							></i>
						</button>
					</Link>
				</div>
			</div>
		);
	}
}

export default SubHeader;
