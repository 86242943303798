import React, { Component } from "react";
import { Link } from "react-router-dom";

class SideHeader extends Component {
	render() {
		let exp_class = "";
		let course_class = "";
		let world_class = "";
		let courses_class = "";
		if (this.props.current_route === "recording-course") {
			course_class = "selected-side-header";
		} else if (this.props.current_route === "recording-world") {
			world_class = "selected-side-header";
		} else if(this.props.current_route === "recording-world-courses"){
			courses_class = "selected-side-header";
		}
		else {
			exp_class = "selected-side-header";
		}
		return (
			<header
				style={{
					zIndex: 1,
					boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
					borderRadius: "5px",
					position: "sticky",
					top: "100px",
					marginBottom: "30px",
					backgroundColor: "var(--background_primary)",
				}}
			>
				<div
					className="side-header-div"
					style={
						this.props.dark
							? {
								boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
								background: "var(--background_primary)",
							}
							: {
								boxShadow: "#d9d9d9 0px 2px 10px",
								background: "var(--background_primary)",
							}
					}
				>
					<div className="title-side-header">
						<h5>My Bookings</h5>
					</div>
					<Link to={"/past-recordings/worlds"}>
						<div className={world_class}>Worlds</div>
					</Link>
					<Link to={"/past-recordings/worlds-courses"}>
						<div className={courses_class}>Classes</div>
					</Link>
					{/* <Link to={"/past-recordings/experience"}>
						<div className={exp_class}>Workshops</div>
					</Link>
					<Link to={"/past-recordings/course"}>
						<div className={course_class}>Courses</div>
					</Link> */}
					<img
							src="/myworlds.png"
							style={{
								width: "95%",
								filter:"drop-shadow(0px 0px 1px)",
							}}
							className="d-none d-md-block m-auto mt-2"
						/>
				</div>
			</header>
		);
	}
}

export default SideHeader;
