import React, { Component } from "react";
import swal from "sweetalert";
import LazyLoad from "../Commons/LazyLoad";
import { ThemeContext } from "../../context/ThemeContext";

class InterestPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			interests: [],
			studentInterest: [],
			studentSelectedInterest: [],
			buttonDisable: true,
		};
	}
	static contextType = ThemeContext;

	componentDidMount() {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);
		if (this.props.interest) {
			var selectedInterest = [];
			var selectedStudentInterest = this.props.interest;
			selectedInterest = selectedStudentInterest.map(function (interest) {
				return interest.id;
			});

			this.setState({
				studentInterest: selectedInterest,
				studentSelectedInterest: selectedInterest,
			});
		}
		this.getInterests();
	}

	getInterests(myHeaders) {
		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(
			`${process.env.REACT_APP_BASE_URL}/rest/students/subjects`,
			requestOptions
		)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then(
				(result) => {
					this.setState({
						interests: result["data"]["results"],
					});
				},
				(error) => {
					localStorage.removeItem("student_token");
					localStorage.removeItem("student_uuid");
					this.setState({
						redirect: true,
					});
				}
			);
	}

	addInterest = (e) => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);
		myHeaders.append("Content-Type", "application/json");

		var studentId = this.props.student_uuid;

		var raw = JSON.stringify({
			interests: this.state.studentInterest,
		});

		var requestOptions = {
			method: "PUT",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			`${process.env.REACT_APP_BASE_URL}/rest/students/${studentId}/interests`,
			requestOptions
		)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then((result) => {
				if (this.props.interest) {
					swal({
						title:
							"Awesome! This will help us recommend the right learning experiences on HelloWorlds :)",
						text: "",
						className: "interest-saving-text",
					}).then((okay) => {
						// window.location.reload();
					});
				} else {
					swal({
						title: "Profile Updated Successfully.",
						text: "",
					}).then((okay) => {
						window.location.reload();
					});
				}
			})
			.catch((error) => {
				console.log("error", error);
				localStorage.removeItem("student_token");
				localStorage.removeItem("student_uuid");
				window.location.reload();
				return Promise.reject(error);
			});
	};

	selectDeselectInterest(id, event) {
		var interest = [];
		var studentInterest = this.state.studentInterest;
		var element = document.getElementById(`flag-${id}`);
		var selectedElementTick = document.getElementById(`tick-${id}`);

		// Check if studentInterest state contains interest if yes then removes it and if not then add it.
		if (studentInterest.includes(id)) {
			for (var i = 0; i < studentInterest.length; i++) {
				if (id === studentInterest[i]) {
					studentInterest.splice(i, 1);
					element.classList.remove("interest-image-opacity");
					selectedElementTick.classList.remove("interest-div-student-ticked");
					selectedElementTick.classList.add("interest-div-student");
				}
			}
		} else {
			interest.push(id);
			selectedElementTick.classList.remove("interest-div-student");
			selectedElementTick.classList.add("interest-div-student-ticked");
			element.classList.add("interest-image-opacity");
		}

		// Check that all the states are pushed in interest array which later on update studentInterest state
		if (studentInterest.length > 0) {
			for (var j = 0; j < studentInterest.length; j++) {
				interest.push(studentInterest[j]);
			}
		}

		// Check is made to mark minimum 3 and maximum 6 interest, accordingly button is acive/disabled
		if (interest.length >= 3 && interest.length <= 6) {
			this.setState({
				studentInterest: interest,
				buttonDisable: false,
			});
		} else {
			this.setState({
				studentInterest: interest,
				buttonDisable: true,
			});
		}
	}

	render() {
		const light = !this.context.isDark;
		const content = this.state.interests
			.sort((a, b) => (a.name > b.name ? 1 : -1))
			.map((interest, idx) => (
				<div
					className="interest-row"
					key={idx}
				>
					<a onClick={(e) => this.selectDeselectInterest(interest.id, e)}>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								position: "relative",
							}}
							id={`flag-${interest.id}`}
						>
							{/* <div
								className={
									this.state.studentSelectedInterest.includes(interest.id)
										? "fa fa-check interest-tick-mark"
										: "fa fa-check interest-tick-mark-hide"
								}
								
								id={`tick-${interest.id}`}
							></div> */}
							{/* <LazyLoad
								src={interest.image_url}
								alt={interest.name}
								classes={
									this.state.studentSelectedInterest.includes(interest.id)
										? "interest-image interest-image-opacity"
										: "interest-image"
								}
								id={`flag-${interest.id}`}
							/> */}
						</div>
						<div
							// className="interest-div-student"
							className={
								this.state.studentSelectedInterest.includes(interest.id)
									? "interest-div-student-ticked"
									: "interest-div-student"
							}
							id={`tick-${interest.id}`}
						>

							<p>
								{interest.name}
							</p>
						</div>
					</a>
					{/* </div>
                ) )} */}
				</div>
			));

		return (
			<div
				className="col-sm-12 col-md-12 col-lg-12 col-xs-12 profile-popup-main"
				style={
					!light
						? {
							boxShadow: "rgb(136 136 136 / 30%) 0px 2px 10px",
							borderRadius: "30px",
							background: "var(--background_primary)",
						}
						: {
							boxShadow: "#d9d9d9 0px 2px 10px",
							borderRadius: "30px",
							background: "var(--background_primary)",
						}
				}
			>
				<div className="profile-popup-header">Tell us what interests you</div>
				<p className="interest-button-info-1 mb-1">
					Select at least 3 interests (maximum 6) to help us personalise your journey.
				</p>
				<div className="interest-popup-wrapper">{content}</div>
				{/* <h6 className="interest-button-info" style={{ fontWeight: 600 }}>
					Interests selected :{" "}
					{this.state.studentInterest.length > 0
						? this.state.studentInterest.length
						: "None"}
				</h6> */}
				<div className="d-flex align-items-center mt-2 gap-3">
					<h6 className="interest-button-info">
						{
							`${this.state.studentInterest.length} of 6 selected`
						}
					</h6>
					<button
						id="save-button"
						disabled={this.state.buttonDisable}
						className="edit-interest-btn-popup m-0"
						onClick={this.addInterest}
					>
						Save Changes
					</button>
				</div>
			</div>
		);
	}
}

export default InterestPopup;
