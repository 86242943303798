import { call, put, takeEvery } from 'redux-saga/effects';
import { getAllMentors, getAllMentorsSuccess, getAllMentorsFailure } from '../slices/mentors';
import { mentorApi } from '../api';

function* getMentors() {
    try {
        const { data } = yield call(mentorApi.getAll);
        if (!data.success) throw new Error("Error fetching user");
        yield put(getAllMentorsSuccess(data.data.results));

    } catch (error) {
        yield put(getAllMentorsFailure(error));
    }
}

export default function* mentorsSaga() {
    yield takeEvery(getAllMentors, getMentors);
}