import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import swal from "sweetalert";
// import Header from "../Header";
import EnterMobile from "./EnterMobile";
import EnterOTP from "./EnterOTP";
import { authApi } from "../../services/api";

// import Footer from "../Footer";
import { ThemeContext } from "../../context/ThemeContext";

//var Moengage = require('../utils.js').Moengage;

class Login extends Component {
	static contextType = ThemeContext;

	constructor(props) {
		super(props);
		this.state = {
			showPhone: true,
			mob: 0,
			redirect: false, //redirects after login,
			first_time_user: 0,
			googleLoginURL: null,
			// facebookLoginURL: null,
		};
		this.mobHandler = this.mobHandler.bind(this);
		this.otpHandler = this.otpHandler.bind(this);
	}

	componentDidMount() {
		document.title = "Login | HelloWorlds";
		this.loginGoogle();
		// this.loginFacebook();
	}

	async mobHandler(mob) {
		try {
			const { data } = await authApi.sendOpt({ phone_number: mob });
			if (!data.success) throw new Error("Something went wrong. Please contact support.");
			this.setState({
				showPhone: false,
				mob: mob,
				first_time_user: data.data.first_time_user,
			});
		} catch (error) {
			console.log(error);
		}
	}

	async otpHandler(otp) {
		try {
			const { data } = await authApi.verifyOpt({
				phone_number: this.state.mob,
				otp: otp,
			});
			if (!data.success) throw new Error("Something went wrong. Please contact support.");
			localStorage.setItem("student_uuid", data.data.user_uuid);
			localStorage.setItem("student_token", data.data.token);
			window.Moengage.add_unique_user_id(data.data.user_uuid);
			window.location = this.props.location || "/";
		} catch (error) {
			swal("OTP doesn't seem to be right. Please recheck.")
			console.log(error)
		}
	}

	async loginGoogle() {
		try {
			const { data } = await authApi.googleLogin();
			if (data.url) {
				this.setState({ googleLoginURL: data.url })
			} else {
				throw new Error("Something went wrong. Please contact support.");
			}
		} catch (error) {
			console.log(error);
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/" />;
		}

		return (
			<div className="overflow-hidden">
				<div className="row login-page">
					<div className="login-box col-12 col-md-6">
						<img
							src="/edvolve_full.png"
							className="edvolve-full-logo"
							alt="edvolve-full-logo"
						/>
						<h2
							className="mt-32 mb-16"
							style={{ color: "var(--fontColor-secondary)" }}
						>
							Hangout and learn with your favorite creators & educators. Be a part of amazing clubs and level up on your passion.
						</h2>
						<h3 className="mb-16" style={{ color: "inherit" }}>
							Create a new account or log in
						</h3>

						{this.state.showPhone ? (
							<EnterMobile
								style={{ color: "var(--fontColor-primary)" }}
								mob_handler={this.mobHandler}
							/>
						) : (
							<EnterOTP
								otp_handler={this.otpHandler}
								mob_number={this.state.mob}
								first_time_user={this.state.first_time_user}
							/>
						)}
						<div className="row">
							<div className="col-12 col-lg-6 google-button-login-page">
								<fieldset className="social-text-placement">
									<span
										style={{
											color: "inherit",
										}}
										className="social-text-or-placement"
									>
										OR
									</span>
								</fieldset>
								<a className="google-button" href={this.state.googleLoginURL}>
									<i className="fa fa-google"></i> Continue with Google
								</a>
								{/* <a
                                    className="google-button facebook-button"
                                    href={this.state.facebookLoginURL}
                                >
                                    <i className="fa fa-facebook"></i> Sign In/Sign
                                    Up with Facebook
                                </a> */}
							</div>
						</div>
					</div>
					<div className="login-img-div col-12 col-md-6 d-none d-md-block">
						<div
							className="login-img"
							style={{
								backgroundImage: "url(/images/login_bg.png)",
							}}
						></div>
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
