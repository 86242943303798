import Styles from "./Chat.module.css";
import React, { useState, useEffect } from 'react'
import {
    Chat as StreamChatUI,
    Channel,
    Thread,
    Window,
    MessageList,
    MessageInput,
} from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import '@stream-io/stream-chat-css/dist/css/index.css';
import Footer from "../Footer";
import { getToken, getChannels } from './utils';
import ChatChannelsList from './ChatChannelsList';
import ChannelHeader from './ChannelHeader';
import Loading from './Loading';

const StreamAPIKey = process.env.REACT_APP_STREAM_API_KEY

const Chat = (props) => {
    console.log(props)
    const user = {
        id: props?.student_uuid,
        name: `${props?.student?.first_name} ${props?.student?.last_name}`,
        image: props?.student?.avatar_url,
    }

    const [token, setToken] = useState(null)
    const [client, setClient] = useState(null);
    const [channels, setChannels] = useState([])
    const [currentChannel, setCurrentChannel] = useState(null)
    const [openMobMenu, setOpenMobMenu] = useState(false)

    const getUserToken = async () => {
        const response = await getToken()
        if (response?.data?.token) {
            setToken(response.data.token)
        }
    }

    const getChannelList = async () => {
        const response = await getChannels()
        if (response?.data?.channels) {
            setChannels(response.data.channels)
        }
    }

    const handleMobMenu = () => {
        setOpenMobMenu(!openMobMenu)
    }

    const handleChangeChannel = (channel) => {
        setCurrentChannel(channel)
        setOpenMobMenu(false)
    }

    useEffect(() => {
        getUserToken()
        getChannelList()
    }, [])

    useEffect(() => {
        if (!token || !props?.student) return
        const client = new StreamChat(StreamAPIKey)
        let didUserConnectInterrupt = false;
        const connectionPromise = client
            .connectUser(user, token)
            .then(() => {
                if (!didUserConnectInterrupt) {
                    setClient(client);
                    console.log('connection established');
                }
            });
        return () => {
            didUserConnectInterrupt = true;
            setClient(undefined);
            // wait for connection to finish before initiating closing sequence
            connectionPromise
                .then(() => client.disconnectUser())
                .then(() => {
                    console.log('connection closed');
                });
        };
    }, [token, props?.student])

    useEffect(() => {
        if (props?.location?.state?.channel) {
            setCurrentChannel(props?.location?.state?.channel)
            return
        }
        if (props?.location?.state?.clubName) {
            const channel = channels.find(channel => channel?.channel?.channel_name === props?.location?.state?.clubName)
            if (channel) {
                setCurrentChannel(channel)
                return
            }
        }
        if (channels.length) {
            setCurrentChannel(channels[0])
        }
    }, [channels.length])

    useEffect(() => {
        if (window.innerWidth < 768) {
            setOpenMobMenu(true)
        }
    }, [])

    // useEffect(() => {
    //     if (!client || !currentChannel) return
    //     const channel = client.channel(currentChannel?.channel?.type, currentChannel?.channel?.id);
    //     channel.watch();
    // }, [client, currentChannel])


    if (!client) return <Loading />

    return (
        currentChannel ? (
            <StreamChatUI client={client}>
                <div className={Styles.chatContainer}>
                    <div
                        className={Styles.channelsList}
                        style={
                            window.innerWidth < 768 ? { display: openMobMenu ? "block" : "none" } : {}
                        }
                    >
                        <div className={Styles.channelsListHead}>

                            <div className={Styles.channelHeadLeft}>
                                <button onClick={() => props?.history?.goBack()} className="no-hover">
                                    <i
                                        className="fa fa-chevron-left"
                                        style={{ color: "var(--fontColor_primary" }}
                                        aria-hidden="true"
                                    ></i>
                                </button>
                                <h4>
                                    Chats
                                </h4>
                            </div>
                            {/* <div className={Styles.channelHeadRight}>
                                <button onClick={handleMobMenu} className={Styles.closeBtn}>
                                    <img src="/icons/cross.jpg" alt="" />
                                </button>
                            </div> */}
                        </div>
                        <ChatChannelsList
                            channels={channels}
                            currentChannel={currentChannel}
                            setCurrentChannel={handleChangeChannel}
                            userId={user?.id}
                        />
                    </div>
                    <div
                        className={Styles.messages}
                        style={
                            window.innerWidth < 768 ? { display: openMobMenu ? "none" : "block" } : {}
                        }
                    >
                        <Channel channel={client.channel(currentChannel?.channel?.type, currentChannel?.channel?.id)}>
                            <Window>
                                <ChannelHeader
                                    currentChannel={currentChannel}
                                    handleMobMenu={handleMobMenu}
                                    userId={user?.id}
                                />
                                <MessageList />
                                <MessageInput />
                            </Window>
                            <Thread />
                        </Channel>
                    </div>
                </div>
            </StreamChatUI>
        ) : (
            <div className={Styles.noChannels}>
                <h3>No chats found</h3>
                <p>
                    Ask your mentor to create a channel for you.
                </p>
                <button
                    className="mt-2 px-2 py-1"
                    onClick={() => props?.history?.goBack()}
                >
                    Go Back
                </button>
            </div>
        )
    )
}

export default Chat