import React, { Component } from "react";
// import { Link } from "react-router-dom";
import ReactDOM from "react-dom";

// import { Redirect } from "react-router";
import swal from "sweetalert";
// import Header from "../Header";
import EnterMobile from "./EnterMobile";
import EnterOTP from "./EnterOTP";
import ProfilePopup from "./ProfilePopup";

// import Footer from "../Footer";
import { ThemeContext } from "../../context/ThemeContext";
import { authApi } from "../../services/api";

//var Moengage = require('../utils.js').Moengage;

class LoginPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPhone: true,
			mob: 0,
			redirect: false, //redirects after login,
			first_time_user: 0,
			googleLoginURL: null,
			popupText:
				"Hangout and learn with your favorite creators & educators. Be a part of amazing clubs and level up on your passion.",
			// facebookLoginURL: null,
		};
		this.mobHandler = this.mobHandler.bind(this);
		this.otpHandler = this.otpHandler.bind(this);
	}

	componentDidMount() {
		this.loginGoogle();
		if (this.props?.from === "soundpod") {
			this.setState({
				popupText:
					"This and tons of other life-changing sessions waiting. One signup away.",
			});
		} else if (this.props?.from === "mindpod") {
			this.setState({
				popupText:
					"This and many other life-changing classes from international educators. One signup away.",
			});
		}
	}

	async mobHandler(mob) {
		try {
			const { data } = await authApi.sendOpt({ phone_number: mob });
			if (!data.success) throw new Error("Something went wrong. Please contact support.");
			this.setState({
				showPhone: false,
				mob: mob,
				first_time_user: data.data.first_time_user,
			});
		} catch (error) {
			console.log(error);
		}
	}

	async otpHandler(otp) {
		try {
			const { data } = await authApi.verifyOpt({
				phone_number: this.state.mob,
				otp: otp,
			});
			if (!data.success) throw new Error("Something went wrong. Please contact support.");
			localStorage.setItem("student_uuid", data.data.user_uuid);
			localStorage.setItem("student_token", data.data.token);
			window.Moengage.add_unique_user_id(data.data.user_uuid);
			swal.close();
			if (this.state.first_time_user === 1) {
				var swal_profile_popup = document.createElement("div");
				ReactDOM.render(
					<React.StrictMode>
						<ProfilePopup
							access_token={data.data.token}
							student_uuid={data.data.user_uuid}
						/>
					</React.StrictMode>,
					swal_profile_popup
				);
				swal({
					content: swal_profile_popup,
					buttons: false,
					className: "swal-login",
					closeOnClickOutside: false,
				});
			} else {
				window.location.reload();
			}
			console.log(data)
		} catch (error) {
			swal("OTP doesn't seem to be right. Please recheck.")
			console.log(error)
		}
	}

	closePopup() {
		swal.close();
	}

	async loginGoogle() {
		try {
			const { data } = await authApi.googleLogin();
			if (data.url) {
				this.setState({ googleLoginURL: data.url })
			} else {
				throw new Error("Something went wrong. Please contact support.");
			}
		} catch (error) {
			console.log(error);
		}
	}

	static contextType = ThemeContext;

	render() {
		return (
			<div>
				<div
					style={{
						backgroundColor: "var(--background_primary)",
						color: "var(--fontColor-primary)",
					}}
					className="row login-page-popup"
				>
					<div className="login-box-popup col-sm-12 col-md-12 col-lg-12 col-xs-12">
						<i
							className="fa fa-times login-popup-cross-icon"
							onClick={() => this.closePopup()}
							aria-hidden="true"
						></i>
						<img
							src="/edvolve_full.png"
							alt="edvolve-full-logo"
							className="edvolve-full-logo"
							style={{ position: "relative", right: "26px" }}
						/>
						<h2
							className="mb-16 mt-16"
							style={{ color: "var(--fontColor-secondary)", fontSize: "16px", fontWeight: "500" }}
						>
							{this.state.popupText}
						</h2>
						<h3 className="mb-16" style={{ color: "inherit" }}>
							Signup or Login
						</h3>
						{this.state.showPhone ? (
							<EnterMobile mob_handler={this.mobHandler} />
						) : (
							<EnterOTP
								otp_handler={this.otpHandler}
								mob_number={this.state.mob}
								first_time_user={this.state.first_time_user}
							/>
						)}
					</div>
					<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 social-login-pop">
						<div className="mt-4">
							OR
						</div>
						<div className="row social-padding-top">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
								<a className="google-button" href={this.state.googleLoginURL}>
									<i className="fa fa-google"></i> Continue with Google
								</a>
							</div>
						</div>
						{/* <div className="row social-padding-top">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <a className="google-button" href={this.state.facebookLoginURL}>
                  <i className="fa fa-facebook"></i> Sign In/Sign Up with Facebook
                </a>
              </div>
            </div> */}
					</div>
				</div>
			</div>
		);
	}
}

export default LoginPopup;
