import React, { Component } from "react";
// import Title from "./Title";
import SnippetExp from "./Snippet";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { Redirect } from "react-router";

class UpcomingExperiences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snippets: [],
            subjects: [],
            filters: ["all"],
        };
    }

    componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.props.access_token);

        var url = `${process.env.REACT_APP_BASE_URL}/rest/students/experiences`;

        this.getFilters(myHeaders);
        this.getExperiences(url, myHeaders);
    }

    isItemSelected = (id) => !!this.state.filters.find((el) => el === id);

    render() {
        if (this.state.redirect) {
            return <Redirect to="/login" />;
        }

        return (
            <div className="row upcoming-webinars home-container">
                {this.state.subjects.length > 0 ? (
                    <ScrollMenu
                        LeftArrow={LeftArrow}
                        RightArrow={RightArrow}
                        alignCenter={false}
                        wrapperClassName="filter-scroll-menu-padding"
                    >
                        <Card
                            itemId="all"
                            title="All Topic"
                            key="all"
                            onClick={(e) => this.handleClick("all", e)}
                            selected={this.isItemSelected("all")}
                        />
                        {this.state.subjects.length ? (
                            this.state.subjects.map((subject) => (
                                <Card
                                    itemId={subject.id} // NOTE: itemId is required for track items
                                    title={subject.name}
                                    key={subject.id}
                                    onClick={(e) =>
                                        this.handleClick(subject.id, e)
                                    }
                                    selected={this.isItemSelected(subject.id)}
                                />
                            ))
                        ) : (
                            <div className="no-upcoming-webinar filter-error-message">
                                Uh oh. No filters found :(
                            </div>
                        )}
                    </ScrollMenu>
                ) : (
                    <div className="no-upcoming-webinar">
                        Uh oh. No filters found :(
                    </div>
                )}
                {/* <Title title="Top notch experiences for you" /> */}
                <hr className="filter-line" />
                {this.state.snippets.length ? (
                    this.state.snippets.map((snippet, index) => (
                        <SnippetExp type="upcoming" snippet={snippet} />
                    ))
                ) : (
                    <div className="no-upcoming-webinar">
                        Uh oh. No upcoming experiences found :(
                    </div>
                )}
            </div>
        );
    }

    getExperiences(url, myHeaders) {
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(url, requestOptions)
            .then(function (response) {
                let status = response.status;
                let json_response = response.json();
                if (status !== 200) {
                    if (status === 401) {
                        throw new Error(
                            "Your session has expired. Please login again."
                        );
                    } else {
                        throw new Error(
                            "Something went wrong. Please contact support."
                        );
                    }
                } else {
                    return json_response;
                }
            })
            .then(
                (result) => {
                    //console.log(result["data"]["results"]);
                    this.setState({
                        snippets: result["data"]["results"],
                    });
                },
                (error) => {
                    localStorage.removeItem("student_token");
                    localStorage.removeItem("student_uuid");
                    this.setState({
                        redirect: true,
                    });
                }
            );
    }

    getFilters(myHeaders) {
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_BASE_URL}/rest/students/subjects`,
            requestOptions
        )
            .then(function (response) {
                let status = response.status;
                let json_response = response.json();
                if (status !== 200) {
                    if (status === 401) {
                        throw new Error(
                            "Your session has expired. Please login again."
                        );
                    } else {
                        throw new Error(
                            "Something went wrong. Please contact support."
                        );
                    }
                } else {
                    return json_response;
                }
            })
            .then(
                (result) => {
                    //console.log(result["data"]["results"]);
                    this.setState({
                        subjects: result["data"]["results"],
                    });
                },
                (error) => {
                    localStorage.removeItem("student_token");
                    localStorage.removeItem("student_uuid");
                    this.setState({
                        redirect: true,
                    });
                }
            );
    }

    handleClick(id, e) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.props.access_token);

        var url;
        if (id === "all") {
            this.setState({
                filters: ["all"],
            });
            url = `${process.env.REACT_APP_BASE_URL}/rest/students/experiences`;
            this.getExperiences(url, myHeaders);
        } else {
            var filter = [];
            var stateFilter = this.state.filters;

            if (stateFilter.includes("all")) {
                for (var j = 0; j < stateFilter.length; j++) {
                    if ("all" === stateFilter[j]) {
                        stateFilter.splice(j, 1);
                    }
                }
            }

            if (stateFilter.includes(id)) {
                for (var i = 0; i < stateFilter.length; i++) {
                    if (id === stateFilter[i]) {
                        console.log("Items", stateFilter[i]);
                        stateFilter.splice(i, 1);
                    }
                }
            } else {
                filter.push(id);
            }

            if (stateFilter.length > 0) {
                for (var index = 0; index < stateFilter.length; index++) {
                    if (id !== stateFilter[index]) {
                        filter.push(stateFilter[index]);
                    }
                }
            }

            if (filter.length === 0) {
                filter.push("all");
            }

            this.setState(
                {
                    filters: filter,
                },
                () => {
                    url = `${process.env.REACT_APP_BASE_URL}/rest/students/experiences?filter=[${this.state.filters}]`;
                    this.getExperiences(url, myHeaders);
                }
            );
        }
    }
}

// Left Arrow for filter
function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);

    return (
        <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <i className="fa fa-angle-left fa-lg"></i>
        </Arrow>
    );
}

// Right Arrow for filter
function RightArrow() {
    const { isLastItemVisible, scrollNext } =
        React.useContext(VisibilityContext);

    return (
        <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <i className="fa fa-angle-right fa-lg"></i>
        </Arrow>
    );
}

// Arrow for filter
function Arrow({ children, disabled, onClick }) {
    return (
        <button disabled={disabled} onClick={onClick} className="arrow-button">
            {children}
        </button>
    );
}

// Card for the filter
function Card({ onClick, selected, title, itemId }) {
    const visibility = React.useContext(VisibilityContext);

    return (
        <div
            onClick={() => onClick(visibility)}
            style={{
                width: "max-content",
            }}
            tabIndex={0}
        >
            <div
                className={
                    JSON.stringify(!!selected) === "true"
                        ? "filter-card" +
                          (title.length < 9
                              ? " filter-card-active"
                              : title.length < 16
                              ? " filter-card-active-big"
                              : " filter-card-active-supreme")
                        : "filter-card"
                }
            >
                <span>{title}</span>
            </div>
        </div>
    );
}

export default UpcomingExperiences;
