import React, { Component } from "react";
// import SnippetCourse from "../CourseHome/Snippet";
import NewSnippetCourse from "../CourseHome/NewSnippet";
import SnippetExperience from "../ExperienceHome/NewSnippet";
// import SnippetExperience from "../ExperienceHome/Snippet";
import { Modal, Dropdown } from "react-bootstrap";
import { Redirect } from "react-router";
import { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import "react-loading-skeleton/dist/skeleton.css";
import css from "./UpcomingLearn.module.css";
import LazyLoad from "../Commons/LazyLoad";
class UpcomingLearn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			snippets: [],
			subjects: [],
			filters: [],
			showModal: false,
			loading: true,
			currPage: 0,
			totalPage: 1,
			classType: "",
		};
	}

	componentDidMount() {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		var url = `${process.env.REACT_APP_BASE_URL}/rest/students/learn`;

		this.getFilters(myHeaders);
		this.getLearnContent(url, myHeaders);
	}

	// isItemSelected = (id) => !!this.state.filters.find((el) => el === id);

	getLearnContent(url, myHeaders) {
		this.setState({
			loading: true,
		});

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(url, requestOptions)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then(
				(result) => {
					this.setState({
						snippets: result["data"]["results"],
						loading: false,
						totalPage: result["data"]["metadata"]["total_pages"],
					});
				},
				(error) => {
					localStorage.removeItem("student_token");
					localStorage.removeItem("student_uuid");
					this.setState({
						redirect: true,
						loading: false,
					});
				}
			);
	}

	getFilters(myHeaders) {
		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(
			`${process.env.REACT_APP_BASE_URL}/rest/students/subjects`,
			requestOptions
		)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then(
				(result) => {
					this.setState({
						subjects: result["data"]["results"],
					});
				},
				(error) => {
					localStorage.removeItem("student_token");
					localStorage.removeItem("student_uuid");
					this.setState({
						redirect: true,
					});
				}
			);
	}

	search() {
		const myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);
		const url = `${process.env.REACT_APP_BASE_URL}/rest/students/learn?type=${
			this.state.classType
		}&filter=[${this.state.filters.join(",")}]`;
		this.getLearnContent(url, myHeaders);
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/login" />;
		}

		const handleShowModal = () => {
			this.setState({ showModal: true });
		};

		const handleHideModal = () => {
			this.setState({ showModal: false });
		};

		const FilterModal = () => {
			const cardOuterClassName = "col m-2";
			return (
				<Modal
					show={this.state.showModal}
					centered
					onHide={handleHideModal}
					animation={false}
				>
					<Modal.Dialog className="filter-Modal p-3 m-0 dark-bg-sec">
						<Modal.Header className="dark-bg-sec d-flex justify-content-between">
							<Modal.Title>Select topics</Modal.Title>
							<i
								className="fa fa-times fs-4"
								style={{ cursor: "pointer" }}
								aria-hidden="true"
								onClick={handleHideModal}
							></i>
						</Modal.Header>
						<Modal.Body className="dark-bg-sec row">
							<div style={{ height: "60px" }}>
								<Card
									fullWidth
									itemId="all"
									noSelectedFilters={true}
									isSelected={!this.state.filters.length}
									title="All Subjects"
									key="all"
									onClick={() => this.setState({ filters: [], currPage: 0 })}
									// selected={this.isItemSelected("all")}
									outerClassName={cardOuterClassName}
								/>
							</div>
							{/* {console.log(this.state.subjects)} */}
							{this.state.subjects.length ? (
								this.state.subjects
									.sort((a, b) => (a.name > b.name ? 1 : -1))
									.map((subject) => (
										<Card
											itemId={subject.id} // NOTE: itemId is required for track items
											title={subject.name}
											key={subject.id}
											image={subject.image_url}
											isSelected={this.state.filters.includes(subject.id)}
											onClick={(selectedFilter) => {
												// this.handleClick(subject.id, e)
												let filters = [...this.state.filters];
												if (selectedFilter) {
													filters.push(subject.id);
												} else {
													filters = filters.filter((id) => id !== subject.id);
												}
												this.setState({ filters: filters, currPage: 0 });
											}}
											outerClassName={cardOuterClassName}
										/>
									))
							) : (
								<div className="no-upcoming-webinar filter-error-message">
									Uh oh. No filters found :(
								</div>
							)}
							<button
								className="w-25 mb-3 py-2 position-fixed bottom-0 start-50 translate-middle-x"
								onClick={() => {
									this.search();
									handleHideModal();
								}}
							>
								<span
									className="fw-bolder"
									style={{
										color: "white",
									}}
								>
									Search
								</span>
							</button>
						</Modal.Body>
					</Modal.Dialog>
				</Modal>
			);
		};

		const CourseSkeleton = () => {
			return (
				<div className="course-skeleton">
					<SkeletonTheme baseColor="gray" highlightColor="white">
						<div>
							<Skeleton height={200} />
						</div>
						<div className="course-skeleton-content">
							<h1>
								<Skeleton width="95%" />
							</h1>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "1rem",
								}}
							>
								<Skeleton inline circle height={40} width={40} />
								<Skeleton inline height={24} width={150} />
							</div>
							<p>
								<Skeleton
									inline
									count={2}
									height={20}
									width={100}
									style={{ marginRight: "10px" }}
								/>
							</p>
						</div>
					</SkeletonTheme>
				</div>
			);
		};

		const handlePageChange = (event) => {
			this.setState({
				snippets: [],
				currPage: event.selected,
			});
			const myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + this.props.access_token);

			window.scrollTo(0, 0);

			this.getLearnContent(
				`${process.env.REACT_APP_BASE_URL}/rest/students/learn?type=${
					this.state.classType
				}&filter=[${this.state.filters.join(",")}]&page=${+event.selected + 1}`,
				myHeaders
			);
		};

		const PaginatedCourses = () => {
			return (
				<>
					{this.state.loading && (
						<SkeletonTheme baseColor="gray" highlightColor="white">
							<Skeleton
								containerClassName="no-upcoming-webinar"
								wrapper={CourseSkeleton}
								count={12}
							/>
						</SkeletonTheme>
					)}
					{!this.state.loading &&
						this.state.snippets &&
						this.state.snippets.map((snippet, index) => (
							<React.Fragment key={index}>
								{snippet.type === "experience" ? (
									<SnippetExperience type="upcoming" snippet={snippet} />
								) : (
									<NewSnippetCourse type="upcoming" snippet={snippet} />
								)}
							</React.Fragment>
						))}
					<div className={css.pagination}>
						<ReactPaginate
							previousLabel="<<"
							nextLabel=">>"
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							pageCount={this.state.totalPage}
							onPageChange={handlePageChange}
							containerClassName="pagination"
							activeClassName="active"
							forcePage={this.state.currPage}
						/>
					</div>
				</>
			);
		};

		const filterClasses = (_type) => {
			const type = _type === "All" ? "" : _type.toLowerCase();
			this.setState({ classType: type.toLowerCase() });

			const myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + this.props.access_token);

			const url = `${
				process.env.REACT_APP_BASE_URL
			}/rest/students/learn?type=${type}&filter=[${this.state.filters.join(
				","
			)}]`;

			this.getLearnContent(url, myHeaders);
		};

		const getclassClassType = (type) => {
			if (this.state.classType === "" && type === "All") {
				return css["is-active"];
			}

			if (this.state.classType === type.toLowerCase()) {
				return css["is-active"];
			}

			return "";
		};

		return (
			<div className="upcoming-webinars home-container rounded-2">
				<section
					style={{
						color: "var(--fontColor-primary)",
					}}
					className="d-flex justify-content-between align-items-center flex-wrap"
				>
					<h4 className="m-0 p-2">All classes</h4>
					{this.state.subjects.length > 0 ? (
						<span
							className="d-flex align-items-center flex-wrap"
							style={{
								gap: "0.5rem",
							}}
						>
							<Dropdown>
								<Dropdown.Toggle
									id="dropdown-basic"
									className={`px-3 py-2 fw-bold border-2 dark-border ${css["dropdown"]}`}
									style={{ background: "none", color: "inherit" }}
								>
									Class type
								</Dropdown.Toggle>
								<Dropdown.Menu className={css["dropdown-menu"]}>
									{["All", "Course", "Workshop"].map((type, index) => (
										<Dropdown.Item
											key={index}
											as="button"
											className={getclassClassType(type)}
											onClick={() => filterClasses(type)}
										>
											{type}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
							<button
								className="px-3 py-2 fw-bold border-2 dark-border"
								style={{ background: "none" }}
								onClick={handleShowModal}
							>
								Subject Filters
							</button>
						</span>
					) : (
						<SkeletonTheme baseColor="gray" highlightColor="white">
							<Skeleton
								width={144}
								height={44}
								style={{
									margin: "0.5rem 0 1rem 0.5rem",
									borderRadius: "50rem",
								}}
							/>
						</SkeletonTheme>
					)}
				</section>
				<div className="row">
					{(!this.state.loading && this.state.snippets.length === 0 && (
						<div className="no-upcoming-webinar mt-5">
							<LazyLoad src="/icons/calendar.png" alt="No classes found." />
							<p className="text-center">
								Uh oh. No classes found for the selected filter.
							</p>
						</div>
					)) || <PaginatedCourses />}
				</div>
				<FilterModal />
			</div>
		);
	}
}

// Card for the filter
function Card({
	onClick,
	noSelectedFilters,
	isSelected,
	title,
	itemId,
	outerClassName,
	image,
	fullWidth,
}) {
	const [selected, setSelected] = useState(isSelected);

	return (
		<div className="col-12 col-md-6" style={{ overflow: "hidden" }}>
			<div
				onClick={() => {
					onClick(!selected);

					setSelected(!selected);
				}}
				style={{
					...(!fullWidth
						? {
								display: "flex",
								justifyContent: "center",
								backgroundColor:
									selected || (noSelectedFilters && itemId === "all")
										? "var(--background_tertiary)"
										: "var(--background_primary)",
								borderRadius: "10px",
								border:
									selected || (noSelectedFilters && itemId === "all")
										? "1px solid var(--border)"
										: "none",
						  }
						: {}),
				}}
				tabIndex={0}
				className={outerClassName}
			>
				<div
					className={
						JSON.stringify(!!selected) === "true" && fullWidth
							? "filter-card" +
							  (title.length < 9
									? " filter-card-active"
									: title.length < 16
									? " filter-card-active-big"
									: " filter-card-active-supreme")
							: "filter-card"
					}
					style={{
						position: "relative",
					}}
				>
					{image && (
						<div
							style={{
								height: "100px",
								width: "100%",
								backgroundColor: "#555",
								borderRadius: "10px",
								overflow: "hidden",
							}}
						>
							<LazyLoad
								src={image}
								alt={title}
								style={{
									height: "100px",
									width: "160px",
									objectFit: "cover",
								}}
							/>
						</div>
					)}
					<div
						style={{
							textAlign: "center",
							marginTop: !fullWidth ? "12px" : 0,
							...(JSON.stringify(!!selected) === "true" && !fullWidth
								? { fontWeight: "bold" }
								: undefined),
						}}
					>
						{title}
					</div>
				</div>
			</div>
		</div>
	);
}

export default UpcomingLearn;
