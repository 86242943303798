import React, { Component } from 'react';

class ShowCourseRecording extends Component {

	constructor(props) {
		super(props);
		this.state = {
			url: ""
		};
	}

	componentDidMount() {

		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/courses/batch/classes/${this.props.match.params.class_id}/recording`;

		fetch(url, requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({
						url: result["data"].recording
					});
				},
				(error) => { }
			);
	}

	render() {
		return (
			<div className="webinar-iframe-container">
				<iframe className="webinar-iframe" src={this.state.url} title="View Webinar" frameBorder="0" width="100%" height="100vh" scrolling="auto" allowFullScreen></iframe>
			</div>
		);
	}
}

export default ShowCourseRecording;
