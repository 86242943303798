import React, { Component } from "react";

class Banner extends Component {
    render() {
        //if (this.props.access_token===null) {
        // return <Redirect to='/login'/>;
        //}
        return (
            <div className="background-banner-listings">
                {/* <img src="/images/webinar_bg.png" alt="" /> */}
                <div className="col-12 col-md-10 col-lg-7 text-in-listings-banner">
                    <h2>Free sessions, events & career talks from experts</h2>
                    <p>
                        Engage with your favourite mentors & leaders on cool
                        topics. Discover new ideas, plan your career better!
                    </p>
                </div>
            </div>
        );
    }
}

export default Banner;
