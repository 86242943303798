import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { Form } from "react-bootstrap";

const DarkModeToggleSwitch = () => {
    const { isDark, toggleTheme } = useContext(ThemeContext);

    const getIcon = () => {
        return isDark ? "🌙" : "🌞";
    };

    return (
        <div className="dark-mode-toggle px-3">
            <Form.Check
                type="switch"
                id="custom-switch"
                label={getIcon()}
                onChange={toggleTheme}
                checked={isDark}
            />
        </div>
    );
};

export default DarkModeToggleSwitch;