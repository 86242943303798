import React, { Component } from "react";
import { ThemeContext } from "../../context/ThemeContext";

// import {
//   Link
// } from "react-router-dom";

// var dateConverter = require('../utils.js').dateConverter;
import { subjectString, getGradeText } from "../utils.js";

class SingleWebinarInfo extends Component {
	static contextType = ThemeContext;

	render() {
		const light = !this.context.isDark;

		// let mentor_url = "/mentor/" + this.props.mentor.id;
		// let duration = (this.props.end_time - this.props.start_time)/60;
		// let full_date = dateConverter(this.props.start_time);
		// let featured_image = "/default_webinar_img.png";
		// if(this.props.featured_image !== null){
		//     featured_image = this.props.featured_image;
		// }

		// let subject_string = subjectString(this.props.subjects);
		// let grade_text = getGradeText(this.props.grades, true);

		// let attend_url = "/webinar/" + this.props.webinar_id + "/attend";
		// let watch_url = "/webinar/" + this.props.webinar_id + "/recording";

		return (
			<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 webinar-info-container m-0">
				<div
					className="row other-webinar-info"
					style={
						!light
							? {
								boxShadow: "rgb(136 136 136 / 30%) 0px 2px 10px",
								borderRadius: "30px",
								background: "var(--background_primary)",
							}
							: {
								boxShadow: "#d9d9d9 0px 2px 10px",
								borderRadius: "30px",
								background: "var(--background_primary)",
							}
					}
				>
					<div className="custom-25-percent col-xs-6 mb-3">
						<h5>Duration</h5>
						<p>{this.props.duration} minutes</p>
					</div>
					<div className="custom-25-percent col-xs-6">
						<h5>Language</h5>
						<p>English</p>
					</div>
					{/* <div className="custom-25-percent col-xs-6">
						<h5>Suitable for</h5>
						<p>{grade_text}</p>
					</div> */}
					{/* <div className="custom-25-percent col-xs-6">
						<h5>Subjects</h5>
						<p>{subject_string}</p>
					</div> */}
				</div>
			</div>
		);
	}
}

export default SingleWebinarInfo;
