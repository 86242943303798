import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

const DarkMode = () => {
	const { isDark, toggleTheme } = useContext(ThemeContext);

	const getIcon = () => {
		return isDark ? "🌞" : "🌙";
	};

	return (
		<div className="dark-mode-toggle">
			<button onClick={toggleTheme}>
				<span>{getIcon()}</span>
			</button>
		</div>
	);
};

export default DarkMode;
