import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';


const ImageSlider = (props) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? props.images.length - 1 : prevIndex - 1
        );
    };

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === props.images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', position: "relative" }}>
            <SwipeableViews index={currentImageIndex} onChangeIndex={setCurrentImageIndex}>
                {props.images.map((image, index) => (
                    <div key={index} style={{ height: '100%', display: "flex", alignItems: "center" }}>
                        <img
                            src={image}
                            alt={props.actorName}
                            style={{ width: '100%' }}
                        />
                    </div>
                ))}
            </SwipeableViews>
            {
                props.images.length > 1 &&
                <>
                    <img
                        src='/icons/arrow.png'
                        alt='arrow-left'
                        onClick={prevImage}
                        style={{
                            cursor: 'pointer',
                            position: "absolute",
                            left: 0,
                            background: "#fff",
                            borderRadius: "50%",
                            width: "25px",
                            height: "25px",
                            transform: "rotate(180deg)",
                            objectFit: "contain"
                        }}
                    />
                    <img
                        src='/icons/arrow.png'
                        alt='arrow-left'
                        onClick={nextImage}
                        style={{
                            cursor: 'pointer',
                            position: "absolute",
                            right: 0,
                            background: "#fff",
                            borderRadius: "50%",
                            width: "25px",
                            height: "25px",
                            objectFit: "contain"
                        }}
                    />
                </>
            }



        </div>
    );
}

export default ImageSlider;

