import ClassesCard from "./ClassesCard";
import { useState, useEffect } from 'react'
import { Row, Col } from "react-bootstrap";


const Classes = (props) => {

    const [classes, setClasses] = useState([]);

    useEffect(() => {
        (async function () {
            let url = `${process.env.REACT_APP_BASE_URL}/rest/students/courses?world_id=${props.match.params.id}`;
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + props.access_token);

            let requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            }
            try {
                const response = await fetch(url, requestOptions);
                const data = await response.json();
                setClasses(data.data.results);
            } catch (error) {
                console.error("Classes error : ", error);
            }
        })();
    }, [])

    return (
        <div className="mb-4 gopher-font">
            <h2 className="d-none d-md-block mb-4">Classes</h2>
            {
                classes.length === 0 ?
                    <h3 className="m-2 mx-3" style={{ color: "#56ccf2" }}>No Upcoming Classes</h3> :
                    <Row>
                        {
                            classes.map((classItem) => (
                                <Col md={6} key={classItem?.id}>
                                    <ClassesCard
                                        data={classItem}
                                        history={props.history}
                                        dark={props.dark}
                                        sneakPeek={props.sneakPeek}
                                        student={props.student}
                                        access_token={props.access_token}
                                        worldId={props.match.params.id}
                                        courseId={classItem?.id}
                                    />
                                </Col>
                            ))
                        }
                    </Row>
            }
        </div>
    );
};

export default Classes;
