import React, { Component } from "react";
import MentorsListItem from "./MentorsListItem";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

class Mentors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mentors: [],
            loading: true,
        };
    }

    componentDidMount() {
        let url = `${process.env.REACT_APP_BASE_URL}/rest/students/mentors`
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.props.access_token);

        let requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(url, requestOptions)
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        mentors: result.data.results.slice(0, 4),
                        loading: false,
                    });
                }
            ).catch((err) => {
                console.log(err);
            });

    }

    render() {
        const MentorSkeletor = () => {
            return (
                <div className="w-100">
                    <SkeletonTheme baseColor="gray" highlightColor="white">
                        <div
                            className="d-flex flex-column"
                            style={{ marginTop: "-20px" }}
                        >
                            <div
                                className="d-flex align-items-center mb-1 py-2 px-2 mb-3"
                                style={{
                                    boxShadow: "var(--shadow_primary)",
                                    borderRadius: "30px"
                                }}
                            >
                                <Skeleton inline circle height={70} width={70} />
                                <p className="m-0 ms-3 me-3 mt-2">
                                    <Skeleton inline height={20} width={150} /> <br />
                                    <Skeleton inline height={10} width={50} />
                                </p>

                            </div>

                            <div
                                className="d-flex align-items-center mb-1 py-2 px-2 mb-3"
                                style={{
                                    boxShadow: "var(--shadow_primary)",
                                    borderRadius: "30px"
                                }}
                            >
                                <Skeleton inline circle height={70} width={70} />
                                <p className="m-0 ms-3 me-3 mt-2">
                                    <Skeleton inline height={20} width={150} /> <br />
                                    <Skeleton inline height={10} width={50} />

                                </p>

                            </div>

                            <div
                                className="d-flex align-items-center mb-1 py-2 px-2 mb-3"
                                style={{
                                    boxShadow: "var(--shadow_primary)",
                                    borderRadius: "30px"
                                }}

                            >
                                <Skeleton inline circle height={70} width={70} />
                                <p className="m-0 ms-3 me-3 mt-2">
                                    <Skeleton inline height={20} width={150} /> <br />
                                    <Skeleton inline height={10} width={50} />

                                </p>

                            </div>

                            <div
                                className="d-flex align-items-center mb-1 py-2 px-2 mb-3"
                                style={{
                                    boxShadow: "var(--shadow_primary)",
                                    borderRadius: "30px"
                                }}
                            >
                                <Skeleton inline circle height={70} width={70} />
                                <p className="m-0 ms-3 me-3 mt-2">
                                    <Skeleton inline height={20} width={150} /> <br />
                                    <Skeleton inline height={10} width={50} />

                                </p>
                            </div>
                        </div>
                    </SkeletonTheme>
                </div>
            )
        }

        return (
            <div className="col-12 col-lg-4 dashboard-cards-wrapper">
                <div
                    style={{
                        background: "var(--background_primary)",
                        padding: "15px 10px",
                        borderRadius: "30px"
                    }}
                >
                    <h4
                        style={{
                            marginBottom: "18px",
                            textAlign: "center",
                            marginTop: window.innerWidth < 768 ? "30px" : "0px",
                            fontSize: "18px"
                        }}
                    >
                        New Mentors on HelloWorlds
                    </h4>

                    <div className="mentors d-flex flex-column" >
                        {
                            this.state.loading ? (
                                <SkeletonTheme baseColor="gray" highlightColor="white">
                                    <Skeleton
                                        containerClassName="no-upcoming-webinar"
                                        wrapper={MentorSkeletor}
                                        count={1}
                                    />
                                </SkeletonTheme>
                            ) :
                                this.state.mentors.map((mentor) => {
                                    return (
                                        <MentorsListItem
                                            mentor={mentor}
                                            key={mentor.id}
                                            dark={this.props.dark}
                                        />
                                    )
                                })
                        }
                    </div>

                    <div className="d-flex justify-content-center">
                        <h6
                            className="text-center fw-bold"
                            style={{
                                cursor: "pointer",
                                boxShadow: "var(--shadow_primary)",
                                width: "fit-content",
                                borderRadius: "30px",
                                padding: "10px 20px",
                            }}
                            onClick={() => {
                                this.props.history.push("./mentors")
                            }}
                        >
                            View All Mentors
                        </h6>
                    </div>

                </div>
            </div>
        );
    }
}

export default Mentors;