import React from 'react';
import ReactDOM from "react-dom";
import Header from "../../Header";
import MobileBottonNav from "../../MobileBottonNav";
import Footer from "../../Footer";
import { Row, Col, Container } from "react-bootstrap";
import styles from "./Activity.module.css";
import LazyLoad from "../../Commons/LazyLoad";
import { Link } from "react-router-dom";
import Feed from "./Feed";
import Calendar from "../Calendar/";
import Library from "../Library/";
import Rules from "../Rules";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RWebShare } from "react-web-share";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import swal from "sweetalert";
import MentorModal from './MentorModal';
import JoindedClubs from './JoindedClubs';
import FreeTrialTimer from './FreeTrialTimer';
import MembersMobileSidebar from './MembersMobileSidebar';
import Classes from '../Classes';
import SingleClass from '../Classes/SingleClass';

const Activity = (props) => {
	const [mob, setMob] = useState(false);
	const [menu, setMenu] = useState(false);
	const [membersMenu, setMembersMenu] = useState(false);
	const location = useLocation();
	const [currentPath, setCurrentPath] = useState("home")
	const worldId = props.match.params.id;
	const courseId = props.match.params.course_id;
	const student_id = localStorage.getItem("student_uuid")
	const [worlds, setWorlds] = useState([]);
	const [worldMembers, setWorldMembers] = useState([]);
	const [showMetorModal, setShowMetorModal] = useState(false);
	const [joinedClubs, setJoinedClubs] = useState([]);
	const [sneakPeek, setSneakPeek] = useState(false);
	const [freeTrialTimer, setFreeTrialTimer] = useState(false);
	const [member, setMember] = useState([]);
	const [classes, setClasses] = useState([]);

	const [showProfileSidebar, setShowProfileSidebar] = useState(false);
	const [profileSidebarData, setProfileSidebarData] = useState(null);

	const [feedLenght, setFeedLenght] = useState(0);

	const handleOpenProfileSidebar = (data) => {
		setProfileSidebarData(data);
		setShowProfileSidebar(true);
	};

	const handleCloseProfileSidebar = () => {
		setProfileSidebarData(null);
		setShowProfileSidebar(false);
	};

	// JoyRide Starts
	const [stepIndex, setStepIndex] = useState(0);
	const [run, setRun] = useState(false);
	const steps = [
		{
			target: '.step1',
			content: 'The activity feed! All posts from the mentor will show up here, you can comment and like.',
			disableBeacon: true
		},
		{
			target: '.step2',
			content: 'Heres where you can check all upcoming events of the club, and view recordings of past events.',
		},
		{
			target: '.step3',
			content: 'The group chat of this club! You can freely chat with mentor and your club mates over here.',
		},
		{
			target: '.step4',
			content: 'This is the place your mentor will be adding learning material, assignments, and other documents.',
		},
		{
			target: '.step5',
			content: 'Use this button to invite your friends to this club.',
		},
	];

	const handleJoyrideCallback = data => {
		const { action, index, status, type } = data;
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
		}
		else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			setRun(false);
			setMenu(false);
			setMembersMenu(false)
			WelcomePopup();
		}
	};
	// JoyRide Ends

	/**
	 * Handle navigation
	 * @param {Event} e
	 * @param {path} pathe to navigate
	 */
	const handleNavigate = (e, path) => {
		e.preventDefault();
		if (sneakPeek) return
		if (path === "home") {
			props.history.push(`/worlds/${worldId}/activity`);
		} else {
			props.history.push(`/worlds/${worldId}/activity/${path}`);
		}
	}

	// Fetch data
	useEffect(() => {
		const fetchData = async () => {
			try {
				let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/`;
				const requestOptions = {
					method: "GET",
					headers: new Headers({
						"Authorization": `Bearer ${props.access_token}`,
					}),
					redirect: "follow",
				};
				const [worldsData, membersData, bookingsData, classesData] = await Promise.all([
					fetch(`${url}${worldId}`, requestOptions).then(response => response.json()),
					fetch(`${url}${worldId}/members`, requestOptions).then(response => response.json()),
					fetch(`${url}bookings`, requestOptions).then(response => response.json()),
					fetch(`${process.env.REACT_APP_BASE_URL}/rest/students/courses?world_id=${props.match.params.id}`, requestOptions).then(response => response.json())
				]);
				document.title = `${worldsData.data.title} - Home | HelloWorlds`;
				setWorlds(worldsData.data);
				setWorldMembers(membersData.data.results);

				const memberExists = membersData.data.results.filter(member => member.student_id === student_id);
				const isMemberNow = memberExists.filter(member => member.sub_status === "ACTIVE");
				if (isMemberNow.length === 0) {
					setSneakPeek(true);
				} else {
					setMember(isMemberNow[0]);
				}

				const firstTime = bookingsData.data.results.filter(world => world.world.id.toString() === worldId);
				if (firstTime[0]?.first_visit === 1) {
					setMenu(true);
					setRun(true);
				} else {
					setRun(false);
				}
				setJoinedClubs(bookingsData.data.results);
				setClasses(classesData.data.results);
			} catch (error) {
				console.error(error);
			}
		};

		fetchData();
		setMenu(false);
		setMembersMenu(false);
	}, [worldId, props.match.params.id, props.access_token]);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 768) setMob(false);
			else setMob(true);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		setMenu(false);
		setMembersMenu(false);
		let pathArray = location.pathname.split("/");

		if (pathArray.includes("calendar")) {
			setCurrentPath("calendar");
			document.title = `${worlds?.title} - Calendar | HelloWorlds`;
		} else if (pathArray.includes("library")) {
			setCurrentPath("library");
			document.title = `${worlds?.title} - Library | HelloWorlds`;
		}
		else if (pathArray.includes("rules")) {
			setCurrentPath("rules");
			document.title = `${worlds?.title} - Rules/Guidelines | HelloWorlds`;
		}
		else if (pathArray.includes("courses")) {
			setCurrentPath("courses");
			document.title = `${worlds?.title} - courses | HelloWorlds`;
		}
		else {
			setCurrentPath("home");
			document.title = `${worlds?.title} - Home | HelloWorlds`;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		if (worlds.free_trial === 1 && member?.sub_status === "ACTIVE") {
			setFreeTrialTimer(true)
		}
	}, [worlds, member])

	const currentRoute = () => {
		const path = props.location.pathname.split("/");
		if (path[path.length - 1] === "activity") return "Activity Feed"
		if (path[path.length - 1] === "calendar") return "Rooms"
		if (path[path.length - 1] === "library") return "Library"
		if (path[path.length - 1] === "rules") return "Rules/Guidelines"
		return path[path.length - 1];
	};

	const toggleMenu = () => {
		setMenu(!menu);
	};

	const toggleMembersMenu = () => {
		setMembersMenu(!membersMenu);
	};

	const goToChat = (e) => {
		e.preventDefault();
		if (sneakPeek) return
		props.history.push(`/chats/`, { clubName: worlds.title });
	};

	const firstVisitDone = async () => {
		try {
			swal.close();
			let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/`;
			let myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + props.access_token);
			let requestOptions = {
				method: "POST",
				headers: myHeaders,
				redirect: "follow",
			};
			await fetch(`${url}${worldId}/first-visit-done`, requestOptions);
		} catch (error) {
			console.error(error);
		}
	}

	const WelcomePopup = () => {
		const welcome_popup = document.createElement("div");
		ReactDOM.render(
			<React.StrictMode>
				<WelcomePopupContent
					title={worlds?.title}
					image={worlds?.image}
				/>
			</React.StrictMode>,
			welcome_popup
		);
		swal({
			content: welcome_popup,
			buttons: false,
			className: "swal-payment-conf",
			closeOnClickOutside: false,
		});
	};

	const WelcomePopupContent = (props) => {
		return (
			<div
				style={{
					backgroundColor: "var(--background_primary)",
					color: "var(--fontColor_primary)",
					boxShadow: "var(--shadow_primary)",
					padding: "30px"
				}}
			>
				<img src="/welcome.webp" alt={props?.title} />
				<h3 className="mt-3">
					Hope you enjoyed the tour!
				</h3>
				<p className="p-2">
					If you have any queries, talk to your mentor or members of the group in Chats. If that doesn't work, connect with us at hello@helloworlds.co.
				</p>
				<button
					className="px-3 py-1 my-2 text-white"
					onClick={firstVisitDone}
					style={{ fontSize: "12px" }}
				>
					Okay
				</button>
			</div>
		);
	};

	return (
		<>
			{window.innerWidth > 600 ? (
				props.location.pathname !== `/worlds/${worldId}/activity/courses/${courseId}` && (
					<Header
						update_token={props.update_token}
						avatar_url={props.avatar_url}
						access_token={props.access_token}
					/>
				)

			) : (
				props.location.pathname !== `/worlds/${worldId}/activity/courses/${courseId}` && (
					<MobileBottonNav
						current_route={props.current_route}
						logged_in={!!props.access_token}
					/>
				)
			)}
			<Joyride
				run={run}
				steps={steps}
				stepIndex={stepIndex}
				callback={handleJoyrideCallback}
				continuous={true}
				showProgress={true}
				showSkipButton={true}
				disableScrolling={true}
				hideCloseButton={true}
				disableOverlayClose={true}
				styles={{
					tooltip: {
						backgroundColor: "var(--background_primary)",
						color: "var(--fontColor_primary)",
						boxShadow: "var(--shadow_primary)"
					},
					buttonNext: {
						color: '#fff',
						fontSize: "12px",
						borderRadius: "10px"
					},
					buttonBack: {
						color: "#fff",
						fontSize: "12px",
						borderRadius: "10px"
					},
					buttonSkip: {
						color: "#fff",
						fontSize: "12px",
						borderRadius: "10px"
					},
				}}
			/>
			<Container fluid className="m-0 p-0 gopher-font position-relative">
				<Row className="m-0" >
					{window.innerWidth < 768 && (
						props.location.pathname !== `/worlds/${worldId}/activity/courses/${courseId}` && (
							<div className={styles.hamburger}>
								<div className={styles.hamburger}>
									<button onClick={toggleMenu} className='no-hover'>
										{
											menu ? <i className="fa fa-times" aria-hidden="true"></i> : <i className="fa fa-bars" aria-hidden="true"></i>
										}
									</button>
									<div>
										<div className={styles.headerTop}>
											<p>
												{
													worlds?.title?.length > 25 ? worlds?.title?.slice(0, 25) + "..." : worlds?.title
												}
											</p>
											{
												currentRoute() === "Activity Feed" ? (
													<p>
														{feedLenght} posts
													</p>
												) : (
													<p>
														{currentRoute()}
													</p>
												)
											}
										</div>
									</div>
								</div>

								{
									worldMembers?.filter((member) => member?.sub_status === "ACTIVE")?.length > 0 && (
										<div className='d-flex align-items-center pe-3'>
											<button onClick={toggleMembersMenu} style={{ float: "right" }} className='no-hover'>
												<img
													src="/icons/userIcon.png"
													alt="helloworlds"
													style={
														props.dark ? {
															width: "32px",
															height: "32px",
															filter: "invert(1)"
														} : {
															width: "32px",
															height: "32px"
														}
													}
												/>
											</button>
										</div>
									)
								}
							</div>
						)

					)}
					<Col
						md={4}
						xs={12}
						className={`ps-5 py-2 ${window.innerWidth < 768 && styles["mob-nav"]} ${window.innerWidth < 768 && (menu ? "d-flex" : styles["mob-nav-hide"])
							} ${styles.nav}`}

					>

						<div
							className={styles["left-nav"]}
							style={
								props.dark
									? {
										boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
										background: "var(--background_primary)",
									}
									: {
										boxShadow: "#d9d9d9 0px 2px 10px",
										background: "var(--background_primary)",
									}
							}
						>
							{
								!sneakPeek ? (
									<div className={styles.joindedClubs}>
										<JoindedClubs clubs={joinedClubs} currentClub={worlds?.id} />
									</div>
								) : null
							}
							<div className={styles["left-nav-top"]}>
								<div className={styles.leftNavTopImg}>
									<LazyLoad
										src={worlds?.image}
										wrapper="w-100"
										classes={styles.banner}
										onClick={() => setShowMetorModal(true)}
										style={{ cursor: "pointer" }}
										title="Mentor details"
									/>
									<img
										src={
											!worlds.uuid?.user?.avatar_url ? "/mentor.png" : worlds.uuid?.user?.avatar_url
										}
										className={styles.mentorImage}
										onClick={() => setShowMetorModal(true)}
										title="Mentor details"
									/>
									{
										sneakPeek && (
											<p
												className="text-white"
												style={{ cursor: "pointer" }}
												onClick={() => props.history.push(`/worlds/${worldId}`)}
											>
												This is a preview. Subscribe to join.
											</p>
										)
									}
									{
										freeTrialTimer && (
											<FreeTrialTimer subscriptionDate={member?.active_since} freeTrialDays={worlds?.free_trial_days} />
										)
									}

								</div>
								<h4
									className="p-3 m-0 pb-0 fw-bold"
									onClick={() => setShowMetorModal(true)}
									style={{ cursor: "pointer" }}
									title="Mentor details"
								>
									{worlds?.title}
								</h4>
								<ul>
									<li className={currentPath === "home" ? styles.active + " step1" : "step1"}>
										<LazyLoad src="/icons/worlds/home.png" />
										<Link to={`/worlds/${worldId}/activity`}>
											Home
										</Link>
									</li>
									<li
										className={currentPath === "calendar" ? styles.active + " step2" : "step2"}
									>
										<LazyLoad src="/icons/worlds/calendar.png" />
										<Link to={`/worlds/${worldId}/activity/calendar`}>
											Rooms
										</Link>
									</li>
									<li className="step3">
										<LazyLoad src="/icons/worlds/chats.png" />
										<a
											onClick={goToChat}
											style={{ textTransform: "capitalize", fontWeight: 400 }}
											className={sneakPeek ? styles.disabled : ""}
										>
											Chats
										</a>
									</li>
									<li
										className={currentPath === "library" ? styles.active + " step4" : "step4"}
									>
										<LazyLoad src="/icons/worlds/library.png" />
										<Link
											to={`/worlds/${worldId}/activity/library`}
											onClick={(e) => handleNavigate(e, "library")}
											className={sneakPeek ? styles.disabled : ""}
										>
											Library
										</Link>
									</li>
									<li className={currentPath === "rules" ? styles.active : ""}>
										<LazyLoad src="/icons/worlds/rules.png" />
										<Link to={`/worlds/${worldId}/activity/rules`}>
											Rules/Guidelines
										</Link>
									</li>
									{
										classes?.length ? (
											<li className={currentPath === "courses" ? styles.active : ""}>
												<LazyLoad src="/icons/worlds/rules.png" />
												<Link to={`/worlds/${worldId}/activity/courses`}>
													Classes
												</Link>
											</li>
										) : null
									}
									<li className="step5">
										{
											sneakPeek ? (
												<div>
													<LazyLoad
														src="/icons/worlds/invite.png"
														classes="me-2"
													/>
													<button
														style={{ textTransform: "capitalize" }}
														className={sneakPeek ? styles.disabled + " ms-1" : "ms-1"}
													>
														Invite Friends
													</button>
												</div>
											) : (
												<RWebShare
													data={{
														text:
															"Hey, Checkout this Club on HelloWorlds: " +
															worlds?.title,
														url: `https://app.helloworlds.co/worlds/${worlds?.id}`,
														title: worlds?.title,
													}}
												>
													<div>
														<LazyLoad
															src="/icons/worlds/invite.png"
															classes="me-2"
														/>
														<a
															onClick={(e) => e.preventDefault()}
															style={{ textTransform: "capitalize" }}
															className={sneakPeek ? styles.disabled + " ms-1" : "ms-1"}
														>
															Invite Friends
														</a>
													</div>
												</RWebShare>
											)
										}
									</li>
									<li>
										<LazyLoad src="/icons/worlds/back.png" />
										<Link to="/timeline">Back</Link>
									</li>
								</ul>
							</div>
						</div>
					</Col>

					<Col
						md={7}
						xs={12}
						className={`{styles["activity-content"]} pt-4`}
					>
						{(props.location.pathname === `/worlds/${worldId}/activity` ||
							props.location.pathname === `/worlds/${worldId}/activity/`) && (
								<Feed
									{...props}
									worlds={worlds}
									student={props.student}
									worldMembers={worldMembers}
									dark={props.dark}
									sneakPeek={sneakPeek}
									handleOpenProfileSidebar={handleOpenProfileSidebar}
									showProfileSidebar={showProfileSidebar}
									handleCloseProfileSidebar={handleCloseProfileSidebar}
									profileSidebarData={profileSidebarData}
									setFeedLenght={setFeedLenght}
								/>
							)}
						{props.location.pathname ===
							`/worlds/${worldId}/activity/calendar` && <Calendar {...props} sneakPeek={sneakPeek} student={props.student} />}
						{props.location.pathname ===
							`/worlds/${worldId}/activity/library` && <Library {...props} />}
						{props.location.pathname === `/worlds/${worldId}/activity/rules` && (
							<Rules {...props} worlds={worlds} />
						)}
						{props.location.pathname === `/worlds/${worldId}/activity/courses` && (
							<Classes {...props} worlds={worlds} />
						)}
						{props.location.pathname === `/worlds/${worldId}/activity/courses/${courseId}` && (
							<SingleClass {...props} worlds={worlds} />
						)}
					</Col>
				</Row>

				<MentorModal
					show={showMetorModal}
					onHide={() => setShowMetorModal(false)}
					mentorData={worlds?.uuid?.user}
					history={props.history}
				/>

				<MembersMobileSidebar
					show={membersMenu}
					handleClose={toggleMembersMenu}
					dark={props.dark}
					worldMembers={worldMembers}
					handleOpenProfileSidebar={handleOpenProfileSidebar}
				/>

			</Container >
			{
				props.location.pathname !== `/worlds/${worldId}/activity/courses/${courseId}` && (
					<div className="mt-5">
						<Footer />
					</div>
				)
			}
		</>
	);
};

export default Activity;
