import css from "./Recommendations.module.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { capitalizeName } from "../utils";
import LazyLoad from "../Commons/LazyLoad";

const Recommendations = (props) => {
	const [recommendation, setRecommendation] = useState([]);
	useEffect(() => {
		const url = `${process.env.REACT_APP_BASE_URL}/rest/students/recommendation/worlds`;
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				const filteredData = data?.data?.results?.filter((item) => item?.id?.toString() !== props?.currentWorld?.toString())
				setRecommendation(filteredData);
			})
			.catch((error) => console.log(error));
	}, [props?.currentWorld]);

	return (
		<div className={css["carousel-container"]}>
			<Carousel
				additionalTransfrom={0}
				arrows
				autoPlaySpeed={3000}
				centerMode={false}
				className=""
				containerClass="container-exp-page"
				dotListClass=""
				draggable
				focusOnSelect={false}
				infinite={true}
				itemClass=""
				keyBoardControl
				minimumTouchDrag={80}
				renderButtonGroupOutside={false}
				renderDotsOutside={false}
				responsive={{
					desktop: {
						breakpoint: {
							max: 3000,
							min: 1140,
						},
						items: 3,
						partialVisibilityGutter: 40,
					},
					tablet: {
						breakpoint: {
							max: 1140,
							min: 700,
						},
						items: 2,
						partialVisibilityGutter: 30,
					},
					mobile: {
						breakpoint: {
							max: 700,
							min: 0,
						},
						items: 1,
						partialVisibilityGutter: 30,
					},
				}}
				showDots={false}
				sliderClass=""
				slidesToSlide={1}
				swipeable
			>
				{recommendation.map((item) => (
					<Link
						to={
							`/worlds/${item.id}`
						}
						key={item.id}
						style={{ textDecoration: "none" }}
					>
						<div className={css["card-container"]}>
							<LazyLoad
								src={item.image || "/public/default_webinar_img.png"}
								alt={item.title}
								classes={css["card-img"]}
							/>
							<section className={css.details}>
								<h5>{item.title}</h5>
								{/* <p>
									{item.tagline?.slice(0, 30)}
								</p> */}
								<p>
									{item.uuid.user.avatar_url && (
										<LazyLoad src={item.uuid.user.avatar_url} alt="author" />
									)}
									{capitalizeName(
										`${item.uuid.user?.first_name} ${item.uuid.user?.last_name || ""}`
									)}
								</p>
							</section>
						</div>
					</Link>
				))}
			</Carousel>
		</div>
	);
};

export default Recommendations;
