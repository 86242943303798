const palleteDark = () => {
	document.querySelector(":root").style.backgroundColor = "#1d1b29";
	document
		.querySelector(":root")
		.style.setProperty("--background_primary", "#1f1d2f");
	document
		.querySelector(":root")
		.style.setProperty("--background_secondary", "#242233");
	document
		.querySelector(":root")
		.style.setProperty("--background_tertiary", "#484b6a");
	document
		.querySelector(":root")
		.style.setProperty("--fontColor-primary", "#fefffe");
	document
		.querySelector(":root")
		.style.setProperty("--fontColor-secondary", "#8c8c92");
		document
		.querySelector(":root")
		.style.setProperty("--fontColor-eo", "#8c8c92");
	document
		.querySelector(":root")
		.style.setProperty("--shadow_primary", "rgba(136, 136, 136, 0.3) 0px 2px 20px");
	document
		.querySelector(":root")
		.style.setProperty("--shadow_secondary", "rgba(136, 136, 136, 0.3) 0px 1px 8px");
};

export default palleteDark;
