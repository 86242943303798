import React, { Component } from "react";
import LazyLoad from "./Commons/LazyLoad";

class Footer extends Component {
	render() {
		return (
			<footer style={{ position: "relative", zIndex: "999" }}>
				<div className="footer-head">
					<div className="container">
						<div className="row">
							<div className="col-12 col-lg-6 pt-0">
								<div className="row footer-padding">
									<div className="col-sm-12">
										<LazyLoad
											src="/edvolve_full.png"
											alt="HelloWorlds"
											classes="footer-logo-img"
											style={{
												position: "relative",
												left: "-23px",
											}}
										/>
									</div>
								</div>
								<div className="row footer-padding">
									<div className="col-sm-12">
										{/* <p className="footer-first-section-paragraph">
											We, at HelloWorlds, aim to complement school education with
											curricular and real world learning experiences, activities
											and mentoring. We connect teens directly with handpicked
											tutors, industry professionals and brands around the world
											to go deeper into subjects, apply classroom concepts,
											explore careers and interests in real life. Being an
											on-demand platform, you can create your child's learning
											journey the way it fits your child's schedule, interests
											and learning goals.
										</p> */}
										<p className="footer-first-section-paragraph text" style={{ color: "var(var(--fontColor-primary" }}>
											Hangout and learn with your favorite creators & educators. Be a part
											{
												window.innerWidth > 768 ? <br /> : ""
											} of amazing clubs and level up on your passion.
										</p>
									</div>
								</div>

							</div>
							<div className="col-12 col-lg-3 pt-lg-4">
								<div className="row">
									<div className="col-sm-12">
										<h3>Links</h3>
									</div>
								</div>
								<div className="row footer-links text" style={{ color: "var(var(--fontColor-primary" }}>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.helloworlds.co/refund-policy"
										className="text" style={{ color: "var(var(--fontColor-primary" }}
									>
										Refund Policy
									</a>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.helloworlds.co/for-educators"
										className="text" style={{ color: "var(var(--fontColor-primary" }}
									>
										Host your own World
									</a>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.helloworlds.co/terms-and-conditions"
										className="text" style={{ color: "var(var(--fontColor-primary" }}
									>
										Terms of Use
									</a>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.helloworlds.co/privacy-policy"
										className="text" style={{ color: "var(var(--fontColor-primary" }}
									>
										Privacy Policy
									</a>
								</div>
							</div>
							<div className="col-12 col-lg-3 pt-lg-4">
								<div className="row">
									<div className="col-sm-12">
										<h3>Contact Us</h3>
									</div>
								</div>
								<div className="row footer-links text" style={{ color: "var(var(--fontColor-primary" }}>
									<p className="mb-2">
										<i className="fa fa-envelope"></i> Email:{" "}
										<a href="mailto:hello@helloworlds.co" className="text" style={{ color: "var(var(--fontColor-primary" }}>hello@helloworlds.co</a>{" "}
									</p>
									<p className="text" style={{ color: "var(var(--fontColor-primary" }}>
										<i className="fa fa-phone"></i> Phone: +91-9043408421{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://api.whatsapp.com/send?phone=919043408421&text=Hello,%20I%20have%20a%20question%20about%20the%20classes."
										>
											<LazyLoad
												src="/icons/wa.png"
												alt="Social Media Icon"
												classes="footer-first-section-social-icon"
												className="rounded-circle ms-2"
												style={{ width: "25px", height: "25px", marginBottom: "5px" }}
											/>
										</a>
									</p>
								</div>
							</div>
						</div>
						<div className="row footer-padding py-md-2">
							<div className="col-sm-12 footer-first-section-social">
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.facebook.com/helloworldsco"
								>
									<LazyLoad
										src="/icons/facebook.png"
										alt="Social Media Icon"
										classes="footer-first-section-social-icon"
									/>
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://twitter.com/i/flow/login?redirect_after_login=%2Fhelloworldsco"
								>
									<LazyLoad
										src="/icons/twitter.png"
										alt="Social Media Icon"
										classes="footer-first-section-social-icon"
									/>
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.instagram.com/helloworldsco/"
								>
									<LazyLoad
										src="/icons/instagram.png"
										alt="Social Media Icon"
										classes="footer-first-section-social-icon"
									/>
								</a>
							</div>
						</div>
						<div className="row">
							<div className="py-2 pt-0">
								<p className="footer-first-section-copyright">
									© {new Date().getFullYear()} GlobalShaala Learning Pvt. Ltd.
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
