import React, { Component } from "react";
import { ThemeContext } from "../../context/ThemeContext";

class CourseClasses extends Component {
	static contextType = ThemeContext;
	render() {
		const dark = this.context.isDark;
		return (
			<div className="course-class-content">
				{this.props.classes.map((classes) => (
					<div
						className="course-class-card"
						style={dark ? {} : { backgroundColor: "transparent" }}
						key={classes.id}
					>
						<span className="course-classes-title">{classes.name}:</span>
						<span className="course-classes-description">
							{classes.description}
						</span>
					</div>
				))}
			</div>
		);
	}
}

export default CourseClasses;
