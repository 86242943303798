import React, { Component } from "react";

import Title from "./Title";
import ClubContent from "./ClubContent";

class SectionContent extends Component {
    render() {
        return (
            <div>
                {this.props.content === null ? null : (
                    <div className="gopher-font" style={{paddingTop:"40px"}}>
                        {this.props.title && (
                            <Title
                                title={this.props.title}
                                img={this.props.img}
                                url={this.props.url}
                            />
                        )}
                        <ClubContent content={this.props.content} />
                    </div>
                )}
            </div>
        );
    }
}

export default SectionContent;
