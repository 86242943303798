import LibraryCard from "./LibraryCard";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";


const Library = (props) => {
	const [library, setLibrary] = useState([]);

	const worldId = props.location.pathname.split("/")[2];
	const url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/${worldId}/library`;
	const access_token = props.access_token;

	let myHeaders = new Headers();
	myHeaders.append("Authorization", "Bearer " + access_token);

	let requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow",
	};


	const getLibrary = async () => {
		try {
			const response = await fetch(url, requestOptions);
			const data = await response.json();
			setLibrary(data.data.results);
		} catch (error) {
			console.error("library", error);
		}
	}

	useEffect(() => {
		getLibrary();
	}, []);
	return (
		<div className="gopher-font">
			<h2 className="d-none d-md-block">Library</h2>
			{
				library.length === 0 ? (
					<h3 className="my-3 text-info">No Resources Added</h3>
				) : (
					<Row>
						{library.map((item) => (
							<Col md={4} key={item.id}>
								<LibraryCard item={item} />
							</Col>
						))}
					</Row>
				)
			}

		</div>
	);
};

export default Library;
