import styles from "./AllMentors.module.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Header"
import Footer from "../Footer"
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { getAllMentors } from "../../services/slices/mentors";

const AllMentors = (props) => {
    const dispatch = useDispatch()
    const { data, loading } = useSelector(state => state.mentors)

    useEffect(() => {
        document.title = "Mentors | HelloWorlds";
        if (!data) dispatch(getAllMentors());
    }, [])

    /*
        Loading Skeleton for the mentors
    */
    const MentorSkeleton = () => {
        return (
            <div className="course-skeleton" style={{ background: "var(--background_primary)" }}>
                <SkeletonTheme baseColor="gray" highlightColor="white">
                    <div>
                        <Skeleton height={200} />
                    </div>
                    <div className="course-skeleton-content">
                        <h1>
                            <Skeleton width="95%" />
                        </h1>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <Skeleton inline circle height={40} width={40} />
                            <Skeleton inline height={24} width={150} />
                        </div>
                        <p>
                            <Skeleton
                                inline
                                count={2}
                                height={20}
                                width={100}
                                style={{ marginRight: "10px" }}
                            />
                        </p>
                    </div>
                </SkeletonTheme>
            </div>
        );
    };

    return (
        <>
            <Header
                avatar_url={props.avatar_url}
                access_token={props.access_token}
            />

            <Container className="video-bg-content">
                <h3 className={`$my-3 pb-5 pt-2 fs-1 text-center ${styles.animatedHeading}`}>Say Hello to your Mentors!</h3>
                {
                    loading ? (
                        <SkeletonTheme baseColor="gray" highlightColor="white">
                            <Skeleton
                                containerClassName="no-upcoming-webinar"
                                wrapper={MentorSkeleton}
                                count={12}
                            />
                        </SkeletonTheme>
                    ) : data && data.length === 0 ? (
                        <div style={{ height: "16vh" }}>
                            <h2 className="mb-4">No mentors found</h2>
                        </div>
                    ) : (
                        <Row>
                            {data?.map((mentor) => {
                                return (
                                    <Col xs={12} sm={6} md={4} key={mentor.id} className="mb-4 px-3">
                                        <div className={styles["image-flip"]}>
                                            <div className={`${styles.mainFlip} ${styles["flip-0"]}`}>
                                                <div className={styles.frontside}>
                                                    <div className={`card ${styles.Card}`}>
                                                        <div className={`card-body text-center`}>
                                                            <p>
                                                                <img
                                                                    className={`img-fluid`}
                                                                    src={!mentor.avatar_url ? "./images/teacher.png" : mentor?.avatar_url}
                                                                    alt={mentor?.first_name}
                                                                />
                                                            </p>
                                                            <h4 className={`card-title`}>
                                                                {`${mentor?.first_name} ${mentor?.last_name}`}
                                                            </h4>
                                                            <p className={`card-text text-secondary`}>
                                                                {mentor?.one_liner}
                                                            </p>
                                                            <p className={`card-text text-secondary`}>
                                                                {mentor?.city?.name}
                                                            </p>
                                                            <p className={`card-text text-secondary`}>
                                                                {mentor?.subjects[0]?.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.backside}>
                                                    <div className={`card ${styles.Card}`}>
                                                        <div className={`card-body text-center mt-4`}>
                                                            <h4 className={`card-title`}>{mentor.name}</h4>
                                                            <p className={`card-text text-secondary`}>
                                                                {mentor?.about_me.slice(0, 300)}...
                                                            </p>
                                                            <button className="mt-2">
                                                                <Link
                                                                    to={`/mentor/${mentor.slug}`}
                                                                    className={`fw-bold text-uppercase`}
                                                                    style={{ textDecoration: "none", color: "#fff" }}
                                                                >
                                                                    View Profile
                                                                </Link>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    )
                }

            </Container>
            <Footer />
        </>
    )
}

export default AllMentors