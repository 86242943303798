import css from "./RequestMentor.module.css";
import { Modal, Form } from "react-bootstrap";
import { useContext, useState, StrictMode } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import LoginPopup from "../Auth/LoginPopup";
import ReactDOM from "react-dom";
import swal from "sweetalert";

const RequestMentor = ({ type, id, token, update_token }) => {
	const light = useContext(ThemeContext).isDark;
	const [open, setOpen] = useState(false);
	const [day, setDay] = useState("anyday");
	const [slot, setSlot] = useState("8 am - 12 pm");
	const [submit, setSubmit] = useState(false);
	const [status, setStatus] = useState("Sending request...");

	const openModal = () => {
		let swal_popup = null;

		if (token) {
			setOpen(true);
		} else {
			swal_popup = document.createElement("div");
			ReactDOM.render(
				<StrictMode>
					<LoginPopup  from="mindpod" />
				</StrictMode>,
				swal_popup
			);
			swal({
				content: swal_popup,
				buttons: false,
				className: "swal-login",
				closeOnClickOutside: false,
			});
		}
	};

	const closeModal = () => {
		setDay("anyday");
		setSlot("8 am - 12 pm");
		setSubmit(false);
		setOpen(false);
		setStatus("Sending request...");
	};

	const changeDay = (event) => {
		setDay(event.target.value);
	};

	const changeSlot = (event) => {
		setSlot(event.target.value);
	};

	const handleSubmit = () => {
		const url = `${process.env.REACT_APP_BASE_URL}/rest/students/${type}/${id}/private`;

		let myHeaders = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		const body = JSON.stringify({
			availability: day,
			time_slot: slot,
		});

		let requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: body,
			redirect: "follow",
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((result) => setStatus(result.message))
			.catch((error) => setStatus(error.message));

		setSubmit(true);
	};

	return (
		<>
			<div
				className="request-class-box"
				style={light ? { backgroundColor: "transparent" } : {}}
			>
				<button
					onClick={openModal}
					style={{
						color: "inherit",
						background: "none",
					}}
					className="request-class-btn border-2 dark-border"
				>
					Request 1:1 Classes
				</button>
			</div>
			<Modal
				show={open}
				centered
				onHide={closeModal}
				contentClassName={css.content}
			>
				<div className={css.title}>
					<span>Request for private classes</span>
					<button
						style={{
							background: "none",
							borderRadius: "10px",
						}}
						onClick={closeModal}
					>
						<i className="fa fa-times" aria-hidden="true"></i>
					</button>
				</div>
				{!submit ? (
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Preferred day of the week</Form.Label>
							<Form.Select onChange={changeDay}>
								<option value="anyday">Any Day</option>
								<option value="weekdays">Weekdays</option>
								<option value="weekends">Weekends</option>
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Preferred time of the day</Form.Label>
							<Form.Select onChange={changeSlot}>
								<option value="8 am - 12 pm">8 AM - 12 PM</option>
								<option value="12 pm - 4 pm">12 PM - 4 PM</option>
								<option value="4 pm - 8 pm">4 PM - 8 PM</option>
							</Form.Select>
						</Form.Group>
					</Form>
				) : (
					<p className="px-2">{status}</p>
				)}
				<button
					className={`border-2 dark-border font-lg ${css.action}`}
					style={{
						background: "none",
					}}
					onClick={submit ? closeModal : handleSubmit}
				>
					{submit ? "Okay" : "Submit"}
				</button>
			</Modal>
		</>
	);
};

export default RequestMentor;
