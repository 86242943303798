import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Modal, Form } from "react-bootstrap"


// Generate a random unique string for file name
const generateRandomString = (length) => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};

const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
    return new Promise(async (resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        image.onload = () => {
            ctx.drawImage(
                image,
                croppedAreaPixels.x,
                croppedAreaPixels.y,
                croppedAreaPixels.width,
                croppedAreaPixels.height,
                0,
                0,
                croppedAreaPixels.width,
                croppedAreaPixels.height
            );

            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                const fileName = generateRandomString(10) + '.jpeg';
                const file = new File([blob], fileName, { type: 'image/jpeg' });
                resolve(file);
            }, 'image/jpeg');
        };

        image.onerror = () => {
            reject(new Error('Failed to load image'));
        };
    });
};

const styles = {
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        background: 'transparent',
    },
    modal: {
        background: 'var(--background_primary)',
        padding: 20,
        outline: 0,
        borderRadius: 4,
        width: window.innerWidth > 600 ? 600 : 320,
        height: window.innerWidth > 600 ? 500 : 320,
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        borderRadius: '30px',
    },
};

const ImageCropper = ({ handleSubmit }) => {
    const [open, setOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [error, setError] = useState(null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setImageSrc(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
        setError(null);
    };

    const handleFileChange = (event) => {
        try {
            const selectedFile = event.target.files[0];
            if (!selectedFile.type.match(/(jpg|jpeg|png)$/)) {
                throw new Error("Invalid image type. Only JPEG, PNG, and JPG images are allowed.");
            }

            setError(null);
            if (selectedFile) {
                const reader = new FileReader();
                reader.onload = () => {
                    setImageSrc(reader.result);
                    handleOpen();
                };
                reader.readAsDataURL(selectedFile);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleZoomChange = (event, zoomValue) => {
        setZoom(zoomValue);
    };

    const handleCropImage = async () => {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        if (croppedImage) {
            handleSubmit([croppedImage]);
        }
        handleClose();
    };

    return (
        <>
            <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFileChange}
            />
            {error && <p className="text-danger" sx={{ my: 2 }}>{error}</p>}
            <Modal show={open} style={{ marginTop: "5%" }}>
                <div style={styles.modal}>
                    <div className='d-flex justify-content-between'>
                        <h5 className='mb-0'>
                            Crop Image
                        </h5>
                        <img
                            src="/icons/cross.jpg"
                            alt="Hellow Worlds Cross"
                            style={{
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                                borderRadius: "50%"
                            }}
                            onClick={handleClose}
                        />
                    </div>
                    <div style={styles.container}>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={handleCropComplete}
                            showGrid={false}
                        />
                    </div>
                    <button onClick={handleCropImage} className='text-white' style={{ height: "50px" }}>
                        Submit
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default ImageCropper;