import React from "react";
import { Link } from "react-router-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import LazyLoad from "./Commons/LazyLoad";

// const getItems = () =>
//   Array(20)
//     .fill(0)
//     .map((_, ind) => ({ id: `element-${ind}` }));

function MobSliderHeader({ current_route, logged_in }) {
	let dashboard_class = "scroll-menu-header";
	let timeline_class = "scroll-menu-header";
	let exp_class = "scroll-menu-header";
	let webinar_class = "scroll-menu-header";
	let worlds_class = "scroll-menu-header";
	let inbox_class = "scroll-menu-header";
	// let course_class = "scroll-menu-header";

	if (current_route === "dashboard") {
		dashboard_class = "scroll-menu-header scroll-menu-header-underline";
	}
	else if (current_route === "timeline") {
		timeline_class = "scroll-menu-header scroll-menu-header-underline";
	}
	else if (current_route === "learn") {
		exp_class = "scroll-menu-header scroll-menu-header-underline";
	} else if (current_route === "webinars") {
		webinar_class = "scroll-menu-header scroll-menu-header-underline";
	} else if (current_route === "worlds") {
		worlds_class = "scroll-menu-header scroll-menu-header-underline";
	} else if (current_route == "chats") {
		inbox_class = "scroll-menu-header scroll-menu-header-underline";
	}

	// const [items, setItems] = React.useState(getItems);
	const [selected, setSelected] = React.useState([]);
	// const [position, setPosition] = React.useState(0);

	const isItemSelected = (id) => !!selected.find((el) => el === id);

	const handleClick =
		(id) =>
			({ getItemById, scrollToItem }) => {
				const itemSelected = isItemSelected(id);

				setSelected((currentSelected) =>
					itemSelected
						? currentSelected.filter((el) => el !== id)
						: currentSelected.concat(id)
				);
			};

	const css_width_first = {
		width: "150px",
	};

	const css_width_second = {
		width: "130px",
	};

	const css_width_third = {
		width: "150px",
	};

	const css_width_fourth = {
		width: "120px",
	};


	return (
		<div className="mobile-scroll-menu">
			<ScrollMenu style={{ padding: "0 0.8rem" }}>

				<Card
					itemId={1} // NOTE: itemId is required for track items
					title="HOME"
					img="/icons/home2.png"
					url="/timeline"
					css_class={timeline_class}
					key={1}
					onClick={handleClick(1)}
					selected={isItemSelected(1)}
					css_width={css_width_first}
				/>

				{logged_in && (
					<Card
						itemId={2} // NOTE: itemId is required for track items
						title="DASHBOARD"
						img="/icons/dashboard2.png"
						url="/dashboard"
						css_class={dashboard_class}
						key={2}
						onClick={handleClick(2)}
						selected={isItemSelected(2)}
						css_width={css_width_first}
					/>
				)}

				<Card
					itemId={3} // NOTE: itemId is required for track items
					title="WORLDS"
					img="/icons/global.png"
					url="/worlds"
					css_class={worlds_class}
					key={3}
					onClick={handleClick(5)}
					selected={isItemSelected(5)}
					css_width={css_width_fourth}
				/>

				{/* {logged_in && (
					<Card
						itemId={4} // NOTE: itemId is required for track items
						title="INBOX"
						img="/icons/mail.png"
						url="/chats"
						css_class={inbox_class}
						key={2}
						onClick={handleClick(2)}
						selected={isItemSelected(2)}
						css_width={css_width_first}
					/>
				)} */}

				{/* <Card
					itemId={4} // NOTE: itemId is required for track items
					title="MIND POD"
					img="/icons/plant.png"
					url="/learn"
					css_class={exp_class}
					key={4}
					onClick={handleClick(3)}
					selected={isItemSelected(3)}
					css_width={css_width_second}
				/> */}
				{/* <Card
					itemId={5} // NOTE: itemId is required for track items
					title="SOUND POD"
					img="/icons/flask.png"
					url="/rooms"
					css_class={webinar_class}
					key={5}
					onClick={handleClick(4)}
					selected={isItemSelected(4)}
					css_width={css_width_third}
				/> */}
				{/* <Card
	              itemId={4} // NOTE: itemId is required for track items
	              title="MIND POD"
	              img="/icons/plant_learn.png"
	              url="/courses"
	              css_class={course_class}
	              key={4}
	              onClick={handleClick(4)}
	              selected={isItemSelected(4)}
	              css_width={css_width_second}
	            /> */}
			</ScrollMenu>
		</div>
	);
}

function Card({
	onClick,
	selected,
	title,
	img,
	url,
	css_class,
	itemId,
	css_width,
}) {
	const visibility = React.useContext(VisibilityContext);

	return (
		<div onClick={() => onClick(visibility)} tabIndex={0}>
			<div className="card" style={{ border: "0" }}>
				<div className={css_class} style={{ ...css_width }}>
					<Link to={url}>
						<LazyLoad
							src={img}
							alt={title}
							classes="header-icon scroll-menu-header-icon"
						/>
						{title}
					</Link>
				</div>
			</div>
		</div>
	);
}

export default MobSliderHeader;
