import React, { Component } from "react";

class ClubContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFullContent: false,
			htmlError: false,
		};
	}

	render() {
		function createMarkup(html) {
			return { __html: html };
		}
		const handleShowFullContent = () => {
			this.setState({ showFullContent: !this.state.showFullContent });
		};
		const showButtons = (text) => {
			if (text.split(" ").length < 40) return false;
			return true;
		};

		return (
			<div className="webinar-content" style={{paddingBottom:"40px"}}>
				{!this.state.htmlError && this.props.content ? (
					<>
						<div
							dangerouslySetInnerHTML={createMarkup(this.props.content)}
							style={{fontSize: "17px"}}
							// className={`pe-4 rounded-bottom club-loadMoreContent${
							// 	!this.state.showFullContent && showButtons(this.props.content)
							// 		? "-hidden"
							// 		: ""
							// }`}
							className={`rounded-bottom club-loadMoreContent mt-4`}
							onError={() => this.setState({ htmlError: true })}
						></div>
						{/* {this.props?.content && showButtons(this.props.content) && (
							<div
								onClick={handleShowFullContent}
								className="showMoreLess-btn w-30 mt-2 fw-bold text-decoration-underline"
							>
								{!this.state.showFullContent ? "Read More" : "Read Less"}
								...
							</div>
						)} */}
					</>
				) : (
					<div>
						<h6 className="dark-font-pri text-decoration-underline">
							Some error in format of details provided by mentor, please contact
							mentor
						</h6>
						<div>{this.props.content}</div>
					</div>
				)}
			</div>
		);
	}
}

export default ClubContent;
