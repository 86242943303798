import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "./styleCourseHome.module.css";
import { ThemeContext } from "../../context/ThemeContext";
import { capitalizeName } from "../utils";
import LazyLoad from "../Commons/LazyLoad";

class SnippetCourse extends Component {
	static contextType = ThemeContext;

	render() {
		let light = !this.context.isDark;
		let snippet = this.props.snippet;
		// let duration = this.props.snippet.duration;
		let featured_image = "/default_webinar_img.png";
		if (snippet.featured_image !== null) {
			featured_image = snippet.featured_image;
		}
		let snippet_url = "/course/" + snippet.id;
		// let attend_url = "/course/" + snippet.id + "/attend";
		let city = "";
		if (this.props.snippet.mentor.city !== null) {
			city = this.props.snippet.mentor.city.name;
		}

		let class_snippet = "experience-snippet";

		if (this.props.class_set) {
			class_snippet = "exp-snippet-mentor";
		}

		return (
			<div className={class_snippet + " col-xs-12 col-sm-6 col-lg-4 py-3"}>
				<div
					className={styles.experience_snippet_inner}
					style={light ? { backgroundColor: "white" } : {}}
				>
					<div className={`position-relative ${styles.snippet_image}`}>
						<Link to={snippet_url}>
							<div
								className={styles.snippet_img_snippets}
								style={{ height: "250px", maxHeight: "250px" }}
							>
								<LazyLoad
									src={featured_image}
									style={{
										height: "250px",
										maxHeight: "250px",
										minWidth: "100%",
									}}
									wrapper="image-lazyload"
								/>
							</div>
							{/*  <div*/}
							{/*    style={{*/}
							{/*      backgroundImage: `url(${featured_image})`,*/}
							{/*    }}*/}
							{/*    // src={featured_image}*/}
							{/*    // alt={snippet.title}*/}
							{/*    className={styles.snippet_img_snippets}*/}
							{/*  />*/}
						</Link>
						<div className="position-absolute top-0 ps-3 pe-2 mt-3 fw-bolder course-type-ribbon">
							COURSE
						</div>
					</div>
					<div className={styles.snippet_content}>
						<Link to={snippet_url}>
							<h6
								style={{
									color: "var(--fontColor-primary)",
									height: "3rem",
								}}
							>
								{snippet.title}
							</h6>
						</Link>
						<p
							className={styles.exp_with_snippet}
							style={{ color: "var(--fontColor-secondary)" }}
						>
							with{" "}
							{capitalizeName(
								`${snippet.mentor.first_name} ${snippet.mentor.last_name}`
							)}
						</p>
						<div className="d-flex justify-content-between">
							<p className={styles.duration_in_snippet}>
								{snippet.classes.length} classes
							</p>
							<div
								className={styles.live_from_snippet}
								style={{ color: "var(--fontColor-secondary)" }}
							>
								Live From {city}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
/* <p className="duration-in-snippet">{snippet.classes.length} classes</p> */
export default SnippetCourse;
