import css from "./CourseCarousel.module.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { capitalizeName } from "../utils";
import LazyLoad from "../Commons/LazyLoad";

const CourseCarousel = () => {
	const [courses, setCourses] = useState([]);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_BASE_URL}/rest/students/learn/events`)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setCourses(data.data.results);
			})
			.catch((error) => console.error(error));
	}, []);

	return (
		<>
			{courses.length > 0 && (
				<>
					<div className="container">
						<h4
							className="col-lg-6 col-md-10 col-12"
							style={{
								marginLeft: "2vw",
							}}
						>
							Classes starting in 48 hours
						</h4>
						<div className="row m-0 pt-2">
							{courses.slice(0, 3).map((course) => {
								return (
									<div
										key={course.id}
										className="col-lg-4 col-sm-6 col-xs-12 mb-3"
									>
										<Link
											to={
												course.type === "course"
													? `course/${course.id}`
													: `experience/${course.id}`
											}
											style={{ textDecoration: "none" }}
										>
											<div
												className={`${css["carousel-item"]} p-3`}
												style={{
													backgroundImage: `linear-gradient(to bottom, transparent 0%, rgba(142, 45, 226, 0.3) 35%, rgb(142, 45, 226) 100%), url(${course.featured_image})`,
												}}
											>
												<div className={css["course-item"]}>
													<h5>{course.title}</h5>
													{course.mentor.city && (
														<p>
															<i
																className="fa fa-map-marker me-1"
																aria-hidden="true"
															></i>{" "}
															Live from {course.mentor.city.name},{" "}
															{course.mentor.city.country}
														</p>
													)}
													<section className={css.author}>
														{course.mentor.avatar_url && (
															<LazyLoad
																src={course.mentor.avatar_url}
																alt="author"
															/>
														)}
														{capitalizeName(
															`${course.mentor.first_name} ${
																course.mentor.last_name || ""
															}`
														)}
													</section>
												</div>
											</div>
										</Link>
									</div>
								);
							})}
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default CourseCarousel;
