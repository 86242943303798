import React, { useState, useEffect, useContext } from "react"
import { Col, Row, Form, Spinner, Button } from "react-bootstrap"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import swal from "sweetalert";
import ReactDOM from "react-dom";
import { ThemeContext } from "../../context/ThemeContext";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import user, { getPastClasses } from "../../services/slices/user";

// getting the next subscription date
const nextSubDate = (date) => {
    const currentDate = moment().startOf('day');
    const originalDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
    let newDate = originalDate.add(30, 'days');

    while (newDate.isBefore(currentDate)) {
        newDate = newDate.add(30, 'days');
    }

    const formattedDate = newDate.format('MMM DD, yyyy');
    return formattedDate;
};


const PastWorldClasses = (props) => {
    const dispatch = useDispatch()
    const dark = useContext(ThemeContext).isDark;
    const { pastClasses, userInfo } = useSelector((state) => state.user);
    const worldClasses = pastClasses?.data
    const loading = pastClasses?.loading
    console.log(pastClasses)
    console.log(userInfo)
    const access_token = props.access_token

    useEffect(() => {
        if (!pastClasses?.data && userInfo?.id) dispatch(getPastClasses(userInfo?.id));
    }, [pastClasses?.data, userInfo?.id])

    const WorldSkeleton = () => {
        return (
            <Col xs={12} md={6} className="my-3">
                <div className="experience-snippet-dashboard" style={{ background: "var(--background_primary)", boxShadow: "var(--shadow_secondary)", }}>
                    <div className="exp-snippet-dashboard-texts">
                        <h3 className="snippet-dashboard-text">
                            <Skeleton height={25} width={150} />
                        </h3>
                        <p className="snippet-with-dashboard-text">
                            <Skeleton height={20} width={150} />
                        </p>
                        <div className="snippet-dashboard-last d-flex justify-content-between">
                            <Skeleton height={30} width={80} />
                            <Skeleton height={30} width={80} />
                        </div>
                    </div>
                </div>

            </Col>
        )
    }

    const confPopup = (item) => {
        var conf_popup = document.createElement("div");
        ReactDOM.render(
            <React.StrictMode>
                <ConfPopuupContent data={item} />
            </React.StrictMode>,
            conf_popup
        );
        swal({
            content: conf_popup,
            buttons: false,
            className: "swal-payment-conf2",
            closeOnClickOutside: true,
        });
    };

    const confPopup2 = () => {
        var conf_popup2 = document.createElement("div");
        ReactDOM.render(
            <React.StrictMode>
                <ConfPopuupContent2 />
            </React.StrictMode>,
            conf_popup2
        );
        swal({
            content: conf_popup2,
            buttons: false,
            className: "swal-payment-conf",
            closeOnClickOutside: true,
        });
    };

    const ConfPopuupContent = (props) => {
        const [loading2, setLoading2] = useState(false) // unsubscribe world
        const [formData, setFromData] = useState({
            reason: "Too expensive",
            additionalComments: ""
        })

        const options = [
            { reason: "Too expensive", value: "Too expensive" },
            { reason: "Not finding enough value", value: "Not finding enough value" },
            { reason: "Harassment/Spam", value: "Harassment/Spam" },
            { reason: "Not much happening in the class", value: "Not much happening in the class" },
            { reason: "Others", value: "Others" },
        ]

        const cancelWorldClassSubscription = async (course) => {
            if (loading2) return;
            try {
                setLoading2(true)
                let url = `${process.env.REACT_APP_BASE_URL}/rest/students/courses/${course?.course_id}/subscribe/cancel`;
                let myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + access_token);
                myHeaders.append("Content-Type", "application/json");

                let requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                    body: JSON.stringify({
                        reason: formData.reason,
                        comments: formData.additionalComments,
                        batch_id: course?.batch_id
                    })
                };

                const response = await fetch(`${url}`, requestOptions);
                const data = await response.json();

                if (data.success) {
                    dispatch(getPastClasses());
                    swal.close();
                    confPopup2();
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading2(false)
            }
        }

        return (
            <div className="p-3">
                <div>
                    <h3 className="my-2 mb-3">
                        Are you sure you want to unsubscribe?
                    </h3>
                    <p className="mb-4">
                        This will remove your access from {" "}
                        <span className="fw-bold">
                            {props.data?.course_name}
                        </span> {" "}
                        and all the data will be lost.
                    </p>
                    <h5 className="mb-3">Reason for unsubscribing ?</h5>
                    <div>
                        <img
                            src="/icons/arrow-down.png"
                            alt="down-arrow"
                            style={
                                dark ? {
                                    width: "45px",
                                    height: "48px",
                                    position: "absolute",
                                    right: "20px",
                                    filter: "invert(1)"
                                } : {
                                    width: "45px",
                                    height: "50px",
                                    position: "absolute",
                                    right: "13px",
                                }
                            }
                        />
                        <Form.Select
                            size="lg"
                            onChange={(e) => setFromData({
                                ...formData,
                                reason: e.target.value
                            })}
                        >
                            {
                                options.map((option) => {
                                    return (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.reason}
                                        </option>
                                    )
                                })
                            }
                        </Form.Select>
                    </div>
                </div>

                <div>
                    <h5 className="my-3">Additional comments</h5>
                    <Form.Control
                        as="textarea"
                        placeholder="10 to 500 characters"
                        onChange={(e) => {
                            setFromData({
                                ...formData,
                                additionalComments: e.target.value
                            })
                        }}
                    />
                    <p className="p-2">
                        On unsubscribing, you will continue to have access to this class until the end of your monthly subscription.
                    </p>
                </div>

                <div className="d-flex justify-content-end mt-3">
                    <button
                        title="Cancel Subscription"
                        type="button"
                        className="px-3 py-2 my-2"
                        onClick={() => swal.close()}
                        style={{ background: "transparent", border: "1px solid var(--border)", fontSize: "14px" }}

                    >
                        Cancel
                    </button>
                    <Button
                        style={{ background: "transparent", fontSize: "14px", borderRadius: "10px" }}
                        onClick={() => cancelWorldClassSubscription(props.data)}
                        className="text-danger px-3 mx-3 py-2 my-2 "
                        variant="outline-danger"
                        id="exit-course"
                    >

                        {loading2 ? (<Spinner animation="border" variant="danger" />) : "Confirm"}
                    </Button>
                </div>
            </div>
        );
    };

    const ConfPopuupContent2 = () => {
        return (
            <div>
                <h3 className="mt-2">
                    Unsubscribed successfully!
                </h3>
                <p className="p-2">
                    You have successfully unsubscribed from this class. However, you will continue to have access to the remaining classes until the end of your monthly subscription.
                    For any concerns, reach out to us via email at hello@helloworlds.co.
                </p>
                <button className="px-3 py-1 my-2 text-white" onClick={() => swal.close()}>
                    Okay
                </button>
            </div>
        );
    };

    return (
        <Row >
            {
                loading ? (
                    <SkeletonTheme baseColor="gray" highlightColor="white">
                        <WorldSkeleton />
                        <WorldSkeleton />
                        <WorldSkeleton />
                        <WorldSkeleton />
                        <WorldSkeleton />
                        <WorldSkeleton />
                    </SkeletonTheme>
                ) :
                    worldClasses?.length > 0 ? worldClasses.map((item) => {
                        return (
                            <Col xs={12} md={6} className="my-3" key={item?.course_id}>
                                <div
                                    className="experience-snippet-dashboard py-2"
                                    style={{
                                        boxShadow: "var(--shadow_secondary)",
                                        background: "var(--background_primary)",
                                    }}
                                >
                                    <div className="exp-snippet-dashboard-texts">
                                        <h3 className="snippet-dashboard-text mb-2">
                                            {item?.batch?.courses?.title?.length > 20 ? item?.batch?.courses?.title?.slice(0, 20) + "..." : item?.batch?.courses?.title}

                                        </h3>
                                        <div className="mb-3">
                                            <p className="snippet-with-dashboard-text mb-1">
                                                Batch :  {moment.utc(item?.start_time * 1000).format("h:mm A")} - {moment.utc(item?.start_time * 1000).format("h:mm A")}
                                            </p>

                                            <p className="snippet-with-dashboard-text">
                                                Next subscription of <span className="text-info">₹{item?.batch?.courses?.fee}</span> on {" "}
                                                <span className="text-info">{nextSubDate(item?.batch?.start_time)}</span>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                style={{ background: "transparent", fontSize: "15px", borderRadius: "10px" }}
                                                className="text-danger py-2"
                                                variant="outline-danger"
                                                title="Cancel Subscription"
                                                onClick={() => confPopup(item)}
                                                id="exit-course"
                                            >
                                                Cancel
                                            </Button>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    }) : (
                        <div className="no-upcoming-past-recording">
                            <p className="bold-no-past-recording mb-3">No classes joined yet</p>
                            {/* <Link to={"/worlds"}>
                                <button
                                    className="py-2 px-3"
                                    type="submit"
                                >
                                    Explore Worlds
                                </button>
                            </Link> */}
                        </div>
                    )
            }
        </Row>
    )
}

export default PastWorldClasses