import React, { useEffect } from 'react'
import ProfilePopup from './ProfilePopup'
import ReactDOM from 'react-dom'
import swal from 'sweetalert'

const ShowProfile = (props) => {
    const showPopup = () => {
        var swal_profile_popup = document.createElement("div");
        ReactDOM.render(
            <React.StrictMode>
                <ProfilePopup
                    access_token={props.access_token}
                    student_uuid={props.student_uuid}
                />
            </React.StrictMode>,
            swal_profile_popup
        );
        swal({
            content: swal_profile_popup,
            buttons: false,
            className: "swal-login",
            closeOnClickOutside: false,
        });
    }

    useEffect(() => {
        if (props.access_token && props.show) {
            showPopup()
        }
    }, [props.show])

    return null
}

export default ShowProfile