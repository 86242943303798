import { useEffect, useState } from 'react'

const GoogleLoginSuggestion = ({ setShowGoogleLoginSuggestion }) => {
    const [googleLoginURL, setGoogleLoginURL] = useState('')

    const handleClose = () => {
        setShowGoogleLoginSuggestion(false)
    }

    useEffect(() => {
        const loginGoogle = () => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            fetch(
                `${process.env.REACT_APP_BASE_URL}/login/google/redirect`,
                requestOptions
            )
                .then(function (response) {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error("Something went wrong!");
                })
                .then((data) => setGoogleLoginURL(data.url))
                .catch((error) => console.error(error));
        }
        loginGoogle();
    }, [])

    return (
        <div className="google-login-suggestion">
            <div className="google-login-suggestion-inner">
                <i
                    className="fa fa-times login-popup-cross-icon"
                    aria-hidden="true"
                    onClick={handleClose}
                >
                </i>
                <h4 className='mb-4 text-center'>Get notified as we add more interest-based clubs.</h4>
                <a className="google-button" href={googleLoginURL}>
                    <i className="fa fa-google"></i> Continue with Google
                </a>
            </div>
        </div>
    )
}

export default GoogleLoginSuggestion