import LazyLoad from "../../Commons/LazyLoad";
import styles from "./LibraryCard.module.css";
import moment from "moment/moment";

const LibraryCard = ({ item }) => {
	return (
		<>
			<div className={styles.card}>
				<a href={item.url} target="_blank" rel="noreferrer noopener">
					{
						item.file_type === "pdf" ? (
							<LazyLoad
								src="/pdf.png"
								alt="club image"
								classes={styles.banner}
								wrapper="image-lazyload"
							/>
						) : (

							<LazyLoad
								src={item.url}
								alt="club image"
								classes={styles.banner}
								wrapper="image-lazyload"
							/>
						)
					}
					<p className={styles["file-type"]}>
						{
							!item.file_type ? (
								"IMAGE"
							) : (
								item.file_type === "pdf" ? (
									"PDF"
								) : (
									"IMAGE"
								)
							)
						}
					</p>
					<i
						className={`fa fa-eye ${styles["dwn-icon"]}`}
						aria-hidden="true"
					></i>
					<div className="p-4 pt-3">
						<h5>
							{
								item.title.length > 18 ? (
									item.title.slice(0, 15) + "..."
								) : (
									item.title
								)
							}
						</h5>
						<p>
							{
								item.description.length > 50 ? (
									item.description.slice(0, 50) + "..."
								) : (
									item.description
								)
							}
						</p>
						<p>
							<i className="fa fa-calendar" aria-hidden="true"></i>{" "}
							{moment(item.created_at).format("DD MMM YYYY")}
						</p>
					</div>
				</a>
			</div>
		</>
	);
};

export default LibraryCard;
