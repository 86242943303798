import React, { Component } from "react";
import TimeBox from "./TimeBox";
import { nearest50 } from "../utils";

class CourseDateSelection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show_time_box: this.props.show_time_box,
		};
		this.showTimeBox = this.showTimeBox.bind(this);
		this.closeAvailability = this.closeAvailability.bind(this);
	}

	showTimeBox() {
		this.setState({
			show_time_box: 1,
		});
	}

	closeAvailability() {
		this.setState({
			show_time_box: 0,
		});
	}

	render() {
		let window_width = window.innerWidth;
		return (
			<>
				<div className="pricing-booking-container">
					<div className="date-exp-container">
						<div className="price-experience ">
							<span className="original">
								₹{nearest50(Math.ceil(this.props.original_price))}
							</span>
							<span className="discounted">₹{this.props.price}</span>
							<span className="per_class">
								for {this.props.n_classes} classes
							</span>
							<p>
								(₹
								{Math.ceil(this.props.price / this.props.n_classes)}
								/class)
							</p>
						</div>
					</div>
					<div className="d-md-none col-xs-7">
						<button
							className="attend-now-btn-webinar"
							onClick={() => this.showTimeBox()}
						>
							Enrol
						</button>
					</div>
				</div>
				{this.state.show_time_box === 1 || window_width > 768 ? (
					<TimeBox
						update_token={this.props.update_token}
						access_token={this.props.access_token}
						course_id={this.props.course_id}
						close_availability={this.closeAvailability}
						student_name={this.props.student_name}
						mentor_name={this.props.mentor_name}
						featured_image={this.props.featured_image}
						price={this.props.price}
						title={this.props.title}
						language={this.props.language}
						student_id={this.props.student_id}
					/>
				) : null}
			</>
		);
	}
}

export default CourseDateSelection;
