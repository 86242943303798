import React, { Component } from "react";

class Banner extends Component {
    render() {
        //if (this.props.access_token===null) {
        // return <Redirect to='/login'/>;
        //}
        return (
            <div className="background-banner-listings">
                <img src="/images/course_bg.png" alt="" />
                <div className="col-10 col-md-8 text-in-listings-banner">
                    <h2>Outcome driven online courses</h2>
                    <p>
                        Let's go deeper into the subjects you love, and acquire
                        practical learning from the best in the field!
                    </p>
                </div>
            </div>
        );
    }
}

export default Banner;
