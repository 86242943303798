import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userInfo: null,
        worldBookings: {
            loading: false,
            data: null,
            error: null
        },
        pastClasses: {
            loading: false,
            data: null,
            error: null
        },
        upcomingLiveRooms: {
            loading: false,
            data: null,
            error: null
        },
        upcomingLiveClasses: {
            loading: false,
            data: null,
            error: null
        },
        loading: false,
        error: null,
    },
    reducers: {
        getUser: (state, payload) => {
            state.loading = true;
            state.error = null;
        },
        getUserSuccess: (state, action) => {
            state.loading = false;
            state.userInfo = action.payload;
        },
        getUserFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        getWorldBookings: (state) => {
            state.worldBookings.loading = true;
            state.worldBookings.error = null
        },
        getWorldBookingsSuccess: (state, action) => {
            state.worldBookings.loading = false;
            state.worldBookings.data = action.payload;
        },
        getWorldBookingsFailure: (state, action) => {
            state.worldBookings.loading = false;
            state.worldBookings.error = action.payload;
        },

        getPastClasses: (state) => {
            state.pastClasses.loading = true;
            state.pastClasses.error = null
        },
        getPastClassesSuccess: (state, action) => {
            state.pastClasses.loading = false;
            state.pastClasses.data = action.payload;
        },
        getPastClassesFailure: (state, action) => {
            state.pastClasses.loading = false;
            state.pastClasses.error = action.payload;
        },

        getUpcomingLiveRooms: (state) => {
            state.upcomingLiveRooms.loading = true;
            state.upcomingLiveRooms.error = null
        },
        getUpcomingLiveRoomsSuccess: (state, action) => {
            state.upcomingLiveRooms.loading = false;
            state.upcomingLiveRooms.data = action.payload;
        },
        getUpcomingLiveRoomsFailure: (state, action) => {
            state.upcomingLiveRooms.loading = false;
            state.upcomingLiveRooms.error = action.payload;
        },

        getUpcomingLiveClasses: (state, action) => {
            state.upcomingLiveClasses.loading = true;
            state.upcomingLiveClasses.error = null
        },
        getUpcomingLiveClassesSuccess: (state, action) => {
            state.upcomingLiveClasses.loading = false;
            state.upcomingLiveClasses.data = action.payload;
        },
        getUpcomingLiveClassesFailure: (state, action) => {
            state.upcomingLiveClasses.loading = false;
            state.upcomingLiveClasses.error = action.payload;
        },
    },
});

export const {
    getUser,
    getUserSuccess,
    getUserFailure,
    getWorldBookings,
    getWorldBookingsSuccess,
    getWorldBookingsFailure,
    getUpcomingLiveRooms,
    getUpcomingLiveRoomsSuccess,
    getUpcomingLiveRoomsFailure,
    getUpcomingLiveClasses,
    getUpcomingLiveClassesSuccess,
    getUpcomingLiveClassesFailure,
    getPastClasses,
    getPastClassesSuccess,
    getPastClassesFailure
    
} = userSlice.actions;
export default userSlice.reducer;
