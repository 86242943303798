import React from 'react'
import { Link } from "react-router-dom";

const SubHeaderWorlds = (props) => {
    return (
        <div className="px-0">
            <div
                className="subheader-div w-100"
                style={{
                    top: "0.1rem",
                    marginLeft: "-18px",
                    background: "var(--background_secondary)",
                    boxShadow: "0px 0px 15px -5px var(--fontColor-primary)",
                }}>
                <Link
                    to={props.backUrl}
                    className="ps-3 py-2 d-flex align-items-center"
                >
                    <button className="d-md-none">
                        <i
                            className="fa fa-chevron-left"
                            style={{ color: "var(--fontColor_primary" }}
                            aria-hidden="true"
                        ></i>
                    </button>
                    <p className='d-md-none ms-3 fw-bold' style={{ color: "var(--fontColor-primary)" }}>{props.world?.title + " - " + props.mentor?.first_name + " " + props.mentor?.last_name}</p>
                </Link>
            </div>
        </div>
    )
}

export default SubHeaderWorlds