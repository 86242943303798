import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonBase = () => {
	return (
		<SkeletonTheme baseColor="gray" highlightColor="white">
			<div className="experience-snippet">
				<div className="experience-snippet-inner">
					<div className="snippet-image">
						<Skeleton circle height={100} width={100} />
					</div>
					<div className="snippet-content snippet-content-webinar">
						<div className="snippet-content-hero">
							<div className="snippet-content-main">
								<div>
									<h5>
										<Skeleton height={32} width={240} />
									</h5>
									<div className="snippet-content-main_sub-details">
										<p>
											<Skeleton height={20} width={270} />
										</p>
										<p className="duration-in-snippet">
											<Skeleton height={20} width={250} />
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</SkeletonTheme>
	);
};

const LoadingSkeleton = () => {
	return <Skeleton wrapper={SkeletonBase} count={2} />;
};

export default LoadingSkeleton;
